import React, { ReactNode, useEffect } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';
import { useDeviceDetection } from '@hooks/useDeviceDetection';
import { CANCEL_BUTTON_LABEL } from '@constants/common';
import { SEARCH_BUTTON, SEARCHBAR_PLACEHOLDER } from '@constants/search';
import styles from './searchQueryInput.module.scss';

const { Search } = Input;

interface ISearchQueryInputProps {
  query?: string;
  loading?: boolean;
  disabled?: boolean;
  onQuerySubmit: (query: string) => void;
  enterButton?: ReactNode | string;
  activatingPipeline?: boolean;
}

const SearchQueryInput = ({
  query,
  loading = false,
  disabled = false,
  enterButton,
  onQuerySubmit,
}: ISearchQueryInputProps) => {
  const { isDesktop } = useDeviceDetection();

  const [searchQuery, setSearchQuery] = React.useState<string>('');

  useEffect(() => {
    if (query) setSearchQuery(query);
  }, [query]);

  const onSearch = (value: string) => {
    if (value) onQuerySubmit(value);
  };

  return (
    <div className={styles.searchQueryContainer}>
      <Search
        className={`${!isDesktop ? styles.tabletMobileInput : ''}`}
        placeholder={SEARCHBAR_PLACEHOLDER}
        onSearch={onSearch}
        value={searchQuery}
        onChange={(event) => setSearchQuery(event.target.value)}
        size="large"
        enterButton={enterButton || SEARCH_BUTTON}
        disabled={disabled}
        loading={loading}
        prefix={!isDesktop && <SearchOutlined />}
        allowClear={isDesktop}
        autoFocus
        data-testid="searchBar_input"
      />
      {!isDesktop && searchQuery && (
        <Button className={styles.cancelButton} type="link" onClick={() => setSearchQuery('')}>
          {CANCEL_BUTTON_LABEL}
        </Button>
      )}
    </div>
  );
};

export default SearchQueryInput;
