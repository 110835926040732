import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { HistoryOutlined, SendOutlined } from '@ant-design/icons';
import { unwrapResult } from '@reduxjs/toolkit';
import { Button, Input } from 'antd';
import { isEmpty } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { getODataEQFilterFrom } from '@utils/odata';
import { parseSearchHistoryToSearchResult } from '@utils/search';
import useEffectUpdateOnly from '@hooks/useEffectUpdateOnly';
import usePipelineQuery from '@hooks/usePipelineQuery';
import { SUBMIT_BUTTON_LABEL } from '@constants/common';
import {
  FilterProp,
  FiltersProp,
  FilterType,
  SelectedFilterItem,
  SelectedFilters,
} from '@constants/data-table';
import { StatusCodes } from '@constants/enum/common';
import {
  PipelineDesiredStatusCodes,
  PipelineOutputType,
  PipelineStatusCodes,
} from '@constants/pipelines';
import {
  MIN_PROMPT_EDITOR_HEIGHT,
  PROMPT_EXPLORER_NEW_SESSION_STARTED_MESSAGE,
  PROMPT_TEMPLATES_FILTERS_FIELD_KEYS,
  PROMPT_TEMPLATES_FILTERS_TAGS_LABEL,
  PROMPT_TEMPLATES_FILTERS_USER_LABEL,
  SEARCH_PLACEHOLDER,
} from '@constants/prompt-explorer';
import { getPipelineMeta } from '@redux/actions/metadataFiltersActions';
import { fetchPipelines, getPipelinePrompts } from '@redux/actions/pipelineActions';
import {
  createCustomPromptTemplate,
  deleteCustomPromptTemplate,
  getCustomPromptTemplates,
  getPromptTemplatesFilterTags,
  getPromptTemplatesFilterUser,
  getPublicPromptTemplates,
  updateCustomPromptTemplate,
  updatePipelinePrompt,
  updatePipelineV2Prompt,
} from '@redux/actions/promptExplorerActions';
import {
  createSearchSession,
  getSearchSessions as getSearchSessionsAction,
} from '@redux/actions/searchActions';
import {
  pipelineLatestQueriesSelector,
  pipelinesSelector,
} from '@redux/selectors/pipelineSelectors';
import {
  createPromptTemplateStatusSelector,
  promptTemplatesFiltersValuesSelector,
  promptTemplatesSelector,
  promptTemplatesSortValueSelector,
  promptTemplatesStatusSelector,
  updatePromptTemplateStatusSelector,
} from '@redux/selectors/promptExplorerSelectors';
import {
  queryResultsPromptExplorerSelector,
  queryStatusPromptExplorerSelector,
} from '@redux/selectors/searchSelectors';
import {
  CustomPromptTemplate,
  DeepsetCloudVersion,
  IAPIPaginationData,
  IPipeline,
  IPromptNode,
  IPromptPlayground,
  ISearchResult,
  ITemplatesParams,
  MetadataFiltersType,
  PipelineLatestQueries,
  PlaygroundId,
  PromptTemplate,
  PromptTemplatesModalTabOption,
} from '@redux/types/types';
import EllipsisMiddle from '@components/common/ellipsisMiddle/EllipsisMiddle';
import PipelineDeepsetCloudVersionTag from '@components/pipelines/pipelineDeepsetCloudVersionTag/PipelineDeepsetCloudVersionTag';
import PipelineServiceLevelBadge from '@components/pipelines/pipelineServiceLevelBadge/PipelineServiceLevelBadge';
import PromptTemplatesModal from '@components/promptTemplatesModal/PromptTemplatesModal';
import SearchHistoryLog from '@components/search/organisms/searchHistoryLog/SearchHistoryLog';
import PipelinePlayground from './components/PipelinePlayground';
import PlaygroundFiltersDrawer from './components/PlaygroundFiltersDrawer';
import styles from './promptExplorer.module.scss';

const { Search } = Input;

const FETCH_LIMIT = 20;

const PromptExplorerPage = () => {
  const dispatch = useDispatch();
  const { pipelineName: queryPipelineName } = useParams<{ pipelineName: string }>();

  const [promptEditorContainerExpanded, setPromptEditorContainerExpanded] = useState(false);
  const [queryValue, setQueryValue] = useState('');
  const [playgrounds, setPlaygrounds] = useState<IPromptPlayground[]>([]);
  const [displayTypingEffect, setDisplayTypingEffect] = useState<boolean>(true);
  const { data: pipelines }: IAPIPaginationData<IPipeline[]> = useSelector((state) =>
    pipelinesSelector(state, PipelineDesiredStatusCodes.DEPLOYED),
  );

  // Querying
  const { queryPromptExplorer, resetSearchResultsPromptExplorer, addPromptExplorerInfoResult } =
    usePipelineQuery();
  const queryResults = useSelector(queryResultsPromptExplorerSelector);
  const queryStatus = useSelector(queryStatusPromptExplorerSelector);

  // Prompt Editor Height
  const [promptEditorHeight, setPromptEditorHeight] = useState(MIN_PROMPT_EDITOR_HEIGHT);
  const handlePromptEditorResize = (height: number) => {
    setPromptEditorHeight(promptEditorHeight + height);
    if (promptEditorContainerExpanded)
      setPromptEditorContainerExpanded(!promptEditorContainerExpanded);
  };

  // Sessions
  const [sessionQueriesResults, setSessionQueriesResults] = useState<
    Record<PlaygroundId, ISearchResult[]>
  >({});
  const [historyVisible, setHistoryVisible] = useState(false);
  const pipelineLatestQueries: PipelineLatestQueries = useSelector(pipelineLatestQueriesSelector);

  // Metadata filters
  const [activePlaygroundFiltersDrawer, setActivePlaygroundFiltersDrawer] = useState<string>('');

  // Prompt templates modal
  const promptTemplatesModalRef = useRef<React.ElementRef<typeof PromptTemplatesModal>>(null);
  const [activePlaygroundPromptTemplate, setActivePlaygroundPromptTemplate] = useState<string>('');
  const [activePromptTemplatesModalTab, setActivePromptTemplatesModalTab] = useState(
    PromptTemplatesModalTabOption.CUSTOM,
  );
  const [currentTemplateListPageNumber, setCurrentTemplateListPageNumber] = useState(1);
  const [selectedTemplatesFilterValuesByTab, setSelectedTemplatesFilterValuesByTab] = useState<
    Record<PromptTemplatesModalTabOption, SelectedFilters>
  >({ [PromptTemplatesModalTabOption.CUSTOM]: {}, [PromptTemplatesModalTabOption.DEEPSET]: {} });
  const [templatesSearchValue, setTemplatesSearchValue] = useState<string>('');
  const selectedPromptTemplatesSortValue = useSelector(promptTemplatesSortValueSelector);
  const promptTemplatesFiltersValues = useSelector(promptTemplatesFiltersValuesSelector);
  const promptTemplates = useSelector(promptTemplatesSelector);
  const createPromptTemplateStatus = useSelector(createPromptTemplateStatusSelector);
  const updatePromptTemplateStatus = useSelector(updatePromptTemplateStatusSelector);
  const promptTemplatesStatus = useSelector(promptTemplatesStatusSelector);

  // General methods

  const getPlaygroundById = (playgroundId: string): IPromptPlayground | null =>
    playgrounds.find(({ id }) => id === playgroundId) || null;

  const updatePlaygroundValues = (playgroundId: string, values: Partial<IPromptPlayground>) => {
    setPlaygrounds((prevPlaygrounds) =>
      prevPlaygrounds.map((playground) => {
        if (playground.id !== playgroundId) return playground;
        return { ...playground, ...values };
      }),
    );
  };

  const getPipelineByName = (pipelineName: string): IPipeline | null =>
    pipelines.find(({ name }) => name === pipelineName) ?? null;

  const isSearching = () =>
    Object.values(queryStatus).some((status) => status === StatusCodes.IN_PROGRESS);

  const emptySearchResults = () =>
    Object.values(queryResults).every((result) => result.length === 0);

  useEffect(() => {
    // TODO: Add pagination on select scroll
    dispatch(
      fetchPipelines({
        currentPage: 1,
        pageSize: 100,
        searchValue: '',
        desiredStatus: PipelineDesiredStatusCodes.DEPLOYED,
        status: PipelineStatusCodes.DEPLOYED,
        supportsPrompt: true,
      }),
    );
    dispatch(
      getPromptTemplatesFilterTags({ promptTemplateModalActiveTab: activePromptTemplatesModalTab }),
    );
    dispatch(getPromptTemplatesFilterUser());

    return () => {
      if (playgrounds.length)
        playgrounds.forEach(({ id: playgroundId }) =>
          resetSearchResultsPromptExplorer(playgroundId),
        );
    };
  }, []);

  useEffect(() => {
    if (
      createPromptTemplateStatus === StatusCodes.SUCCESS ||
      updatePromptTemplateStatus === StatusCodes.SUCCESS
    )
      dispatch(getCustomPromptTemplates({}));
  }, [createPromptTemplateStatus, updatePromptTemplateStatus]);

  useEffect(() => {
    const [firstPlayground] = playgrounds;
    if (firstPlayground?.session) {
      const parsedResults = pipelineLatestQueries.data.map(parseSearchHistoryToSearchResult);
      const reversedParsedResults = parsedResults.reverse();

      setDisplayTypingEffect(false);
      setSessionQueriesResults({ [firstPlayground.id]: reversedParsedResults as ISearchResult[] });
    }
  }, [pipelineLatestQueries]);

  useEffect(() => {
    setDisplayTypingEffect(true);

    const [firstPlayground] = playgrounds;
    if (!firstPlayground?.id) return;
    const currentSessionQueriesResults = sessionQueriesResults[firstPlayground.id];

    if (currentSessionQueriesResults?.length) {
      const playgroundSearchResults = queryResults[firstPlayground.id];
      const lastPlaygroundSearchResults =
        playgroundSearchResults[playgroundSearchResults.length - 1];
      const lastSessionSearchResults =
        currentSessionQueriesResults[currentSessionQueriesResults.length - 1];

      if (lastPlaygroundSearchResults?.query_id === lastSessionSearchResults?.query_id) {
        const updatedSessionQueriesResults = currentSessionQueriesResults
          .slice(0, -1)
          .concat(lastPlaygroundSearchResults);
        setSessionQueriesResults({
          [firstPlayground.id]: updatedSessionQueriesResults,
        });
        return;
      }

      setSessionQueriesResults({
        [firstPlayground.id]: [...currentSessionQueriesResults, lastPlaygroundSearchResults],
      });
    }
  }, [queryResults]);

  useEffect(() => {
    if (activePlaygroundFiltersDrawer) {
      const playground = getPlaygroundById(activePlaygroundFiltersDrawer);
      if (!playground) return;
      dispatch(getPipelineMeta({ pipelineName: playground.pipelineName! }));
    }
  }, [activePlaygroundFiltersDrawer]);

  const getPromptTemplates = (localParams: ITemplatesParams) => {
    const getTemplates = {
      [PromptTemplatesModalTabOption.CUSTOM]: getCustomPromptTemplates,
      [PromptTemplatesModalTabOption.DEEPSET]: getPublicPromptTemplates,
    };
    const params = {
      filterValues: selectedTemplatesFilterValuesByTab[activePromptTemplatesModalTab],
      searchValue: templatesSearchValue,
      ...localParams,
    };
    dispatch(getTemplates[activePromptTemplatesModalTab](params));
  };

  const getPromptTemplatesFilters = (): FiltersProp<FilterType.MULTI_SELECT> => {
    const { tags, users } = promptTemplatesFiltersValues;

    const tagsFilter = {
      type: FilterType.MULTI_SELECT,
      key: PROMPT_TEMPLATES_FILTERS_FIELD_KEYS.TAGS_ID,
      title: PROMPT_TEMPLATES_FILTERS_TAGS_LABEL,
      options: tags,
    } as FilterProp<FilterType.MULTI_SELECT>;

    if (activePromptTemplatesModalTab === PromptTemplatesModalTabOption.CUSTOM) {
      const createdByFilter = {
        type: FilterType.MULTI_SELECT,
        key: PROMPT_TEMPLATES_FILTERS_FIELD_KEYS.USER_ID,
        title: PROMPT_TEMPLATES_FILTERS_USER_LABEL,
        style: { minWidth: '120px' },
        options: users,
      } as FilterProp<FilterType.MULTI_SELECT>;

      return [...(tags?.length ? [tagsFilter] : []), ...(users?.length ? [createdByFilter] : [])];
    }

    return [...(tags?.length ? [tagsFilter] : [])];
  };

  useEffectUpdateOnly(() => {
    setCurrentTemplateListPageNumber(1);
    dispatch(
      getPromptTemplatesFilterTags({ promptTemplateModalActiveTab: activePromptTemplatesModalTab }),
    );
    getPromptTemplates({});
  }, [activePromptTemplatesModalTab]);

  const getPipelinePromptsFromAPI = async (pipelineName: string) => {
    try {
      const resultAction = await dispatch(getPipelinePrompts(pipelineName));
      if (resultAction) {
        const content = unwrapResult(resultAction as any);
        const { prompts } = content;
        if (!prompts || isEmpty(prompts)) return {};
        return prompts;
      }
      return {};
    } catch (error) {
      return {};
    }
  };

  const getLastPromptNodeName = (prompts: Record<string, string>) => {
    const promptNodesNames = Object.keys(prompts) || [];
    return promptNodesNames[promptNodesNames.length - 1];
  };

  const initializePlayground = async (pipelineName?: string) => {
    const id = uuidv4();
    if (pipelineName) {
      const prompts = await getPipelinePromptsFromAPI(pipelineName);
      const lastNode = getLastPromptNodeName(prompts);

      setPlaygrounds([
        {
          id,
          pipelineName,
          prompts,
          selectedPromptNode: lastNode || '',
        },
      ]);
      return;
    }
    setPlaygrounds([{ id, prompts: {}, selectedPromptNode: '' }]);
  };

  useEffect(() => {
    initializePlayground(queryPipelineName);
  }, [pipelines]);

  useEffect(() => {
    getPromptTemplates({});
  }, [selectedTemplatesFilterValuesByTab, templatesSearchValue]);

  const getPipelinesSelectOptions = () =>
    pipelines.map(
      ({ pipeline_id: id, name, service_level: serviceLevel, deepset_cloud_version: version }) => ({
        id,
        value: name,
        label: (
          <div className={styles.pipelineSelectOption}>
            <PipelineServiceLevelBadge serviceLevel={serviceLevel} />
            <EllipsisMiddle>{name}</EllipsisMiddle>
            <PipelineDeepsetCloudVersionTag deepsetCloudVersion={version} />
          </div>
        ),
      }),
    );

  // TODO: Remove once we support streaming for V2
  const showTypingEffect = (playgroundId: string) => {
    const playground = getPlaygroundById(playgroundId);
    const { pipelineName } = playground || {};
    const selectedPipeline = getPipelineByName(pipelineName || '');
    const { deepset_cloud_version: pipelineVersion } = selectedPipeline || {};
    return displayTypingEffect && pipelineVersion === DeepsetCloudVersion.V2;
  };

  // Session methods

  const getSearchSessions = (
    extraParams: {
      pipelineName?: string;
      limit?: number;
      pageNumber?: number;
      filter?: string;
      fetchMore?: boolean;
    } = {},
  ) => {
    const [firstPlayground] = playgrounds;
    const { pipelineName, ...restOfParams } = extraParams;

    const { pipeline_id: pipelineId } = getPipelineByName(
      pipelineName || firstPlayground.pipelineName || '',
    ) || { pipeline_id: '' };
    const params = {
      limit: FETCH_LIMIT,
      pageNumber: 1,
      filter: getODataEQFilterFrom('pipeline_id', pipelineId),
      ...restOfParams,
    };
    dispatch(getSearchSessionsAction(params));
  };

  const createSession = async (pipelineId: string) => {
    const resultAction = await dispatch(createSearchSession({ pipelineId }));
    if (resultAction) {
      getSearchSessions({ pageNumber: 1 });
      const content = unwrapResult(resultAction as any);
      return content?.search_session_id;
    }
    return null;
  };

  const onSessionSelect = (sessionId: string) => {
    const [firstPlayground] = playgrounds;
    updatePlaygroundValues(firstPlayground.id, { session: sessionId });
  };

  const openSearchHistory = () => {
    setHistoryVisible(true);
  };

  const closeSearchHistory = () => {
    setHistoryVisible(false);
  };

  // Playground methods

  const handleSelectingPipeline = async ({
    pipelineName,
    playgroundId,
  }: {
    pipelineName: string;
    playgroundId: string;
  }) => {
    setSessionQueriesResults({});
    resetSearchResultsPromptExplorer(playgroundId);
    const prompts = await getPipelinePromptsFromAPI(pipelineName);
    const lastNode = getLastPromptNodeName(prompts);

    updatePlaygroundValues(playgroundId, {
      pipelineName,
      prompts,
      selectedPromptNode: lastNode || '',
      session: null,
    });
  };

  const handleAddingNewPlayground = async (playgroundId: string, pipelineName: string) => {
    closeSearchHistory();
    setSessionQueriesResults({});
    updatePlaygroundValues(playgroundId, { session: undefined });
    const playground = await getPlaygroundById(playgroundId);
    const id = uuidv4();
    setPlaygrounds((prev) => [
      ...prev,
      {
        id,
        pipelineName,
        prompts: playground?.prompts || {},
        selectedPromptNode: playground?.selectedPromptNode || '',
      },
    ]);
  };

  const handleRemovingPlayground = (playgroundId: string) => {
    resetSearchResultsPromptExplorer(playgroundId);
    setPlaygrounds((prev) => prev.filter(({ id }) => id !== playgroundId));
  };

  const onPromptEditorValueChange = ({
    value,
    playgroundId,
  }: {
    value: string;
    playgroundId: string;
  }) => {
    const playground = getPlaygroundById(playgroundId);
    if (!playground) return;
    const { selectedPromptNode, prompts } = playground;
    if (!selectedPromptNode) return;
    const updatedPrompts = {
      ...prompts,
      [selectedPromptNode]: {
        ...prompts[selectedPromptNode],
        text: value,
      },
    };
    updatePlaygroundValues(playgroundId, { prompts: updatedPrompts });
  };

  const onPromptEditorPromptNodeChange = ({
    node,
    playgroundId,
  }: {
    node: string;
    playgroundId: string;
  }) => {
    const playground = getPlaygroundById(playgroundId);
    if (!playground) return;
    updatePlaygroundValues(playgroundId, {
      selectedPromptNode: node,
    });
  };

  const onUpdatePipelinePrompt = (playgroundId: string) => {
    const playground = getPlaygroundById(playgroundId);
    if (!playground) return;

    const { pipelineName, prompts, selectedPromptNode } = playground;
    if (!pipelineName) return;

    const selectedPipeline = getPipelineByName(pipelineName);
    const { text, template_name: templateName } = prompts[selectedPromptNode];

    if (selectedPipeline?.deepset_cloud_version === DeepsetCloudVersion.V2) {
      dispatch(
        updatePipelineV2Prompt({
          pipelineName,
          prompt: text,
          promptNode: selectedPromptNode,
        }),
      );
      return;
    }

    if (!templateName) return;

    dispatch(
      updatePipelinePrompt({
        pipelineName,
        prompt: text,
        templateName,
      }),
    );
  };

  const handleStartingNewPlaygroundSession = (playgroundId: string) => {
    updatePlaygroundValues(playgroundId, {
      session: null,
    });
    addPromptExplorerInfoResult({
      playgroundId,
      infoText: PROMPT_EXPLORER_NEW_SESSION_STARTED_MESSAGE,
    });
  };

  const handleClearingPlayground = (playgroundId: string) => {
    resetSearchResultsPromptExplorer(playgroundId);
    setSessionQueriesResults({});
    updatePlaygroundValues(playgroundId, {
      session: null,
    });
  };

  // Prompt template methods

  const handleFetchingMoreTemplates = () => {
    const nextPage = currentTemplateListPageNumber + 1;
    setCurrentTemplateListPageNumber(nextPage);
    getPromptTemplates({ pageNumber: nextPage, fetchMore: true });
  };

  const handleSelectingPromptTemplate = (promptTemplate: PromptTemplate) => {
    const playground = getPlaygroundById(activePlaygroundPromptTemplate);
    if (!playground) return;
    const { selectedPromptNode, prompts } = playground;
    const updatedPrompts = {
      ...prompts,
      [selectedPromptNode]: {
        ...prompts[selectedPromptNode],
        text: promptTemplate.text,
      },
    };
    updatePlaygroundValues(activePlaygroundPromptTemplate, { prompts: updatedPrompts });
  };

  const handleCreatingPromptTemplate = (promptTemplate: CustomPromptTemplate) => {
    dispatch(createCustomPromptTemplate(promptTemplate));
  };

  const handleUpdatingPromptTemplate = (
    promptTemplate: CustomPromptTemplate & { prompt_template_id: string },
  ) => {
    dispatch(updateCustomPromptTemplate(promptTemplate));
  };

  const handleDeletingPromptTemplate = (promptTemplateId: string) => {
    dispatch(deleteCustomPromptTemplate(promptTemplateId));
  };

  const handleChangingPromptTemplatesSortValue = (value: string) => {
    setCurrentTemplateListPageNumber(1);
    getPromptTemplates({ sortValue: value });
  };

  const handleSearchingPromptTemplates = (value: string) => {
    setTemplatesSearchValue(value);
  };

  const handleChangingPromptTemplatesFilters = (filterKey: string, items: SelectedFilterItem[]) => {
    const updatedValues = {
      ...selectedTemplatesFilterValuesByTab[activePromptTemplatesModalTab],
      [filterKey]: items,
    };
    setSelectedTemplatesFilterValuesByTab({
      ...selectedTemplatesFilterValuesByTab,
      [activePromptTemplatesModalTab]: updatedValues,
    });
  };

  const handleClearingAllPromptTemplatesFilters = () => {
    const filters = getPromptTemplatesFilters();
    const emptyFilterValues = filters.reduce((acc, { key }) => ({ ...acc, [key]: [] }), {});
    setSelectedTemplatesFilterValuesByTab({
      ...selectedTemplatesFilterValuesByTab,
      [activePromptTemplatesModalTab]: emptyFilterValues,
    });
  };

  // Query methods

  const getPromptTemplatesQueryParams = (prompts: Record<string, IPromptNode>) =>
    Object.keys(prompts).reduce(
      (acc, key) => ({
        ...acc,
        [key]: {
          prompt_template: prompts[key]?.text,
        },
      }),
      {},
    );

  const onQuery = ({
    query,
    localFilters,
  }: { query?: string; localFilters?: Record<string, MetadataFiltersType> } = {}) => {
    playgrounds.forEach(async (playground) => {
      const { id, pipelineName, prompts, session, filters } = playground;
      let currentSession = session;
      if (!pipelineName) return;
      const promptTemplateParams = getPromptTemplatesQueryParams(prompts);

      const {
        pipeline_id: pipelineId,
        output_type: outputType = PipelineOutputType.GENERATIVE,
        deepset_cloud_version: pipelineVersion,
      } = getPipelineByName(pipelineName) || {
        pipeline_id: '',
        outputType: PipelineOutputType.GENERATIVE,
      };

      if (
        (!currentSession && playgrounds.length === 1) ||
        (!currentSession && outputType === PipelineOutputType.CHAT)
      ) {
        currentSession = await createSession(pipelineId);
        updatePlaygroundValues(id, { session: currentSession });
      }

      queryPromptExplorer({
        pipelineName,
        pipelineOutputType: outputType,
        query: query || queryValue,
        filters: localFilters || filters,
        params: promptTemplateParams,
        sessionId: currentSession!,
        playgroundId: id,
        isV2: pipelineVersion === DeepsetCloudVersion.V2,
      });
    });
  };

  const onInputQueryChange = (newQuery: string) => {
    setQueryValue(newQuery);
  };

  const handleRepeatingQuery = (query: string) => {
    onInputQueryChange(query);
    onQuery({ query });
  };

  const handleSavingQueryPromptAsTemplate = (playgroundId: string, prompt: string) => {
    setActivePlaygroundPromptTemplate(playgroundId);
    promptTemplatesModalRef.current?.createCustomTemplateFromPrompt(prompt);
  };

  // Filters methods

  const onClearAllFilters = () => {
    updatePlaygroundValues(activePlaygroundFiltersDrawer, { filters: {} });
  };

  const onClickApplyFilters = (selectedFilters: Record<string, MetadataFiltersType>) => {
    updatePlaygroundValues(activePlaygroundFiltersDrawer, { filters: selectedFilters });
    setActivePlaygroundFiltersDrawer('');
    onQuery({ localFilters: selectedFilters });
  };

  // Render methods

  const renderHistory = () => {
    const [firstPlayground] = playgrounds;
    const { pipeline_id: pipelineId } = getPipelineByName(firstPlayground.pipelineName!) || {
      pipeline_id: '',
    };

    return (
      <div className={styles.history}>
        <SearchHistoryLog
          currentSessionId={firstPlayground.session!}
          pipelineId={pipelineId}
          pipelineName={firstPlayground.pipelineName!}
          onSessionChange={onSessionSelect}
          onClose={closeSearchHistory}
        />
      </div>
    );
  };

  const renderPromptExplorer = () => {
    return (
      <div className={styles.promptExplorer}>
        <div className={`${styles.promptExplorer_wrapper} ${historyVisible && styles.shrinked}`}>
          <div
            className={styles.promptExplorer_dynamicContent}
            style={{
              gridTemplateColumns: `repeat(${playgrounds.length}, ${100 / playgrounds.length}%)`,
            }}
          >
            {playgrounds.map((playground, playgroundIdx) => (
              <PipelinePlayground
                displayPipelineActionsBar
                key={playground.id}
                playground={playground}
                totalPlaygrounds={playgrounds.length}
                pipeline={getPipelineByName(playground.pipelineName || '')}
                pipelinesOptions={getPipelinesSelectOptions()}
                search={{
                  query: queryValue,
                  results: sessionQueriesResults[playground.id] || queryResults[playground.id],
                  status: queryStatus[playground.id],
                  searching: queryStatus[playground.id] === StatusCodes.IN_PROGRESS,
                  displayTypingEffect: showTypingEffect(playground.id),
                }}
                options={{
                  displayRemovePipelineButton: playgroundIdx !== 0,
                  displayHeader: playgroundIdx === 0,
                  displayExpandShrinkButton: playgroundIdx + 1 === playgrounds.length,
                  renderDescription: playgroundIdx === 0,
                }}
                promptEditorHeight={promptEditorHeight}
                activeFilters={!isEmpty(getPlaygroundById(playground.id)?.filters)}
                onPromptEditorValueChange={onPromptEditorValueChange}
                onPromptEditorPromptNodeChange={onPromptEditorPromptNodeChange}
                onSelectPipeline={handleSelectingPipeline}
                onAddNewPipeline={handleAddingNewPlayground}
                onRemovePipeline={handleRemovingPlayground}
                onDisplayFilters={setActivePlaygroundFiltersDrawer}
                onStartNewSession={handleStartingNewPlaygroundSession}
                onClearChat={handleClearingPlayground}
                promptEditorContainerExpanded={promptEditorContainerExpanded}
                onExpandShrinkPromptEditorContainer={() => {
                  setPromptEditorContainerExpanded(!promptEditorContainerExpanded);
                }}
                onTemplatesLinkClick={setActivePlaygroundPromptTemplate}
                onRepeatQueryOptionClick={handleRepeatingQuery}
                onSaveQueryPromptTemplateOptionClick={handleSavingQueryPromptAsTemplate}
                onPromptEditorResize={handlePromptEditorResize}
                onUpdatePipelinePrompt={onUpdatePipelinePrompt}
              />
            ))}
          </div>
          <div className={styles.promptExplorer_query}>
            {playgrounds.length === 1 && playgrounds[0].pipelineName && (
              <Button
                type="link"
                size="small"
                icon={<HistoryOutlined />}
                onClick={() => (historyVisible ? closeSearchHistory() : openSearchHistory())}
              />
            )}
            <Search
              className={styles.promptExplorer_query_input}
              placeholder={SEARCH_PLACEHOLDER}
              disabled={isSearching()}
              enterButton={
                <Button
                  type="primary"
                  icon={<SendOutlined />}
                  disabled={!queryValue || isSearching() || !playgrounds[0]?.pipelineName}
                  loading={isSearching()}
                >
                  {queryValue ? SUBMIT_BUTTON_LABEL : ''}
                </Button>
              }
              value={queryValue}
              onChange={(event) => onInputQueryChange(event.target.value)}
              onSearch={() => queryValue && onQuery()}
              autoFocus
            />
          </div>
        </div>
        {historyVisible && renderHistory()}
      </div>
    );
  };

  return (
    <div className={styles.promptExplorerPage}>
      <PromptTemplatesModal
        ref={promptTemplatesModalRef}
        activeTab={activePromptTemplatesModalTab}
        open={!!activePlaygroundPromptTemplate}
        isV2={
          getPipelineByName(getPlaygroundById(activePlaygroundPromptTemplate)?.pipelineName || '')
            ?.deepset_cloud_version === DeepsetCloudVersion.V2
        }
        promptTemplates={promptTemplates.data || []}
        templateListHasMore={!!promptTemplates.has_more}
        selectedSortingValue={selectedPromptTemplatesSortValue}
        filters={getPromptTemplatesFilters()}
        selectedFilterValues={selectedTemplatesFilterValuesByTab[activePromptTemplatesModalTab]}
        fetchMoreTemplates={handleFetchingMoreTemplates}
        loadingTemplates={promptTemplatesStatus === StatusCodes.IN_PROGRESS}
        onTabChange={setActivePromptTemplatesModalTab}
        onCancel={() => setActivePlaygroundPromptTemplate('')}
        onUsePromptClick={handleSelectingPromptTemplate}
        onCreateNewCustomPrompt={handleCreatingPromptTemplate}
        onUpdateCustomPromptValues={handleUpdatingPromptTemplate}
        onDeleteCustomPrompt={handleDeletingPromptTemplate}
        onSortSelectChange={handleChangingPromptTemplatesSortValue}
        onFilterSelectChange={handleChangingPromptTemplatesFilters}
        onClearAllFilters={handleClearingAllPromptTemplatesFilters}
        onSearch={handleSearchingPromptTemplates}
      />
      {!!activePlaygroundFiltersDrawer && (
        <PlaygroundFiltersDrawer
          open={!!activePlaygroundFiltersDrawer}
          onClose={(selectedFilters) => {
            updatePlaygroundValues(activePlaygroundFiltersDrawer, {
              filters: selectedFilters,
            });
            setActivePlaygroundFiltersDrawer('');
          }}
          pipelineName={getPlaygroundById(activePlaygroundFiltersDrawer)?.pipelineName!}
          emptySearchResults={emptySearchResults()}
          activeFilters={getPlaygroundById(activePlaygroundFiltersDrawer)?.filters || {}}
          onClickApplyFilters={onClickApplyFilters}
          onClearAllFilters={onClearAllFilters}
        />
      )}
      {renderPromptExplorer()}
    </div>
  );
};

export default PromptExplorerPage;
