import React from 'react';
import { RocketFilled } from '@ant-design/icons';
import { Alert } from 'antd';
import { interpolateString } from '@utils/string';
import {
  DEEPSET_CLOUD_V2_RELEASE_LOCAL_STORAGE_KEY,
  DEEPSET_CLOUD_V2_RELEASE_MESSAGE,
  DOCS_DEEPSET_CLOUD_V2_LINK,
  VERSION2_DOCS_LABEL,
} from '@constants/common';
import styles from './releaseAlert.module.scss';

const ReleaseAlert = () => {
  const DOCS_URL = import.meta.env.VITE_DOCS_URL;
  const deepsetCloudVersion2ReleaseMessageDismissed = localStorage.getItem(
    DEEPSET_CLOUD_V2_RELEASE_LOCAL_STORAGE_KEY,
  );

  return (
    <>
      {!deepsetCloudVersion2ReleaseMessageDismissed && (
        <Alert
          icon={<RocketFilled className={styles.releaseIcon} />}
          className={styles.releaseAlert}
          message={interpolateString(DEEPSET_CLOUD_V2_RELEASE_MESSAGE, {
            version2DocsLink: (
              <a href={`${DOCS_URL}${DOCS_DEEPSET_CLOUD_V2_LINK}`} target="_blank" rel="noreferrer">
                {VERSION2_DOCS_LABEL}
              </a>
            ),
          })}
          banner
          closable
          onClose={() => {
            localStorage.setItem(DEEPSET_CLOUD_V2_RELEASE_LOCAL_STORAGE_KEY, 'true');
          }}
        />
      )}
    </>
  );
};

export default ReleaseAlert;
