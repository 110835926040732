import produce from 'immer';
import { StatusCodes } from '@constants/enum/common';
import { DEFAULT_FEEDBACK_SORTING_KEY } from '@constants/pipeline-feedback';
import {
  CREATE_PIPELINE_FEEDBACK_TAG,
  DELETE_PIPELINE_FEEDBACK_TAG,
  FULFILLED,
  GET_PIPELINE_FEEDBACK,
  GET_PIPELINE_FEEDBACK_TAGS,
  IAPIPaginationData,
  IPipelineFeedbackData,
  ITag,
  PENDING,
  PROVIDE_SEARCH_RESULT_FEEDBACK,
  REJECTED,
  RESET_PIPELINE_FEEDBACK_DATA,
  RESET_PIPELINE_FEEDBACK_TAGS,
  RESET_PROVIDED_FEEDBACK_STATUS,
  SELECT_FEEDBACK_SORT_VALUE,
  SET_PROVIDED_FEEDBACK_BY_RESULT_ID,
  UPDATE_SEARCH_RESULT_FEEDBACK,
} from '@redux/types/types';

interface IInitialStateProps {
  pipelineFeedback: IAPIPaginationData<IPipelineFeedbackData[]>;
  getPipelineFeedbackStatus: StatusCodes;
  providedPipelineFeedbackStatus: StatusCodes;
  updatedPipelineFeedbackStatus: StatusCodes;
  providedFeedbackByResultId: Record<string, IPipelineFeedbackData>;
  pipelineFeedbackTagsByPipelineId: Record<string, IAPIPaginationData<ITag[]>>;
  createPipelineFeedbackTagStatus: StatusCodes;
  deletePipelineFeedbackTagStatus: StatusCodes;
  feedbackSortValue: string;
}

export const initialState: IInitialStateProps = {
  providedFeedbackByResultId: {},
  getPipelineFeedbackStatus: StatusCodes.IDLE,
  providedPipelineFeedbackStatus: StatusCodes.IDLE,
  updatedPipelineFeedbackStatus: StatusCodes.IDLE,
  pipelineFeedback: {
    data: [],
    has_more: false,
    total: 0,
  },
  pipelineFeedbackTagsByPipelineId: {},
  createPipelineFeedbackTagStatus: StatusCodes.IDLE,
  deletePipelineFeedbackTagStatus: StatusCodes.IDLE,
  feedbackSortValue: DEFAULT_FEEDBACK_SORTING_KEY,
};

const pipelineFeedbackReducer = (state = initialState, action: any) => {
  return produce(state, (draft) => {
    const localDraft = draft;
    switch (action.type) {
      case RESET_PIPELINE_FEEDBACK_DATA:
        localDraft.pipelineFeedback = initialState.pipelineFeedback;
        localDraft.getPipelineFeedbackStatus = initialState.getPipelineFeedbackStatus;
        break;

      case RESET_PIPELINE_FEEDBACK_TAGS:
        localDraft.pipelineFeedbackTagsByPipelineId = initialState.pipelineFeedbackTagsByPipelineId;
        break;

      case RESET_PROVIDED_FEEDBACK_STATUS:
        localDraft.providedPipelineFeedbackStatus = initialState.providedPipelineFeedbackStatus;
        localDraft.updatedPipelineFeedbackStatus = initialState.updatedPipelineFeedbackStatus;
        break;

      case SELECT_FEEDBACK_SORT_VALUE:
        localDraft.feedbackSortValue = action.payload;
        break;

      case SET_PROVIDED_FEEDBACK_BY_RESULT_ID: {
        const { resultId, feedback } = action.payload;
        localDraft.providedFeedbackByResultId[resultId] = feedback;
        break;
      }

      case `${UPDATE_SEARCH_RESULT_FEEDBACK}/${PENDING}`: {
        localDraft.updatedPipelineFeedbackStatus = StatusCodes.IN_PROGRESS;
        break;
      }
      case `${UPDATE_SEARCH_RESULT_FEEDBACK}/${FULFILLED}`: {
        const { resultId } = action.meta.arg;
        localDraft.updatedPipelineFeedbackStatus = StatusCodes.SUCCESS;
        localDraft.providedFeedbackByResultId[resultId] = action.payload;
        break;
      }
      case `${UPDATE_SEARCH_RESULT_FEEDBACK}/${REJECTED}`: {
        localDraft.updatedPipelineFeedbackStatus = StatusCodes.ERROR;
        break;
      }

      case `${PROVIDE_SEARCH_RESULT_FEEDBACK}/${PENDING}`: {
        localDraft.providedPipelineFeedbackStatus = StatusCodes.IN_PROGRESS;
        break;
      }
      case `${PROVIDE_SEARCH_RESULT_FEEDBACK}/${FULFILLED}`: {
        const { resultId } = action.meta.arg;
        localDraft.providedPipelineFeedbackStatus = StatusCodes.SUCCESS;
        localDraft.providedFeedbackByResultId[resultId] = action.payload;
        break;
      }
      case `${PROVIDE_SEARCH_RESULT_FEEDBACK}/${REJECTED}`: {
        localDraft.providedPipelineFeedbackStatus = StatusCodes.ERROR;
        break;
      }

      case `${GET_PIPELINE_FEEDBACK_TAGS}/${FULFILLED}`: {
        const { pipelineId } = action.meta.arg;
        localDraft.pipelineFeedbackTagsByPipelineId[pipelineId] = action.payload;
        break;
      }

      case `${GET_PIPELINE_FEEDBACK}/${PENDING}`: {
        localDraft.getPipelineFeedbackStatus = StatusCodes.IN_PROGRESS;
        break;
      }
      case `${GET_PIPELINE_FEEDBACK}/${FULFILLED}`: {
        localDraft.getPipelineFeedbackStatus = StatusCodes.SUCCESS;
        localDraft.pipelineFeedback = action.payload;
        break;
      }
      case `${GET_PIPELINE_FEEDBACK}/${REJECTED}`: {
        localDraft.getPipelineFeedbackStatus = StatusCodes.ERROR;
        break;
      }

      case `${CREATE_PIPELINE_FEEDBACK_TAG}/${PENDING}`: {
        localDraft.createPipelineFeedbackTagStatus = StatusCodes.IN_PROGRESS;
        break;
      }
      case `${CREATE_PIPELINE_FEEDBACK_TAG}/${FULFILLED}`: {
        localDraft.createPipelineFeedbackTagStatus = StatusCodes.SUCCESS;
        break;
      }
      case `${CREATE_PIPELINE_FEEDBACK_TAG}/${REJECTED}`: {
        localDraft.createPipelineFeedbackTagStatus = StatusCodes.ERROR;
        break;
      }

      case `${DELETE_PIPELINE_FEEDBACK_TAG}/${PENDING}`: {
        localDraft.deletePipelineFeedbackTagStatus = StatusCodes.IN_PROGRESS;
        break;
      }
      case `${DELETE_PIPELINE_FEEDBACK_TAG}/${FULFILLED}`: {
        localDraft.deletePipelineFeedbackTagStatus = StatusCodes.SUCCESS;
        break;
      }
      case `${DELETE_PIPELINE_FEEDBACK_TAG}/${REJECTED}`: {
        localDraft.deletePipelineFeedbackTagStatus = StatusCodes.ERROR;
        break;
      }

      default:
        break;
    }

    return localDraft;
  });
};

export default pipelineFeedbackReducer;
