import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { METERING_PAGE_HEADER } from '@constants/metering';
import { resetUsageData } from '@redux/actions/usageActions';
import TopNavigation from '@components/settings/TopNavigation';
import MeteringUsage from './features/MeteringUsage/MeteringUsage';
import PipelinesUsage from './features/PipelinesUsage/PipelinesUsage';
import PipelinesUsageGraph from './features/PipelinesUsageGraph/PipelinesUsageGraph';
import styles from './meteringPage.module.scss';

const MeteringPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetUsageData);
  }, []);

  return (
    <div className={styles.container}>
      <TopNavigation />
      <div className={styles.content}>
        <div className={styles.section}>
          <h4 className={styles.header}>{METERING_PAGE_HEADER}</h4>
          <MeteringUsage />
        </div>
        <PipelinesUsageGraph />
        <PipelinesUsage />
      </div>
    </div>
  );
};

export default MeteringPage;
