import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ReactFlowProvider } from 'reactflow';
import {
  ArrowsAltOutlined,
  CodeOutlined,
  EditOutlined,
  InfoCircleOutlined,
  PartitionOutlined,
  ShrinkOutlined,
} from '@ant-design/icons';
import { Alert, Button, Divider, Input, InputRef, Switch, Tooltip } from 'antd';
import { isPipelineDeployed, isPipelineInProduction, pipelineInProgress } from '@utils/pipelines';
import { isValidDataName } from '@utils/string';
import { usePreventPageExitPrompt } from '@hooks/usePreventPageExitPrompt';
import { CANCEL_BUTTON_LABEL, COMMON_FORM_ERRORS } from '@constants/common';
import { MessageCodes, StatusCodes } from '@constants/enum/common';
import {
  ATTEMPT_READ_ONLY_EDIT,
  CODE_EDITOR_TAB,
  DEPLOY_BUTTON_LABEL,
  DEPLOYING_BUTTON_LABEL,
  DIFF_SWITCH_LABEL,
  DRAFT_LABEL,
  PREVENT_PAGE_EXIT_MESSAGE,
  RESET_BUTTON_LABEL,
  RESET_TOOLTIP_MESSAGE,
  SAVE_BUTTON_LABEL,
  UNDEPLOY_BUTTON_LABEL,
  VISUALIZER_TAB,
} from '@constants/pipeline-designer';
import {
  CREATE_PIPELINE_MODAL_PIPELINE_NAME_PLACEHOLDER,
  EDIT_PIPELINE_NAME_LABEL,
  PIPELINE_PRODUCTION_LABEL,
  PIPELINE_PRODUCTION_TOOLTIP_LABEL,
  PipelineStatusCodes,
  UNDEPLOYING_BUTTON_LABEL,
} from '@constants/pipelines';
import {
  createPipeline,
  deployPipeline,
  fetchPipeline,
  fetchPipelineIndexing,
  fetchPipelineYaml,
  getRuntimeIssueDetection,
  resetFetchPipelineYamlStatus,
  resetMessage,
  resetNewPipelineName,
  resetPipelineErrors,
  resetRenamePipelineNameStatus,
  resetValidateNewPipelineName,
  setPipelineEditedStateTo,
  setPipelineName,
  setPipelineYamlV1,
  startPollingPipelineStatus,
  stopPollingPipelineStatus,
  undeployPipeline,
  updatePipeline,
  updatePipelineYaml,
  validatePipelineYaml,
} from '@redux/actions/pipelineActions';
import {
  fetchPipelineYamlStatusSelector,
  pipelineCreateUpdatePipelineStatusSelector,
  pipelineErrorsSelector,
  pipelineIndexingDetailsSelector,
  pipelineSelector,
  renamePipelineNameStatusSelector,
} from '@redux/selectors/pipelineSelectors';
import {
  IMessage,
  IPipeline,
  IPipelineIndexingData,
  PipelineServiceLevel,
} from '@redux/types/types';
import LoadingIndicator from '@components/common/LoadingIndicator/LoadingIndicator';
import MultipleErrorsPopup from '@components/multipleErrosPopup/MultipleErrorsPopup';
import PipelineConfirmationModal from '@components/pipelines/pipelineConfirmationModal/PipelineConfirmationModal';
import PipelineServiceLevelBadge from '@components/pipelines/pipelineServiceLevelBadge/PipelineServiceLevelBadge';
import PipelineServiceLevelSwitch from '@components/pipelines/pipelineServiceLevelSwitch/PipelineServiceLevelSwitch';
import PipelineStatusTag from '@components/pipelineStatusTag/PipelineStatusTag';
import styles from './pipelineDesigner.module.scss';

const YamlEditor = React.lazy(
  () => import(/* webpackChunkName: "YamlEditor" */ '@components/yamlEditor/YamlEditor'),
);
const YamlVisualizer = React.lazy(
  () =>
    import(/* webpackChunkName: "YamlVisualizer" */ '@components/yamlVisualizer/YamlVisualizer'),
);

function PipelineDesignerYaml() {
  const dispatch = useDispatch();
  const pipeline: IPipeline = useSelector(pipelineSelector);
  const renamePipelineNameStatus: StatusCodes = useSelector(renamePipelineNameStatusSelector);
  const fetchPipelineYamlStatus: StatusCodes = useSelector(fetchPipelineYamlStatusSelector);
  const pipelineCreateUpdatePipelineStatus: StatusCodes = useSelector(
    pipelineCreateUpdatePipelineStatusSelector,
  );
  const pipelineErrors = useSelector(pipelineErrorsSelector);
  const pipelineIndexingData: IPipelineIndexingData = useSelector(pipelineIndexingDetailsSelector);
  const { name, status, edited, indexing, yaml, service_level: serviceLevel } = pipeline;
  const [code, setCode] = useState(yaml);
  const [loadingButton, setLoadingButton] = useState(false);
  const [savingPipeline, setSavingPipeline] = useState(false);
  const [codeEditorExtended, setCodeEditorExtended] = useState(false);
  const [yamlVisualizerExtended, setYamlVisualizerExtended] = useState(false);
  const [yamlEditorDiff, setYamlEditorDiff] = useState(false);
  const [editPipelineName, setEditPipelineName] = useState(false);
  const [openPipelineConfirmationModal, setOpenPipelineConfirmationModal] = useState(false);
  const [successfulValidationPipelineName, setSuccessfulValidationPipelineName] =
    useState<boolean>(true);
  const [warningMessage, setWarningMessage] = useState<IMessage>({
    type: MessageCodes.WARNING,
    content: '',
  });
  const pipelineNameInputRef = useRef<InputRef>(null);
  const editPipelineNameButtonRef = useRef<HTMLButtonElement>(null);

  const { pipelineName } = useParams() as { pipelineName: string };
  const [newPipelineName, setNewPipelineName] = useState(pipelineName);

  const isNewPipeline = !name || !pipelineName;

  const { pending_file_count: pipelinePendingFileCount } = indexing;

  usePreventPageExitPrompt(PREVENT_PAGE_EXIT_MESSAGE, !!edited);

  useEffect(() => {
    return () => {
      dispatch(resetNewPipelineName);
      dispatch(resetPipelineErrors);
      dispatch(resetFetchPipelineYamlStatus);
    };
  }, []);

  useEffect(() => {
    if (pipelineName) {
      dispatch(fetchPipeline({ pipelineName }));
      dispatch(fetchPipelineYaml(pipelineName));
      setNewPipelineName(pipelineName);
      dispatch(resetValidateNewPipelineName);
      dispatch(resetRenamePipelineNameStatus);
    }
  }, [dispatch, pipelineName]);

  const getPipelineIssues = async (yamlCode: string) => {
    await dispatch(resetPipelineErrors);
    dispatch(getRuntimeIssueDetection(pipelineName));
    dispatch(
      validatePipelineYaml({
        config: yamlCode,
        deepset_cloud_version: pipeline.deepset_cloud_version,
      }),
    );
  };

  useEffect(() => {
    if (pipeline.name === pipelineName && fetchPipelineYamlStatus === StatusCodes.SUCCESS)
      getPipelineIssues(pipeline.yaml);
  }, [yaml, pipeline.name, fetchPipelineYamlStatus]);

  useEffect(() => {
    setCode(yaml);
  }, [yaml]);

  useEffect(() => {
    if (newPipelineName && name && newPipelineName !== name) {
      dispatch(resetValidateNewPipelineName);
      dispatch(resetRenamePipelineNameStatus);
      setSuccessfulValidationPipelineName(isValidDataName(newPipelineName));
    } else setSuccessfulValidationPipelineName(true);
  }, [newPipelineName]);

  useEffect(() => {
    if (editPipelineName)
      pipelineNameInputRef.current?.focus({
        cursor: 'end',
      });
  }, [editPipelineName]);

  useEffect(() => {
    if (pipelineCreateUpdatePipelineStatus === StatusCodes.SUCCESS) {
      dispatch(fetchPipeline({ pipelineName: newPipelineName }));
      dispatch(fetchPipelineYaml(name || pipelineName));
    }
  }, [pipelineCreateUpdatePipelineStatus]);

  useEffect(() => {
    if (pipelineInProgress(status) || pipelinePendingFileCount > 0) {
      dispatch(startPollingPipelineStatus({ pipelineName: name }));
    } else {
      dispatch(stopPollingPipelineStatus());
      setLoadingButton(false);
      setSavingPipeline(false);
    }
    return () => {
      dispatch(stopPollingPipelineStatus());
    };
  }, [name, status, pipelinePendingFileCount, dispatch]);

  const storeCode = (newValue: string) => {
    setCode(newValue);
    dispatch(setPipelineEditedStateTo(true));
  };

  const resetCode = () => {
    setYamlEditorDiff(false);
    setCode(yaml);
    dispatch(setPipelineYamlV1(yaml));
    dispatch(setPipelineEditedStateTo(false));
    getPipelineIssues(yaml);
  };

  const extendCodeEditor = () => {
    setCodeEditorExtended(!codeEditorExtended);
    if (yamlVisualizerExtended) {
      setYamlVisualizerExtended(false);
    }
  };

  const extendYamlVisualizer = () => {
    setYamlVisualizerExtended(!yamlVisualizerExtended);
    if (codeEditorExtended) {
      setCodeEditorExtended(false);
    }
  };

  const showWarningMessage = (newWarningMessage: IMessage) => {
    setWarningMessage(newWarningMessage);
  };

  const onDidAttemptReadOnlyEditCode = () => {
    if (serviceLevel === PipelineServiceLevel.PRODUCTION)
      setWarningMessage({
        content: ATTEMPT_READ_ONLY_EDIT.productionPipelines,
        type: MessageCodes.WARNING,
      });
  };

  const onRenamePipeline = () => {
    if (name && newPipelineName && newPipelineName !== name && isValidDataName(newPipelineName)) {
      const payload = {
        pipelineName: name,
        newPipelineName,
      };

      dispatch(updatePipeline(payload));
    }
  };

  const onSavePipeline = async () => {
    if (!pipelineName && !name) {
      setSuccessfulValidationPipelineName(false);
      setEditPipelineName(true);
    } else {
      setSavingPipeline(true);
      dispatch(resetMessage);

      if ((pipelineName || name) && pipeline.pipeline_id) {
        await dispatch(updatePipelineYaml({ code, name }));
        getPipelineIssues(code);
      } else if (!pipeline.pipeline_id) {
        const payload = {
          code,
          pipelineName: name,
        };

        dispatch(createPipeline(payload));
      }
    }
  };

  const isDesignerReadOnly = () => {
    if (serviceLevel === PipelineServiceLevel.PRODUCTION) return true;
    if (pipelineInProgress(status)) return true;
    return false;
  };

  const nonCodeEditorExtendedStyle =
    !codeEditorExtended && !yamlVisualizerExtended ? styles.mainTab : '';
  const nonYamlEditorExtendedStyle =
    !codeEditorExtended && !yamlVisualizerExtended ? styles.secondaryTab : '';

  const codeEditorExtendedStyle =
    codeEditorExtended && !yamlVisualizerExtended ? styles.tabExtended : '';
  const codeEditorNonExtendedStyle =
    !codeEditorExtended && yamlVisualizerExtended ? styles.tabNonExtended : '';
  const yamlVisualizerExtendedStyle =
    !codeEditorExtended && yamlVisualizerExtended ? styles.tabExtended : '';
  const yamlVisualizerNonExtendedStyle =
    codeEditorExtended && !yamlVisualizerExtended ? styles.tabNonExtended : '';

  return (
    <>
      <div className={styles.container}>
        <div className={styles.settings}>
          <div
            className={`${styles.settings_name} ${
              editPipelineName ? styles.settings_name_editName : ''
            }`}
          >
            <div className={styles.settings_badge}>
              <PipelineServiceLevelBadge serviceLevel={serviceLevel} />
            </div>
            {editPipelineName ? (
              <div className={styles.settings_nameContent}>
                <Input
                  placeholder={CREATE_PIPELINE_MODAL_PIPELINE_NAME_PLACEHOLDER}
                  defaultValue={pipeline.name}
                  className={styles.settings_name_input}
                  onChange={(e) => setNewPipelineName(e.target.value)}
                  ref={pipelineNameInputRef}
                  status={
                    !successfulValidationPipelineName ||
                    renamePipelineNameStatus === StatusCodes.ERROR
                      ? 'error'
                      : ''
                  }
                  onBlur={(e) => {
                    if (pipeline.pipeline_id) onRenamePipeline();
                    else {
                      setNewPipelineName(e.target.value);
                      dispatch(setPipelineName(e.target.value));
                    }
                    setEditPipelineName(!editPipelineName);
                  }}
                />
                {!successfulValidationPipelineName && (
                  <div className={styles.settings_name_input_error}>
                    {COMMON_FORM_ERRORS.INVALID_CHARACTERS}
                  </div>
                )}
              </div>
            ) : (
              <span>{pipeline.name || DRAFT_LABEL}</span>
            )}
            <div className={styles.settings_icon}>
              <Tooltip title={EDIT_PIPELINE_NAME_LABEL}>
                <Button
                  type="text"
                  onClick={() => setEditPipelineName(!editPipelineName)}
                  ref={editPipelineNameButtonRef}
                >
                  <EditOutlined />
                </Button>
              </Tooltip>
            </div>
            <div className={styles.settings_pipelineStatus}>
              <PipelineStatusTag
                pipeline={pipeline as IPipeline}
                getIndexingDetails={(pipelineNameArg) =>
                  dispatch(fetchPipelineIndexing(pipelineNameArg))
                }
                indexingData={pipelineIndexingData}
              />
            </div>
          </div>
          <div className={styles.settings_actions}>
            {serviceLevel !== PipelineServiceLevel.DRAFT && (
              <span className={styles.switchServiceLevel}>
                <Tooltip placement="bottom" title={PIPELINE_PRODUCTION_TOOLTIP_LABEL}>
                  <InfoCircleOutlined className={styles.switchServiceLevel_info} />
                </Tooltip>
                <span>{PIPELINE_PRODUCTION_LABEL}</span>{' '}
                <PipelineServiceLevelSwitch
                  serviceLevel={serviceLevel}
                  afterSwitch={() => dispatch(fetchPipeline({ pipelineName: name }))}
                  pipelineName={name}
                  size="small"
                />
              </span>
            )}
            {edited && (
              <Tooltip placement="bottom" title={RESET_TOOLTIP_MESSAGE}>
                <Button
                  disabled={pipelineInProgress(status)}
                  onClick={resetCode}
                  type="link"
                  className={styles.resetButton}
                >
                  {RESET_BUTTON_LABEL}
                </Button>
              </Tooltip>
            )}
            {!isPipelineInProduction(status, serviceLevel) && !loadingButton && (
              <Button
                type={edited || isNewPipeline ? 'primary' : 'default'}
                className={styles.saveButton}
                disabled={pipelineInProgress(status) || (!edited && pipeline.pipeline_id !== '')}
                data-testid="savePipeline_button"
                onClick={() => onSavePipeline()}
              >
                {SAVE_BUTTON_LABEL}
              </Button>
            )}
            {isPipelineDeployed(status) ? (
              <Button
                loading={pipelineInProgress(status) || loadingButton}
                onClick={() => {
                  setOpenPipelineConfirmationModal(true);
                }}
                className={styles.deployButton}
              >
                {status === PipelineStatusCodes.UNDEPLOYMENT_IN_PROGRESS ||
                status === PipelineStatusCodes.UNDEPLOYMENT_SCHEDULED
                  ? UNDEPLOYING_BUTTON_LABEL
                  : UNDEPLOY_BUTTON_LABEL}
              </Button>
            ) : (
              <Button
                type="primary"
                className={styles.deployButton}
                disabled={edited || pipeline.pipeline_id === ''}
                loading={(pipelineInProgress(status) || loadingButton) && !savingPipeline}
                onClick={() => {
                  setLoadingButton(true);
                  dispatch(deployPipeline(name));
                  dispatch(startPollingPipelineStatus({ pipelineName: name }));
                }}
              >
                {((status === PipelineStatusCodes.DEPLOYMENT_IN_PROGRESS ||
                  status === PipelineStatusCodes.DEPLOYMENT_SCHEDULED) &&
                  !savingPipeline) ||
                loadingButton
                  ? DEPLOYING_BUTTON_LABEL
                  : DEPLOY_BUTTON_LABEL}
              </Button>
            )}
          </div>
        </div>

        <Divider className={styles.pipelineDesigner_divider} />

        <div className={styles.yaml}>
          <div
            className={`${styles.tabContainer} ${styles.tabDivider} ${nonCodeEditorExtendedStyle} ${codeEditorExtendedStyle} ${codeEditorNonExtendedStyle}`}
          >
            <div className={styles.tabContainer_tab}>
              <div>
                <CodeOutlined className={styles.tabContainer_icon} />
                {!yamlVisualizerExtended && (
                  <span className={styles.tabContainer_title}>{CODE_EDITOR_TAB}</span>
                )}
              </div>
              <div className={styles.actionContainer}>
                {edited && !yamlVisualizerExtended && (
                  <span className={styles.diffButton}>
                    {DIFF_SWITCH_LABEL}
                    <Switch
                      checked={yamlEditorDiff}
                      size="small"
                      onChange={() => setYamlEditorDiff(!yamlEditorDiff)}
                    />
                  </span>
                )}
                <Button onClick={() => extendCodeEditor()} className={styles.resizeButton}>
                  {codeEditorExtended ? <ShrinkOutlined /> : <ArrowsAltOutlined />}
                </Button>
              </div>
            </div>
            {warningMessage.content && (
              <Alert
                message={warningMessage.content}
                type={warningMessage.type}
                banner
                className={styles.warningMessage}
              />
            )}
            <div className={styles.yaml_codeEditor}>
              <React.Suspense fallback={<LoadingIndicator />}>
                <YamlEditor
                  originalCode={yamlEditorDiff ? yaml : ''}
                  code={code}
                  onStore={storeCode}
                  readOnly={isDesignerReadOnly()}
                  onDidAttemptReadOnlyEditCode={() => onDidAttemptReadOnlyEditCode()}
                />
              </React.Suspense>
              {pipelineErrors.length > 0 && <MultipleErrorsPopup errors={pipelineErrors} />}
            </div>
          </div>
          <div
            className={`${styles.tabContainer} ${nonYamlEditorExtendedStyle} ${yamlVisualizerExtendedStyle} ${yamlVisualizerNonExtendedStyle}`}
          >
            <div className={styles.tabContainer_tab}>
              <div>
                <PartitionOutlined className={styles.tabContainer_icon} />
                <span className={styles.tabContainer_title}>{VISUALIZER_TAB}</span>
              </div>
              <Button onClick={() => extendYamlVisualizer()} className={styles.resizeButton}>
                {yamlVisualizerExtended ? <ShrinkOutlined /> : <ArrowsAltOutlined />}
              </Button>
            </div>
            <ReactFlowProvider>
              <React.Suspense fallback={<LoadingIndicator />}>
                <YamlVisualizer
                  code={code}
                  showMessage={showWarningMessage}
                  yamlVisualizerExtended={yamlVisualizerExtended}
                />
              </React.Suspense>
            </ReactFlowProvider>
          </div>
        </div>
      </div>
      <PipelineConfirmationModal
        open={openPipelineConfirmationModal}
        okText={UNDEPLOY_BUTTON_LABEL}
        onOk={() => {
          setLoadingButton(true);
          dispatch(undeployPipeline(name));
          dispatch(startPollingPipelineStatus({ pipelineName: name }));
          setOpenPipelineConfirmationModal(false);
        }}
        cancelText={CANCEL_BUTTON_LABEL}
        onCancel={() => {
          setOpenPipelineConfirmationModal(false);
        }}
        pipelines={openPipelineConfirmationModal ? [pipeline] : []}
        undeployingPipeline
      />
    </>
  );
}

export default PipelineDesignerYaml;
