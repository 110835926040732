import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DeleteOutlined, FilterOutlined, UploadOutlined } from '@ant-design/icons';
import { Alert, Button, Drawer, Empty, Space } from 'antd';
import { getFileExtension, readableBytes } from '@utils/common';
import { interpolateString } from '@utils/string';
import { uploadFilesApi } from '@api/upload';
import useEffectUpdateOnly from '@hooks/useEffectUpdateOnly';
import { VIEW_METADATA_BUTTON_LABEL } from '@constants/common';
import { UploadFileType } from '@constants/constant';
import {
  DECLINE_BUTTON_LABEL,
  DEFAULT_PAGE_SIZE,
  DELETE_ALL_CONFIRMATION_MESSAGE,
  DELETE_ALL_FILES_BUTTON_LABEL,
  DELETE_BUTTON_LABEL,
  DELETE_FILE_CONFIRMATION_MESSAGE,
  DELETE_SELECTED_FILE_MESSAGE,
  DELETE_SELECTED_FILES_PLURAL_LABEL,
  DELETE_SELECTED_FILES_SINGULAR_LABEL,
  DOWNLOAD_BUTTON_LABEL,
  EMPTY_FILES_TABLE_MESSAGE,
  FILE_SORTING_DATATABLE_OPTIONS,
  METADATA_FILTERS_APPLY_LABEL,
  METADATA_FILTERS_CLEAR_ALL_LABEL,
  METADATA_FILTERS_LABEL,
  PREVIEW_FILE_BUTTON_LABEL,
  PREVIEW_FILE_DRAWER_TITLE,
  UPLOAD_BUTTON_LABEL,
  UPLOAD_DESCRIPTION_MESSAGE,
  UPLOAD_FILE_BUTTON_LABEL,
} from '@constants/data-page';
import { SelectedFilters } from '@constants/data-table';
import { StatusCodes } from '@constants/enum/common';
import {
  deleteAllFiles,
  deleteFile,
  deleteMultipleFiles,
  downloadFile,
  getFileContent,
  getWorkspaceFiles,
  resetFileContent,
  resetFilesMessage,
  selectFileDocument,
  updateFileMeta as updateFileMetaAction,
} from '@redux/actions/fileActions';
import {
  getPipelineMeta,
  selectMetaDataFilterValues,
  setAppliedMetaDataFilterValues,
} from '@redux/actions/metadataFiltersActions';
import {
  fileActionStatusSelector,
  fileContentSelector,
  fileFetchStatusSelector,
  fileMessageSelector,
  filesSelector,
  getFileContentStatusSelector,
  selectedFileDocumentSelector,
  sortValueSelector,
  updateFileMetaStatusSelector,
} from '@redux/selectors/fileSelectors';
import {
  appliedMetaFilterValuesSelector,
  metadataFiltersStatusSelector,
  selectedMetaFilterValuesSelector,
} from '@redux/selectors/metadataFiltersSelectors';
import { IMessage, MetadataFiltersType } from '@redux/types/types';
import LoadingIndicator from '@components/common/LoadingIndicator/LoadingIndicator';
import DataTable from '@components/dataTable/DataTable';
import DataTableActions from '@components/dataTable/DataTableActions';
import MetadataFilters from '@components/metadataFilters/MetadataFilters';
import MetadataModal from '@components/metadataModal/MetadataModal';
import UploadFileModal from '@components/uploadModal/UploadFileModal';
import styles from './filesPage.module.scss';

const DocumentsPreview = React.lazy(
  () =>
    import(
      /* webpackChunkName: "DocumentsPreview" */ '@components/documentsPreview/DocumentsPreview'
    ),
);

const PIPELINE_NAME_FOR_METADATA_ON_FILES_PAGE = 'workspace-pipeline';
export interface IGetFilesPayload {
  currentPage: number;
  pageSize: number;
  searchValue: string;
  sortValue?: string;
  filterValues?: SelectedFilters;
  after?: Record<string, unknown>;
}

const FilesPage = () => {
  const dispatch = useDispatch();
  const message: IMessage = useSelector(fileMessageSelector);
  const actionStatus: StatusCodes = useSelector(fileActionStatusSelector);
  const fetchStatus: StatusCodes = useSelector(fileFetchStatusSelector);
  const { data, total }: any = useSelector(filesSelector);
  const selectedFileDocument = useSelector(selectedFileDocumentSelector);
  const fileContent = useSelector(fileContentSelector);
  const getFileContentStatus = useSelector(getFileContentStatusSelector);
  const selectedSortValue: string = useSelector(sortValueSelector);
  const statusMetadataFilters = useSelector(metadataFiltersStatusSelector);
  const selectedMetaFilterValues = useSelector(selectedMetaFilterValuesSelector);
  const appliedMetaFilterValues = useSelector(appliedMetaFilterValuesSelector);
  const updateFileMetaStatus = useSelector(updateFileMetaStatusSelector);
  const [fileToDelete, setFileToDelete] = useState('');
  const [totalSelectedItems, setTotalSelectedItems] = useState(0);
  const [isUploadModalVisible, setIsUploadModalVisibleTo] = useState(false);
  const [isMetaModalVisible, setIsMetaModalVisible] = useState(false);
  const [currentModalMetaData, setCurrentModalMetaData] = useState<Record<string, unknown>>({});
  const [openMetadataFilters, setOpenMetadataFilters] = useState(false);
  const [cleanedMetaFilterValues, setCleanedMetaFilterValues] = useState(false);
  const [getFilesWithMetadataFiltersPayload, setGetFilesWithMetadataFiltersPayload] =
    useState<IGetFilesPayload>({
      currentPage: 0,
      pageSize: DEFAULT_PAGE_SIZE,
      searchValue: '',
    });
  const loading =
    actionStatus === StatusCodes.IN_PROGRESS || fetchStatus === StatusCodes.IN_PROGRESS;

  enum MenuActions {
    ViewMetadata = 'VIEW_METADATA',
    Delete = 'DELETE',
    Download = 'DOWNLOAD',
  }

  useEffect(() => {
    dispatch(resetFileContent);
    dispatch(selectMetaDataFilterValues({}));
    dispatch(setAppliedMetaDataFilterValues({}));
  }, []);

  useEffect(() => {
    if (openMetadataFilters) dispatch(selectMetaDataFilterValues(appliedMetaFilterValues));
  }, [openMetadataFilters]);

  useEffect(() => {
    dispatch(resetFilesMessage);
    dispatch(getPipelineMeta({ pipelineName: PIPELINE_NAME_FOR_METADATA_ON_FILES_PAGE }));
  }, [dispatch]);

  const getFiles = (
    currentPage: number,
    pageSize: number,
    searchValue: string,
    sortValue?: string,
    filterValues?: SelectedFilters,
    after?: Record<string, unknown>,
    metadataFilterValues?: Record<string, MetadataFiltersType>,
  ) => {
    const afterValue = { value: '', fileId: '' };
    if (after) {
      if ((sortValue && sortValue.includes('name')) || selectedSortValue.includes('name'))
        afterValue.value = after.name as string;
      else afterValue.value = after.created_at as string;
      afterValue.fileId = after.file_id as string;
    }

    return dispatch(
      getWorkspaceFiles({
        currentPage,
        pageSize,
        searchValue,
        sortValue,
        after: after ? afterValue : undefined,
        metadataFilterValues,
      }),
    );
  };

  const getFileById = (fileId: string) => {
    return data.find((file: any) => file.file_id === fileId);
  };

  const getFileMetadataById = (fileId: string) => {
    const { meta } = getFileById(fileId) || {};
    return { ...meta, file_id: fileId };
  };

  const updateFileMeta = (fileId: string, updateMeta: Record<string, unknown>) => {
    dispatch(updateFileMetaAction({ fileId, meta: updateMeta }));
  };

  useEffectUpdateOnly(() => {
    if (!selectedFileDocument || !fileContent) return;
    if (getFileContentStatus === StatusCodes.SUCCESS) {
      dispatch(selectFileDocument({ ...selectedFileDocument, contents: [{ value: fileContent }] }));
    }
  }, [getFileContentStatus]);

  useEffectUpdateOnly(() => {
    if (updateFileMetaStatus === StatusCodes.SUCCESS) {
      dispatch(getWorkspaceFiles({ currentPage: 1, pageSize: 10, searchValue: '' }));
      setIsMetaModalVisible(false);
    }
  }, [updateFileMetaStatus]);

  const onViewMetadata = (fileId: string) => {
    setIsMetaModalVisible(true);
    setCurrentModalMetaData(getFileMetadataById(fileId));
  };

  // TODO: Remove this when preview for other types is implemented
  const isValidFileFormatForPreview = (file: File | string) => {
    const fileType = getFileExtension(file);
    const supportedFileTypes = [
      UploadFileType.txt,
      UploadFileType.pdf,
      UploadFileType.json,
      UploadFileType.html,
      UploadFileType.md,
      UploadFileType.xml,
      UploadFileType.csv,
      UploadFileType.docx,
    ];

    return supportedFileTypes.includes(fileType as UploadFileType);
  };

  const openDocumentPreview = async (fileId: string, fileName: string) => {
    dispatch(selectFileDocument({ contents: [], fileName, fileId }));
    dispatch(getFileContent({ fileId, fileName }));
  };

  const closeDocumentPreview = () => {
    dispatch(selectFileDocument(null));
  };

  const onDeleteFile = async (fileId: string) => {
    dispatch(deleteFile(fileId));
  };

  const onDeleteMultipleFiles = async (fileIds: string[]) => {
    dispatch(deleteMultipleFiles(fileIds));
  };

  const onDeleteAllFiles = async () => {
    await dispatch(deleteAllFiles());
  };

  const onUpload = () => {
    setIsUploadModalVisibleTo(true);
  };

  const applyMetadataFilters = (metadataFiltersExists: boolean) => {
    const { currentPage, pageSize, searchValue, sortValue, filterValues } =
      getFilesWithMetadataFiltersPayload;
    getFiles(
      currentPage,
      pageSize,
      searchValue,
      sortValue,
      filterValues,
      undefined,
      metadataFiltersExists ? selectedMetaFilterValues : undefined,
    );
  };

  const onClearAllFilters = () => {
    dispatch(selectMetaDataFilterValues({}));
    dispatch(setAppliedMetaDataFilterValues({}));
    applyMetadataFilters(false);
    setCleanedMetaFilterValues(true);
  };

  const onClickApplyMetadataFilters = async () => {
    dispatch(setAppliedMetaDataFilterValues(selectedMetaFilterValues));
    applyMetadataFilters(true);
    setCleanedMetaFilterValues(false);
  };

  const onOpenMetadataFilters = (payload: IGetFilesPayload) => {
    setGetFilesWithMetadataFiltersPayload({ ...payload });
    setOpenMetadataFilters(true);
  };

  const handleMoreActionClick = (key: string, fileName: string, fileId: string) => {
    if (key === MenuActions.ViewMetadata) onViewMetadata(fileId);
    if (key === MenuActions.Delete) setFileToDelete(fileId);
    if (key === MenuActions.Download) dispatch(downloadFile({ fileId, fileName }));
  };

  const renderMetaDataModal = () => {
    return (
      <MetadataModal
        data={currentModalMetaData}
        nonEditableFields={['file_id']}
        updating={updateFileMetaStatus === StatusCodes.IN_PROGRESS}
        open={isMetaModalVisible}
        onEdit={(updateMeta: Record<string, unknown>) =>
          updateFileMeta(currentModalMetaData.file_id as any, updateMeta)
        }
        onOk={() => setIsMetaModalVisible(false)}
        onCancel={() => setIsMetaModalVisible(false)}
      />
    );
  };

  const renderMetadataFilters = () => {
    return (
      <Drawer
        title={METADATA_FILTERS_LABEL}
        onClose={() => setOpenMetadataFilters(false)}
        open={openMetadataFilters}
        extra={
          <Space>
            <Button onClick={() => onClearAllFilters()}>{METADATA_FILTERS_CLEAR_ALL_LABEL}</Button>
            <Button onClick={() => onClickApplyMetadataFilters()} type="primary">
              {METADATA_FILTERS_APPLY_LABEL}
            </Button>
          </Space>
        }
        className={styles.metadataFiltersDrawer}
      >
        <MetadataFilters
          pipelineName={PIPELINE_NAME_FOR_METADATA_ON_FILES_PAGE}
          requestWithoutErrors={statusMetadataFilters === StatusCodes.SUCCESS}
          showAll
        />
      </Drawer>
    );
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (name: string, record: any) => (
        <Button
          type="link"
          size="small"
          className={styles.nameLink}
          onClick={() => {
            if (isValidFileFormatForPreview(record)) openDocumentPreview(record.file_id, name);
          }}
        >
          {name}
        </Button>
      ),
    },
    {
      title: 'Size',
      dataIndex: 'size',
      key: 'size',
      width: '10%',
      render: (size: number) => readableBytes(size),
    },
    {
      title: 'Created At',
      dataIndex: 'created_at',
      key: 'created_at',
      width: '20%',
      render: (created_at: string) => new Date(created_at).toLocaleString(),
    },
    {
      key: 'action',
      width: '10%',
      align: 'right' as const,
      render: (text: any, record: any) => {
        const items = [
          {
            label: VIEW_METADATA_BUTTON_LABEL,
            key: MenuActions.ViewMetadata,
          },
          {
            label: DOWNLOAD_BUTTON_LABEL,
            key: MenuActions.Download,
          },
          {
            label: DELETE_BUTTON_LABEL,
            key: MenuActions.Delete,
            danger: true,
            icon: <DeleteOutlined />,
          },
        ];
        return (
          <DataTableActions
            menu={{
              items,
              onClick: ({ key }) => handleMoreActionClick(key, record.name, record.file_id),
              'data-testid': 'files_tableRow_moreActions_button',
            }}
            item={record.file_id}
            itemToDelete={fileToDelete}
            onDelete={onDeleteFile}
            deleteConfirmationMessage={DELETE_FILE_CONFIRMATION_MESSAGE}
            cancelButtonLabel={DECLINE_BUTTON_LABEL}
            onCancelDelete={() => setFileToDelete('')}
            primaryButton={{
              label: PREVIEW_FILE_BUTTON_LABEL,
              action: (fileId: string) => openDocumentPreview(fileId, record.name),
              visible: isValidFileFormatForPreview(record),
            }}
          />
        );
      },
    },
  ];

  const emptyFilesTableMessage = interpolateString(EMPTY_FILES_TABLE_MESSAGE, {
    uploadFilesButton: (
      <Button key="uploadFile" type="link" onClick={() => onUpload()} style={{ padding: 0 }}>
        {UPLOAD_BUTTON_LABEL}
      </Button>
    ),
  });

  return (
    <div data-testid="filesPage" className="content-wrapper">
      {renderMetaDataModal()}
      {isUploadModalVisible && (
        <UploadFileModal
          multiple
          fileTypes={[
            UploadFileType.txt,
            UploadFileType.pdf,
            UploadFileType.docx,
            UploadFileType.pptx,
            UploadFileType.xlsx,
            UploadFileType.xml,
            UploadFileType.csv,
            UploadFileType.html,
            UploadFileType.md,
            UploadFileType.json,
          ]}
          showUploadList={false}
          onUpload={uploadFilesApi}
          onCancel={() => setIsUploadModalVisibleTo(false)}
          afterUpload={() =>
            dispatch(getWorkspaceFiles({ currentPage: 1, pageSize: 10, searchValue: '' }))
          }
          uploadDescriptionMessage={UPLOAD_DESCRIPTION_MESSAGE}
          showFileSizeLimitMessage
        />
      )}
      {message && message.content && <Alert message={message.content} type={message.type} banner />}
      <div>
        <DataTable
          data={data}
          total={total}
          loading={loading}
          testId="filesTable"
          refetch={actionStatus === StatusCodes.SUCCESS}
          columns={columns}
          getData={getFiles}
          sorting={{
            selectedValue: selectedSortValue,
            options: FILE_SORTING_DATATABLE_OPTIONS,
          }}
          pagination={{ pageSize: DEFAULT_PAGE_SIZE, cursorPagination: true }}
          primaryAction={{
            label: UPLOAD_FILE_BUTTON_LABEL,
            onClick: onUpload,
            testid: 'filesPage_uploadFile_button',
            icon: <UploadOutlined />,
          }}
          filtersAction={{
            secondary: true,
            onClick: (payload?: IGetFilesPayload) => {
              if (payload) onOpenMetadataFilters(payload);
            },
            filtersApplied: !!Object.keys(selectedMetaFilterValues).length,
            testid: 'filesPage_metadataFilters_button',
            icon: <FilterOutlined />,
            tooltipMessage: METADATA_FILTERS_LABEL,
          }}
          appliedMetaFilterValues={appliedMetaFilterValues}
          cleanedMetaFilterValues={cleanedMetaFilterValues}
          locale={{
            emptyText: (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={emptyFilesTableMessage} />
            ),
          }}
          rowKey="file_id"
          selectActions={[
            {
              type: 'default',
              danger: true,
              label: (totalSelectedItems === 1
                ? interpolateString(DELETE_SELECTED_FILES_SINGULAR_LABEL, {
                    total: totalSelectedItems,
                  })
                : interpolateString(DELETE_SELECTED_FILES_PLURAL_LABEL, {
                    total: totalSelectedItems,
                  })) as string,
              onClick: onDeleteMultipleFiles,
              popconfirm: {
                title: DELETE_SELECTED_FILE_MESSAGE,
                cancelText: DECLINE_BUTTON_LABEL,
              },
            },
            {
              type: 'default',
              danger: true,
              label: DELETE_ALL_FILES_BUTTON_LABEL,
              onClick: onDeleteAllFiles,
              popconfirm: {
                title: DELETE_ALL_CONFIRMATION_MESSAGE,
                cancelText: DECLINE_BUTTON_LABEL,
              },
            },
          ]}
          setTotalSelectedItems={(totalFilesSelected) => setTotalSelectedItems(totalFilesSelected)}
        />
        {renderMetadataFilters()}
        {selectedFileDocument && (
          <React.Suspense fallback={<LoadingIndicator />}>
            <DocumentsPreview
              title={PREVIEW_FILE_DRAWER_TITLE}
              contents={selectedFileDocument.contents}
              closeDocumentPreview={closeDocumentPreview}
              source={selectedFileDocument.fileName}
              meta={getFileMetadataById(selectedFileDocument.fileId || '')}
              onUpdateFileMeta={(updateMeta) =>
                updateFileMeta(selectedFileDocument.fileId, updateMeta)
              }
              loading={getFileContentStatus === StatusCodes.IN_PROGRESS}
              isUpdatingFileMeta={updateFileMetaStatus === StatusCodes.IN_PROGRESS}
            />
          </React.Suspense>
        )}
      </div>
    </div>
  );
};

export default FilesPage;
