import { createAsyncThunk } from '@reduxjs/toolkit';
import { Navigator } from '@utils/navigator';
import {
  CREATE_NOTEBOOK,
  GET_NOTEBOOK_SERVER_STATUS,
  RESET_NOTEBOOK_STATUS,
  START_NOTEBOOK_SERVER_STATUS,
  START_POLLING_NOTEBOOK_SERVER_STATUS,
  STOP_POLLING_NOTEBOOK_SERVER_STATUS,
} from '@redux/types/types';
import {
  createNotebookApi,
  getNotebookServerStatusApi,
  startNotebookServerApi,
} from '@src/api/notebook';

const JUPYTER_URL = import.meta.env.VITE_JUPYTER_URL;
let interval: any;

export const resetStatus = {
  type: RESET_NOTEBOOK_STATUS,
};

export const createNotebook = createAsyncThunk(
  CREATE_NOTEBOOK,
  async (pipelineID: string, { rejectWithValue }) => {
    try {
      const { data } = await createNotebookApi(pipelineID);
      window.open(data.link, '_blank');
      Navigator.navigate('/pipelines');
      return data.link;
    } catch (error) {
      window.open(JUPYTER_URL, '_blank');
      return rejectWithValue(error);
    }
  },
);

export const getNotebookServerStatus = createAsyncThunk(
  GET_NOTEBOOK_SERVER_STATUS,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await getNotebookServerStatusApi();
      return data.state;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const startNotebookServer = createAsyncThunk(
  START_NOTEBOOK_SERVER_STATUS,
  async (serverType: string, { rejectWithValue }) => {
    try {
      const data = await startNotebookServerApi(serverType);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const startPollingServerStatus = () => (dispatch: any) => {
  clearInterval(interval);
  interval = setInterval(() => dispatch(getNotebookServerStatus()), 4000);
  return {
    type: START_POLLING_NOTEBOOK_SERVER_STATUS,
  };
};

export const stopPollingServerStatus = () => {
  clearInterval(interval);
  return {
    type: STOP_POLLING_NOTEBOOK_SERVER_STATUS,
  };
};
