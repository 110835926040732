import { DOCS_URL } from '@constants/constant';
import { MessageCodes } from './enum/common';

export const OVERVIEW_TAB_LABEL = 'Overview';
export const LOGS_TAB_LABEL = 'Logs';
export const SETTINGS_TAB_LABEL = 'Settings';
export const ACTIVATE_PIPELINE_BUTTON_LABEL = 'Activate';
export const DOCUMENTATION_LINK = 'documentation';
export const LOGS_LINK = 'logs';
export const SKIPPED_FILES_MESSAGE = `Your pipeline ignored some files and didn't index them. These files will be excluded from search results. `;
export const EMPTY_LOGS_UNDEPLOYED_PIPELINE_LABEL = `There aren’t any logs for this pipeline yet. Once deployed, you will be able to view all logs.`;
export const EMPTY_LOGS_DEPLOYED_PIPELINE_LABEL = `There are no logs for this pipeline. This is either because there was nothing to log or this pipeline was deployed more than two days ago. We keep logs for the last two days or since the last deployment, whichever is latest.`;
export const EMPTY_LOGS_WITH_FILTERS_LABEL = `There aren't any logs for your filters. Change or clear the filters.`;
export const EMPTY_LOGS_WITH_SEARCH_VALUE_LABEL = `There aren't any logs for your search. Change or clear the search.`;
export const CLEAR_ALL_FILTERS_BUTTON_LABEL = 'Clear all filters';
export const TOTAL_LOGS_PERPAGE = 'Showing %totalPerPage%';
export const TOTAL_LOGS = ' of %totalItems%';
export const LOGS_FORMATTED_TAB_LABEL = 'Formatted';
export const LOGS_RAW_TAB_LABEL = 'Raw';
export const COPY_TO_CLIPBOARD_BUTTON_LABEL = 'Copy to clipboard';
export const CAUSED_BY_LABEL = 'Caused by:';
export const COPY_TO_CLIPBOARD_MESSAGE_ALERT = 'Raw text copied to clipboard';
export const LINE_LABEL = 'line';
export const STACK_TRACE_LABEL =
  '. See the stack trace above for more information. Skipping handling indexing requests. pipeline_ Traceback (most recent call last):';
export const SEARCH_LOGS_PLACEHOLDER = 'Search for logs';
export const DOWNLOAD_LOGS_BUTTON_LABEL = 'Download CSV';
export const ENABLE_AUTOREFRESH_BUTTON_LABEL = 'Enable autorefresh';
export const SETTINGS_FEEDBACK_TAGS_TITLE = 'Feedback tags';
export const SETTINGS_FEEDBACK_TAGS_DESCRIPTION =
  'Add feedback tags that you find relevant for the use-case. The tags added here, will be available to be selected when providing feedback on an answer.';
export const SETTINGS_FEEDBACK_TAGS_LABEL = 'Tags';

export enum PipelineDetailsTabsKeys {
  OVERVIEW = 'overview',
  LOGS = 'logs',
  SETTINGS = 'settings',
}

export enum LogTabsKeys {
  FORMATTED = 'formatted',
  RAW = 'raw',
}

export enum LogLevels {
  DEBUG = 'debug',
  INFO = 'info',
  WARNING = 'warning',
  ERROR = 'error',
  CRITICAL = 'critical',
}

export interface IPipelineStatusMessage {
  status: string;
  documentation?: string;
  type: MessageCodes;
  title: string;
  message: string;
}

export const PIPELINE_LOGS_FILTERS_FIELD_KEYS = {
  LEVEL: 'level',
  ORIGIN: 'origin',
  DATE_ADDED: 'logged_at',
};

export const PIPELINE_LOGS_FILTER_LEVEL_LABEL = 'Level';
export const PIPELINE_LOGS_FILTER_ORIGIN_LABEL = 'Origin';
export const PIPELINE_LOGS_FILTER_DATE_LABEL = 'Date added';

export const PIPELINE_LOGS_FILTER_LEVEL_OPTIONS = [
  { key: 'info', label: 'info' },
  { key: 'warning', label: 'warning' },
  { key: 'error', label: 'error' },
];

export const PIPELINE_LOGS_FILTER_ORIGIN_OPTIONS = [
  { key: 'querypipeline', label: 'query' },
  { key: 'indexingpipeline', label: 'indexing' },
];

export const PIPELINE_LOGS_FILTER_DATE_OPTIONS = [
  { key: 'date_15_m', label: 'Last 15m' },
  { key: 'date_30_m', label: 'Last 30m' },
  { key: 'date_1_h', label: 'Last 1h' },
  { key: 'date_2_h', label: 'Last 2h' },
  { key: 'date_4_h', label: 'Last 4h' },
  { key: 'date_1_d', label: 'Last 1d' },
  { key: 'date_2_d', label: 'Last 2d' },
];

export const FETCH_PIPELINE_LOGS_LIMIT = 20;

export const PIPELINE_STATUS_MESSAGES: IPipelineStatusMessage[] = [
  {
    status: 'Failed to deploy',
    documentation: `${DOCS_URL}docs/troubleshooting-pipeline-deployment`,
    type: MessageCodes.ERROR,
    title: 'Pipeline failed to deploy',
    message:
      'There could be multiple reasons that caused the deployment of your pipeline to fail. Check out our %documentation% for step by step troubleshooting.',
  },
  {
    status: 'Indexing failed',
    documentation: `${DOCS_URL}docs/troubleshooting-pipeline-deployment`,
    type: MessageCodes.ERROR,
    title: 'Pipeline failed to index',
    message:
      'There could be multiple reasons that caused the indexing of your pipeline to fail. Check out our %documentation% for step by step troubleshooting.',
  },
  {
    status: 'Partially Indexed',
    documentation: `${DOCS_URL}docs/troubleshooting-pipeline-deployment`,
    type: MessageCodes.WARNING,
    title: 'Not all files were indexed',
    message:
      'There could be multiple reasons that some files failed to index. Check out our %documentation% for step by step troubleshooting.',
  },
  {
    status: 'Unhealthy',
    documentation: `${DOCS_URL}docs/troubleshooting-pipeline-deployment`,
    type: MessageCodes.WARNING,
    title: 'Your pipeline is unhealthy',
    message: `Wait a couple of seconds for this issue to resolve. If a pipeline takes longer than 2 seconds to respond, it becomes unhealthy. It's a temporary state caused by high load or other temporary problems. You don't need to take any action.`,
  },
  {
    status: 'Failed to undeploy',
    documentation: `${DOCS_URL}docs/troubleshooting-pipeline-deployment`,
    type: MessageCodes.ERROR,
    title: `Your pipeline didn't deploy`,
    message: `Wait a couple of seconds and try again. If it doesn't deploy, check our %documentation% to learn how to resolve it.`,
  },
  {
    status: 'Indexed',
    type: MessageCodes.WARNING,
    title: `Your pipeline has issues`,
    message: `Check the %logs% for details and remedy actions.`,
  },
  {
    status: 'Standby',
    type: MessageCodes.INFO,
    title: `This pipeline is on standby`,
    message: `Unused pipelines automatically enter standby mode after %idleTimeout% to save resources. Your data is still there, secure and accessible. %activate% the pipeline to use it.`,
  },
];
