import { MIMETypes } from './enum/common';

export const API_URL = import.meta.env.VITE_API_URL;

export const DOCS_URL = import.meta.env.VITE_DOCS_URL;
export const DOCS_URL_V2 = `${DOCS_URL}v2.0/docs`;
export enum UploadFileType {
  pdf = 'pdf',
  txt = 'txt',
  csv = 'csv',
  docx = 'docx',
  pptx = 'pptx',
  xlsx = 'xlsx',
  xml = 'xml',
  html = 'html',
  md = 'md',
  json = 'json',
}

export const FILE_TYPE_TO_MIME_MAP = {
  pdf: MIMETypes.PDF,
  txt: MIMETypes.TXT,
  csv: MIMETypes.CSV,
  docx: MIMETypes.DOCX,
  pptx: MIMETypes.PPTX,
  xlsx: MIMETypes.XLSX,
  xml: MIMETypes.XML,
  html: MIMETypes.HTML,
  md: MIMETypes.MD,
  json: MIMETypes.JSON,
};

export const baseHttpStaticText = {
  sessionExpMsg: 'Session token has been expired. Log in again.',
  compareErrMsg: 'Could not validate your credentials',
  notAuth: 'Not authenticated',
};
