import { GUIDED_WORKFLOW_TOTAL_STEPS } from './guided-workflow';

/* Workspace statistic */
export const WORKSPACE_STATISTIC_TITLE = 'Workspace Statistics';
export const STATISTIC_FILES_LABEL = 'Files';
export const STATISTIC_FILES_TOOLTIP = 'Number of files for indexing and creating documents.';
export const STATISTIC_DOCS_LABEL = 'Docs';
export const STATISTIC_DOCS_TOOLTIP =
  'Passages of text the search runs on. Multiple documents may come from one file.';
export const STATISTIC_AVERAGE_RESPONSE_TIME_LABEL = 'Average Response Time';
export const STATISTIC_REQUESTS_TODAY_LABEL = 'Searches Today';

/* Number of Requests for the last Month */
export const CHART_TITLE = 'The number of searches last week';

/* Latest Requests */
export const HISTORY_TITLE = 'Latest Searches';
export const TOTAL_HISTORY_TITLE = 'Searches in total';
export const DEFAULT_HISTORY_LIMIT = 5;
export const HISTORY_TABLE_COLUMNS = [
  {
    title: 'Query',
    dataIndex: 'query',
    key: 'query',
    width: '25%',
  },
  {
    title: 'Top Answer',
    dataIndex: 'topAnswer',
    key: 'topAnswer',
    width: '25%',
  },
  {
    title: 'Pipeline',
    dataIndex: 'pipeline',
    key: 'pipeline',
    width: '20%',
  },
  {
    title: 'Top Files',
    dataIndex: 'topFiles',
    key: 'topFiles',
    width: '15%',
  },
  {
    title: 'User',
    dataIndex: 'user',
    key: 'user',
    width: '10%',
  },
  {
    title: 'Time',
    dataIndex: 'time',
    key: 'time',
    width: '5%',
  },
];

/* Guided Workflow */
export const GUIDED_WORKFLOW_TITLE = `Create a pipeline in ${GUIDED_WORKFLOW_TOTAL_STEPS} easy steps`;
export const GUIDED_WORKFLOW_SURTITLE = 'Guided Workflow';
export const GUIDED_WORKFLOW_DESCRIPTION =
  'Use the guided workflow to create a production-ready AI-powered pipeline that you can instantly try out and share with others.';
export const GUIDED_WORKFLOW_START_BUTTON_LABEL = 'Create Pipeline';
