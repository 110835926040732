import { MetadataValueType } from '@redux/types/types';

export const CANCEL_BUTTON_LABEL = 'Cancel';
export const CONFIRM_BUTTON_LABEL = 'Yes';
export const DECLINE_BUTTON_LABEL = 'No';
export const DELETE_SELECTED_FILES_SINGULAR_LABEL = 'Delete %total% file';
export const DELETE_SELECTED_FILES_PLURAL_LABEL = 'Delete %total% files';
export const DELETE_SELECTED_EVALSET_SINGULAR_LABEL = 'Delete %total% eval set';
export const DELETE_SELECTED_EVALSET_PLURAL_LABEL = 'Delete %total% eval sets';
export const DELETE_SELECTED_EXPERIMENTS_SINGULAR_LABEL = 'Delete %total% experiment';
export const DELETE_SELECTED_EXPERIMENTS_PLURAL_LABEL = 'Delete %total% experiments';
export const DELETE_SELECTED_PIPELINES_SINGULAR_LABEL = 'Delete %total% pipeline';
export const DELETE_SELECTED_PIPELINES_PLURAL_LABEL = 'Delete %total% pipelines';
export const DELETE_SELECTED_MESSAGE = 'Are you sure you want to delete these?';
export const DELETE_SELECTED_FILE_MESSAGE = 'Delete selected files?';
export const DELETE_FILE_CONFIRMATION_MESSAGE = 'Delete the file?';
export const DELETE_CONFIRMATION_MESSAGE = 'Are you sure you want to delete?';
export const DELETE_ALL_FILES_BUTTON_LABEL = 'Delete all files';
export const DELETE_ALL_CONFIRMATION_MESSAGE = 'Delete all files in your workspace?';
export const EMPTY_FILES_TABLE_MESSAGE =
  'This is where your files are listed. No files here? %uploadFilesButton% some.';
export const EMPTY_EVALSET_TABLE_MESSAGE =
  'This is where your evaluation sets are listed. No data here? %uploadEvalSetsButton% an evaluation set and use it when creating experiments to evaluate your pipeline.';
export const UPLOAD_FILE_BUTTON_LABEL = 'Upload Files';
export const UPLOAD_BUTTON_LABEL = 'Upload';
export const UPLOAD_EVALSET_BUTTON_LABEL = 'Upload Evaluation Sets';
export const UPLOAD_DESCRIPTION_MESSAGE = 'Click or drag files to this area to upload.';
export const PREVIEW_FILE_BUTTON_LABEL = 'Preview';
export const LABELS_WARNING_TOOLTIP =
  "During experiments, deepset Cloud verifies the labels from the evaluation set against the answers the pipeline returned. If the labels don't match the files, there's no way to evaluate a pipeline during experiments.";
export const DELETE_BUTTON_LABEL = 'Delete';
export const DOWNLOAD_BUTTON_LABEL = 'Download';
export const DOWNLOAD_BUTTON_LABEL_EVAL_SET = 'Download JSON';
export const DOWNLOAD_CSV_BUTTON_LABEL = 'Export as CSV';
export const SORTING_SELECT_LABEL = 'Sort by:';
export const SORTING_PLACEHOLDER = 'Select';
export const CLEAR_FILTER_LABLE = 'Clear filter';
export const CLEAR_ALL_FILTER_LABLE = 'Clear all';
export const EDIT_COLUMNS_TOOLTIP = 'Customize the columns';
export const PREVIEW_FILE_DRAWER_TITLE = 'File';
export const TOTAL_ITEMS_TABLE_PERPAGE = 'Showing %totalPerPage%';
export const TOTAL_ITEMS_TABLE = ' of %totalItems%';
export const METADATA_FILTERS_LABEL = 'Metadata filters';
export const METADATA_FILTERS_CLEAR_ALL_LABEL = 'Clear all';
export const METADATA_FILTERS_APPLY_LABEL = 'Apply';
export const FILE_DELETE_SUCCESS_MESSAGE = 'Files deleted.';
export const FILE_SIZE_LIMIT_ERROR_WITH_DOCUMENTATION = `A single file cannot be larger than %sizeLimit%MB. You can use %documentationLink% to upload larger files.`;
export const FILE_SIZE_LIMIT_ERROR_WITH_DOCUMENTATION_LABEL = `deepset Cloud SDK`;
export const FILE_META_UPDATED_MESSAGE = `Metadata changes saved`;

export const METADATA_TYPE_HEADER_LABEL = 'Type';
export const METADATA_KEY_HEADER_LABEL = 'Key';
export const METADATA_VALUE_HEADER_LABEL = 'Value';
export const ADD_METADATA_BUTTON_LABEL = 'Add metadata';

export const METADATA_EDIT_VALIDATION_ERROR_MESSAGE =
  'One or more fields are empty. You must fill them in to save the changes.';

export const METADATA_VALUE_TYPE_OPTIONS_LABELS = {
  [MetadataValueType.KEYWORD]: 'Keyword',
  [MetadataValueType.NUMERICAL]: 'Numerical',
  [MetadataValueType.DATE]: 'Date',
  [MetadataValueType.LIST]: 'List',
};

export const MAX_NUMBER_OF_PAGES_ON_PAGINATION = 5;
export const DEFAULT_PAGE_SIZE = 10;

export const DEFAULT_FILE_SORTING_KEY = 'created_at_desc';

export const FILE_SORTING_DATATABLE_OPTIONS = [
  { key: 'created_at_desc', label: 'Newest to oldest' },
  { key: 'created_at_asc', label: 'Oldest to newest' },
  { key: 'name_asc', label: 'Name (A to Z)' },
  { key: 'name_desc', label: 'Name (Z to A)' },
];

export const FILE_SORTING_PARAMS_BY_KEY = {
  created_at_desc: {
    field: 'created_at',
    order: 'DESC',
  },
  created_at_asc: {
    field: 'created_at',
    order: 'ASC',
  },
  name_asc: {
    field: 'name',
    order: 'ASC',
  },
  name_desc: {
    field: 'name',
    order: 'DESC',
  },
};
