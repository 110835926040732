import React from 'react';
import { theme } from 'antd';
import DcLogoAvatar from '@components/common/dcLogoAvatar/DcLogoAvatar';
import ThreeDots from '@components/common/threeDots/threeDots';
import UserAvatar from '@components/common/userAvatar/userAvatar';
import styles from './conversationalLoadingBubble.module.scss';

const { useToken } = theme;

interface IConversationalLoadingBubbleProps {
  query: string;
  user: { firstName: string; lastName: string };
  styleCustomization?: {
    loadingAnswerContainer?: string;
  };
}

const ConversationalLoadingBubble = ({
  query,
  user,
  styleCustomization,
}: IConversationalLoadingBubbleProps) => {
  const { token } = useToken();
  const { firstName, lastName } = user;

  return (
    <>
      <div className={styles.container}>
        <div className={styles.bubble_content}>
          <UserAvatar
            user={{ given_name: firstName, family_name: lastName, user_id: '' }}
            size="default"
          />
          <span>{query}</span>
        </div>
      </div>
      <div
        className={`${styles.container} ${styles.blueBackground} ${
          styleCustomization?.loadingAnswerContainer || ''
        }`}
        style={{ backgroundColor: token.colorPrimaryBg }}
      >
        <div className={styles.bubble_content}>
          <DcLogoAvatar />
          <ThreeDots />
        </div>
      </div>
    </>
  );
};

export default ConversationalLoadingBubble;
