import { get as _get } from 'lodash';
import { HTTPStatusCode } from '@constants/enum/common';

export const normalizeErrorMessage = (error: any) => {
  return _get(error, 'response.data.errors[0]', 'Unexpected error occured.');
};

export const getStatusCode = (error: any) => {
  return _get(error, 'response.status', HTTPStatusCode.INTERNAL_SERVER_ERROR);
};
