import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Alert, Button, Tabs } from 'antd';
import { isNil } from 'lodash';
import { getPipelineIdleTimeoutLabel, pipelineInProgress } from '@utils/pipelines';
import { getParsedFeedbackSearchResultToSearchResult } from '@utils/search';
import { interpolateString } from '@utils/string';
import usePipelineFeedback from '@hooks/usePipelineFeedback';
import { FilterType, SelectedFilterItem, SelectedFilters } from '@constants/data-table';
import { NUMBER_OF_LATEST_QUERIES_PER_PAGE } from '@constants/details-header';
import { StatusCodes } from '@constants/enum/common';
import {
  ACTIVATE_PIPELINE_BUTTON_LABEL,
  DOCUMENTATION_LINK,
  LOGS_LINK,
  LOGS_TAB_LABEL,
  OVERVIEW_TAB_LABEL,
  PIPELINE_STATUS_MESSAGES,
  PipelineDetailsTabsKeys,
  SETTINGS_TAB_LABEL,
  SKIPPED_FILES_MESSAGE,
} from '@constants/pipeline-details';
import {
  DEPLOYMENT_FAILED_LABEL,
  DEPLOYMENT_IN_PROGRESS_LABEL,
  IDLE_STATUS_LABEL,
  IndexingStatusMessages,
  PipelineStatusCodes,
  RENAME_PIPELINE_MODAL_OK_BUTTON,
  RENAME_PIPELINE_MODAL_TITLE,
  UNDEPLOYMENT_FAILED_LABEL,
  UNDEPLOYMENT_IN_PROGRESS_LABEL,
} from '@constants/pipelines';
import {
  activatePipeline,
  checkPipelinelogsHealth,
  deletePipeline,
  deployPipeline,
  duplicatePipelineV1,
  duplicatePipelineV2,
  exportPipelineLogsCSV,
  fetchPipeline,
  fetchPipelineIndexing,
  fetchPipelineYaml,
  getPipelineLatestQueries,
  getPipelinelogs,
  getPipelineStatistics,
  getRuntimeIssueDetection,
  resetActiveTabPipelineDetailsPage,
  resetFetchPipelineYamlStatus,
  resetMessage,
  resetPipelineDetailsState,
  resetPipelineErrors,
  resetRenamePipelineNameStatus,
  setActiveTabPipelineDetailsPage,
  startPollingPipelineIndexing,
  startPollingPipelineLogs,
  startPollingPipelineStatus,
  stopPollingPipelineIndexing,
  stopPollingPipelineLogs,
  stopPollingPipelineStatus,
  undeployPipeline,
  updatePipeline,
  validatePipelineYaml,
} from '@redux/actions/pipelineActions';
import {
  activeTabPipelineDetailsPageSelector,
  fetchPipelineYamlStatusSelector,
  loadingLatestQueriesStatusSelector,
  pipelineErrorsSelector,
  pipelineIndexingDetailsSelector,
  pipelineLatestQueriesSelector,
  pipelineLogsSelector,
  pipelineLogsStatusSelector,
  pipelineLogsUnhealthySelector,
  pipelineMessageSelector,
  pipelineSelector,
  pipelineStatisticsAnswersSelector,
  pipelineStatisticsSelector,
  renamePipelineNameStatusSelector,
} from '@redux/selectors/pipelineSelectors';
import {
  DeepsetCloudVersion,
  IAPIPaginationData,
  IFeedbackSearchResult,
  IMessage,
  IPipeline,
  IPipelineIndexingData,
  IPipelineLog,
  IPipelineLogsParams,
  IPipelineStatistics,
  IPipelineStatisticsAnswers,
  IValidatePipelineParams,
  PipelineLatestQueries as PipelineLatestQueriesType,
} from '@redux/types/types';
import DetailedQueryView from '@components/detailedQueryView/DetailedQueryView';
import MultipleErrorsPopup from '@components/multipleErrosPopup/MultipleErrorsPopup';
import NamePipelineModal from '@components/namePipelineModal/NamePipelineModal';
import PipelineDeepsetCloudVersionTag from '@components/pipelines/pipelineDeepsetCloudVersionTag/PipelineDeepsetCloudVersionTag';
import PipelineDetailsHeader from '@components/pipelines/pipelineDetailsHeader/PipelineDetailsHeader';
import PipelineFeedbackTable from '@components/pipelines/pipelineFeedbackTable/PipelineFeedbackTable';
import PipelineLatestQueries from '@components/pipelines/pipelineLatestQueries/PipelineLatestQueries';
import PipelineLogs from '@components/pipelines/pipelineLogs/PipelineLogs';
import PipelineServiceLevelBadge from '@components/pipelines/pipelineServiceLevelBadge/PipelineServiceLevelBadge';
import PipelineServiceLevelSwitchSection from '@components/pipelines/pipelineServiceLevelSwitchSection/PipelineServiceLevelSwitchSection';
import PipelineSettings from '@components/pipelines/pipelineSettings/PipelineSettings';
import PipelineStatistics from '@components/pipelines/pipelineStatistics/PipelineStatistics';
import PipelineStatisticsAnswers from '@components/pipelines/pipelineStatisticsAnswers/PipelineStatisticsAnswers';
import ShareModal from '@components/shareModal/ShareModal';
import styles from './pipelineDetailsPage.module.scss';

const PipelineDetailsLogsPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pipeline: IPipeline = useSelector(pipelineSelector);
  const message: IMessage = useSelector(pipelineMessageSelector);
  const pipelineIndexingData: IPipelineIndexingData = useSelector(pipelineIndexingDetailsSelector);
  const pipelineStatistics: IPipelineStatistics = useSelector(pipelineStatisticsSelector);
  const pipelineLatestQueries: PipelineLatestQueriesType = useSelector(
    pipelineLatestQueriesSelector,
  );
  const loadingLatestQueriesStatus: StatusCodes = useSelector(loadingLatestQueriesStatusSelector);
  const pipelineStatisticsAnswers: IPipelineStatisticsAnswers = useSelector(
    pipelineStatisticsAnswersSelector,
  );
  const pipelineLogs: IAPIPaginationData<IPipelineLog[]> = useSelector(pipelineLogsSelector);
  const pipelineLogsUnhealthy: Record<string, boolean> = useSelector(pipelineLogsUnhealthySelector);
  const pipelineLogsStatus: StatusCodes = useSelector(pipelineLogsStatusSelector);
  const loadingPipelineLogs = pipelineLogsStatus === StatusCodes.IN_PROGRESS;
  const renamePipelineNameStatus: StatusCodes = useSelector(renamePipelineNameStatusSelector);
  const fetchPipelineYamlStatus: StatusCodes = useSelector(fetchPipelineYamlStatusSelector);
  const activeTabPipelineDetailsPage = useSelector(activeTabPipelineDetailsPageSelector);
  const pipelineErrors = useSelector(pipelineErrorsSelector);

  const {
    getPipelineFeedback,
    getFeedbackTags,
    resetFeedbackTags,
    resetFeedbackData,
    resetProvidedFeedbackStatus,
    pipelineFeedback,
    selectedFeedbackSortValue,
    pipelineFeedbackTagsByPipelineId,
  } = usePipelineFeedback();

  const [currentActiveSearchResult, setCurrentActiveSearchResult] =
    useState<IFeedbackSearchResult | null>();
  const [currentActiveFeedbackIndex, setCurrentActiveFeedbackIndex] = useState<number>(0);
  const [showRenamePipelineModal, setShowRenamePipelineModal] = useState(false);
  const [activeTab, setActiveTab] = useState<string>(activeTabPipelineDetailsPage);
  const [pipelineToShare, setPipelineToShare] = useState(false);
  const [pipelineName, setPipelineName] = useState<string>(
    (useParams() as { pipelineName: string }).pipelineName,
  );
  const [filterDateLastDeployedAt, setFilterDateLastDeployedAt] = useState<SelectedFilterItem>({
    key: 'date',
    label: 'Date',
    value: pipeline.last_deployed_at,
    type: FilterType.DATE,
  });
  const [feedbackBookmarkFilter, setFeedbackBookmarkFilter] = useState<SelectedFilterItem>({
    key: 'bookmarked',
    label: 'Save for later',
    value: false,
    type: FilterType.TOGGLE,
  });
  const feedbackDataTableParams = useRef<{
    currentPage: number;
    pageSize: number;
    searchValue: string;
    sortValue?: string;
    filterValues?: SelectedFilters;
  } | null>(null);

  const pipelineIdleTimeout = getPipelineIdleTimeoutLabel(pipeline.idle_timeout_in_seconds);

  const {
    indexing,
    status: queryStatus,
    last_deployed_at: lastDeployAt,
    service_level: serviceLevel,
  } = pipeline;
  const {
    pending_file_count: pipelinePendingFileCount,
    failed_file_count: pipelineFailedFileCount,
  } = indexing;

  const getLatestQueries = (limit?: number) => {
    const params = {
      name: pipelineName,
      limit: limit || NUMBER_OF_LATEST_QUERIES_PER_PAGE,
    };
    dispatch(getPipelineLatestQueries(params));
  };

  useEffect(() => {
    resetFeedbackData();
    resetFeedbackTags();
    resetProvidedFeedbackStatus();

    return () => {
      dispatch(resetPipelineDetailsState);
      dispatch(resetActiveTabPipelineDetailsPage);
      dispatch(resetPipelineErrors);
      dispatch(resetFetchPipelineYamlStatus);
    };
  }, []);

  useEffect(() => {
    if (!pipeline?.pipeline_id) return;

    getFeedbackTags({ pipelineId: pipeline.pipeline_id });
  }, [pipeline.pipeline_id]);

  const getPipelineIssues = async () => {
    await dispatch(resetPipelineErrors);
    dispatch(getRuntimeIssueDetection(pipelineName));

    const payload: IValidatePipelineParams = {
      deepset_cloud_version: pipeline.deepset_cloud_version,
    };
    if (pipeline.deepset_cloud_version === DeepsetCloudVersion.V1) {
      payload.config = pipeline.yaml || '';
    } else if (pipeline.deepset_cloud_version === DeepsetCloudVersion.V2) {
      payload.indexing_yaml = pipeline.indexing_yaml || '';
      payload.query_yaml = pipeline.query_yaml || '';
    }

    dispatch(validatePipelineYaml(payload));
  };

  useEffect(() => {
    if (pipeline.name === pipelineName && fetchPipelineYamlStatus === StatusCodes.SUCCESS)
      getPipelineIssues();
  }, [pipeline.yaml, pipeline.name, fetchPipelineYamlStatus]);

  useEffect(() => {
    if (filterDateLastDeployedAt.value)
      dispatch(
        checkPipelinelogsHealth({
          pipelineName,
          filterValues: {
            logged_at: [{ ...filterDateLastDeployedAt }],
          },
        }),
      );
  }, [queryStatus, filterDateLastDeployedAt]);

  useEffect(() => {
    if (lastDeployAt) setFilterDateLastDeployedAt((prev) => ({ ...prev, value: lastDeployAt }));
  }, [queryStatus, lastDeployAt]);

  useEffect(() => {
    dispatch(getPipelineStatistics(pipelineName));
    getLatestQueries();
    dispatch(fetchPipeline({ pipelineName }));
    dispatch(startPollingPipelineStatus({ pipelineName }));
    dispatch(fetchPipelineYaml(pipelineName));
    dispatch(fetchPipelineIndexing(pipelineName));

    return () => {
      dispatch(stopPollingPipelineStatus());
      dispatch(stopPollingPipelineLogs());
    };
  }, [dispatch, pipelineName]);

  useEffect(() => {
    if (pipelineInProgress(pipeline.status)) dispatch(startPollingPipelineIndexing(pipelineName));
    if (pipeline.status === PipelineStatusCodes.DEPLOYED)
      dispatch(getPipelineStatistics(pipelineName));

    return () => {
      dispatch(stopPollingPipelineIndexing());
    };
  }, [pipeline.status]);

  useEffect(() => {
    if (!currentActiveSearchResult) return;

    const feedbackListLength = pipelineFeedback?.data.length - 1;
    const activeFeedbackIndex = currentActiveFeedbackIndex > 0 ? 0 : feedbackListLength;

    setCurrentActiveFeedbackIndex(activeFeedbackIndex);
    setCurrentActiveSearchResult(pipelineFeedback?.data[activeFeedbackIndex]?.search_history);
  }, [feedbackDataTableParams.current?.currentPage]);

  useEffect(() => {
    if (renamePipelineNameStatus === StatusCodes.SUCCESS) {
      window.history.replaceState({}, '', `${pipeline.name}`);
      setPipelineName(pipeline.name);
    }
  }, [renamePipelineNameStatus]);

  const goToPipelinesPage = () => {
    navigate('/pipelines');
  };

  const goToYamlEditor = () => {
    if (pipeline.deepset_cloud_version === DeepsetCloudVersion.V1)
      navigate(`/pipelines/designer/${pipelineName}`);
    else if (pipeline.deepset_cloud_version === DeepsetCloudVersion.V2)
      navigate(`/pipelines/editor/${pipelineName}`);
  };

  const goToStudio = () => {
    navigate(`/pipelines/${pipelineName}/studio`);
  };

  const goToPromptExplorer = () => {
    navigate(`/prompt-studio/${pipelineName}`);
  };

  // Feedback

  const getFeedbacks = (payload: {
    currentPage: number;
    pageSize: number;
    searchValue: string;
    sortValue?: string;
    filterValues?: SelectedFilters;
  }) => {
    if (!pipeline.pipeline_id) return;

    const externalFilters = {
      bookmarked: [feedbackBookmarkFilter],
    };
    const filterValues = {
      ...externalFilters,
      ...(payload?.filterValues || {}),
    };

    const params = {
      pipelineId: pipeline.pipeline_id,
      ...payload,
      filterValues,
    };
    getPipelineFeedback(params);
    feedbackDataTableParams.current = {
      ...params,
    };
  };

  useEffect(() => {
    if (!pipeline.pipeline_id) return;
    getFeedbacks({
      currentPage: 1,
      pageSize: 10,
      searchValue: '',
    });
  }, [pipeline.pipeline_id]);

  const onDetailedQueryViewClose = () => {
    setCurrentActiveFeedbackIndex(0);
    setCurrentActiveSearchResult(null);
  };

  const onFeedbackTableQueryClick = (idx?: number) => {
    if (isNil(idx)) return;

    setCurrentActiveFeedbackIndex(idx);
    setCurrentActiveSearchResult(pipelineFeedback?.data[idx]?.search_history);
  };

  const onBookmarkFilterClick = (bookmarked: boolean) => {
    const {
      filterValues: currentfilterValues,
      searchValue,
      sortValue,
    } = feedbackDataTableParams.current!;

    const filterValue = {
      ...feedbackBookmarkFilter,
      value: bookmarked,
    };
    const filterValues = {
      ...currentfilterValues,
      bookmarked: [filterValue],
    };
    const params = {
      currentPage: 1,
      pageSize: 10,
      searchValue,
      sortValue,
      filterValues,
    };

    setFeedbackBookmarkFilter(filterValue);
    getFeedbacks(params);

    feedbackDataTableParams.current = {
      ...feedbackDataTableParams.current,
      ...params,
    };
  };

  const getSingleFeedbackData = () => {
    const feedback = pipelineFeedback?.data[currentActiveFeedbackIndex];
    if (!feedback) return null;

    const { score, tags } = feedback;

    return { score, tags };
  };

  const getFeedbackExtraQueryInfo = () => {
    if (!currentActiveSearchResult) return null;

    const {
      rank,
      search: { filters, created_at: createdAt, duration },
    } = currentActiveSearchResult;

    return { rank, filters, createdAt, user: null, duration };
  };

  const onPreviousFeedbackDataPointClick = () => {
    const isFirstItem = currentActiveFeedbackIndex === 0;
    const { currentPage, ...restOfParams } = feedbackDataTableParams.current!;

    if (isFirstItem && currentPage > 1) {
      getFeedbacks({ currentPage: currentPage - 1, ...restOfParams });
      return;
    }

    if (isFirstItem) return;

    const previousFeedbackIdx = currentActiveFeedbackIndex - 1;
    const nextSearchResults = pipelineFeedback?.data[previousFeedbackIdx]?.search_history;

    setCurrentActiveFeedbackIndex(previousFeedbackIdx);
    setCurrentActiveSearchResult(nextSearchResults);
  };

  const onNextFeedbackDataPointClick = () => {
    const isLastItem = currentActiveFeedbackIndex === pipelineFeedback.data.length - 1;
    const { currentPage, ...restOfParams } = feedbackDataTableParams.current!;
    const { has_more: hasMore } = pipelineFeedback;

    if (isLastItem && hasMore) {
      getFeedbacks({ currentPage: currentPage + 1, ...restOfParams });
      return;
    }

    if (isLastItem) return;

    const nextFeedbackIdx = currentActiveFeedbackIndex + 1;
    const nextSearchResults = pipelineFeedback?.data[nextFeedbackIdx]?.search_history;

    setCurrentActiveFeedbackIndex(nextFeedbackIdx);
    setCurrentActiveSearchResult(nextSearchResults);
  };

  const getPipelineStatusLabel = () => {
    const isPipelineIdle = (status: PipelineStatusCodes) => status === PipelineStatusCodes.IDLE;

    if (pipelineInProgress(queryStatus)) {
      if (
        queryStatus === PipelineStatusCodes.DEPLOYMENT_IN_PROGRESS ||
        queryStatus === PipelineStatusCodes.DEPLOYMENT_SCHEDULED
      )
        return DEPLOYMENT_IN_PROGRESS_LABEL;
      return UNDEPLOYMENT_IN_PROGRESS_LABEL;
    }

    if (queryStatus === PipelineStatusCodes.DEPLOYMENT_FAILED) return DEPLOYMENT_FAILED_LABEL;
    if (queryStatus === PipelineStatusCodes.UNDEPLOYMENT_FAILED) return UNDEPLOYMENT_FAILED_LABEL;
    if (queryStatus === PipelineStatusCodes.IDLE) return IDLE_STATUS_LABEL;

    if (queryStatus !== PipelineStatusCodes.DEPLOYED && !isPipelineIdle(queryStatus))
      return IndexingStatusMessages.NOT_INDEXED;

    if (pipelinePendingFileCount > 0) return IndexingStatusMessages.IN_PROGRESS;
    if (pipelinePendingFileCount === 0 && pipelineFailedFileCount > 0)
      return IndexingStatusMessages.PARTIALLY_INDEXED;
    if (pipelinePendingFileCount === 0 && pipelineFailedFileCount === 0)
      return IndexingStatusMessages.INDEXED;

    return '';
  };

  const getTabItems = () => {
    const tabsItems = [
      {
        label: OVERVIEW_TAB_LABEL,
        key: PipelineDetailsTabsKeys.OVERVIEW,
        children: (
          <>
            <PipelineStatistics
              pipelineStatistics={pipelineStatistics}
              pipelineIndexingData={pipelineIndexingData}
              setActiveTabPipelineDetailsPage={(tabName: string) => {
                setActiveTab(tabName);
                dispatch(setActiveTabPipelineDetailsPage(tabName));
              }}
            />
            <PipelineStatisticsAnswers statisticsAnswers={pipelineStatisticsAnswers} />
            {pipelineLatestQueries.data && pipelineLatestQueries.data.length > 0 && (
              <PipelineLatestQueries
                latestQueries={pipelineLatestQueries}
                getLatestQueries={(limit) => getLatestQueries(limit)}
                loading={loadingLatestQueriesStatus === StatusCodes.IN_PROGRESS}
              />
            )}
            <PipelineFeedbackTable
              pipelineId={pipeline.pipeline_id}
              pipelineName={pipeline.name}
              feedback={pipelineFeedback}
              tags={pipelineFeedbackTagsByPipelineId[pipeline.pipeline_id] ?? { data: [] }}
              getFeedbacks={getFeedbacks}
              selectedSortValue={selectedFeedbackSortValue}
              bookmarkFilter={feedbackBookmarkFilter}
              onQueryClick={onFeedbackTableQueryClick}
              onBookmarkFilterClick={onBookmarkFilterClick}
            />
          </>
        ),
      },
      {
        label: LOGS_TAB_LABEL,
        key: PipelineDetailsTabsKeys.LOGS,
        children: PipelineLogs ? (
          <PipelineLogs
            pipelineLogs={pipelineLogs?.data}
            pipelineTotalNumberLogs={pipelineLogs?.total}
            pipelineLogsListHasMore={pipelineLogs?.has_more}
            pipelineName={pipelineName}
            filterDateLastDeployedAt={filterDateLastDeployedAt}
            getPipelineLogs={(payload: IPipelineLogsParams) => dispatch(getPipelinelogs(payload))}
            startPollingPipelineLogs={(payload: IPipelineLogsParams) =>
              dispatch(startPollingPipelineLogs(payload))
            }
            stopPollingPipelineLogs={() => dispatch(stopPollingPipelineLogs())}
            pipelineStatus={pipeline.status}
            downloadLogsCSV={() => dispatch(exportPipelineLogsCSV(pipelineName))}
            loading={loadingPipelineLogs}
            activeTab={activeTab}
          />
        ) : null,
      },
      {
        label: SETTINGS_TAB_LABEL,
        key: PipelineDetailsTabsKeys.SETTINGS,
        children: <PipelineSettings pipelineId={pipeline.pipeline_id} />,
      },
    ];

    return tabsItems;
  };

  const getAlertMessagePipelineStatus = () => {
    const pipelineStatusMessage = PIPELINE_STATUS_MESSAGES.find((statusMessage) => {
      return statusMessage.status === getPipelineStatusLabel();
    });
    let showAlert = false;
    let description = null;

    if (pipelineStatusMessage && pipelineStatusMessage.status === IndexingStatusMessages.IDLE) {
      description = interpolateString(pipelineStatusMessage.message, {
        idleTimeout: pipelineIdleTimeout,
        activate: (
          <Button
            type="link"
            className={styles.pipelineDetails_alertLink}
            onClick={() => dispatch(activatePipeline({ pipelineName: pipeline.name }))}
          >
            {ACTIVATE_PIPELINE_BUTTON_LABEL}
          </Button>
        ),
      });

      return (
        <Alert
          message={pipelineStatusMessage.title}
          description={description}
          type={pipelineStatusMessage.type}
          className={styles.pipelineDetails_pipelineStatusMessage}
        />
      );
    }

    if (pipelineStatusMessage) {
      if (
        pipelineStatusMessage.status === IndexingStatusMessages.INDEXED &&
        pipelineLogsUnhealthy[pipelineName]
      ) {
        description = (
          <>
            {pipelineIndexingData.indexed_no_documents_file_count > 0 && SKIPPED_FILES_MESSAGE}
            {interpolateString(pipelineStatusMessage.message, {
              logs: (
                <Button
                  type="link"
                  className={styles.pipelineDetails_alertLink}
                  onClick={() => {
                    setActiveTab(PipelineDetailsTabsKeys.LOGS);
                    dispatch(setActiveTabPipelineDetailsPage(PipelineDetailsTabsKeys.LOGS));
                  }}
                >
                  {LOGS_LINK}
                </Button>
              ),
            })}
          </>
        );
        showAlert = true;
      }

      if (pipelineStatusMessage.status !== IndexingStatusMessages.INDEXED) {
        description = interpolateString(pipelineStatusMessage.message, {
          documentation: (
            <a href={pipelineStatusMessage.documentation} target="_blank" rel="noreferrer">
              {DOCUMENTATION_LINK}
            </a>
          ),
        });
        showAlert = true;
      }

      if (showAlert)
        return (
          <Alert
            message={pipelineStatusMessage.title}
            description={description}
            type={pipelineStatusMessage.type}
            className={styles.pipelineDetails_pipelineStatusMessage}
          />
        );
    }

    return null;
  };

  return (
    <div className="content-wrapper_padding">
      {pipeline && (
        <div className={styles.pipelineDetails}>
          <PipelineDetailsHeader
            pipeline={pipeline}
            pipelineName={pipelineName!}
            deployPipelineHandler={(name) => dispatch(deployPipeline(name))}
            undeployPipelineHandler={(name) => dispatch(undeployPipeline(name))}
            onSharePipelineHandler={() => setPipelineToShare(true)}
            deletePipelineHandler={(name) => dispatch(deletePipeline(name))}
            onActivatePipelineHandler={(name) => dispatch(activatePipeline({ pipelineName: name }))}
            goToPipelinesPageHandler={goToPipelinesPage}
            fetchPipelineIndexingHandler={(name) => dispatch(fetchPipelineIndexing(name))}
            fetchPipelineHandler={(name) => dispatch(fetchPipeline({ pipelineName: name }))}
            pipelineIndexingData={pipelineIndexingData}
            goToYamlEditor={goToYamlEditor}
            goToStudio={goToStudio}
            duplicatePipeline={(name) => {
              if (pipeline.deepset_cloud_version === DeepsetCloudVersion.V1)
                dispatch(duplicatePipelineV1(name));
              else if (pipeline.deepset_cloud_version === DeepsetCloudVersion.V2)
                dispatch(duplicatePipelineV2(name));
            }}
            onPromptExplorerMenuClick={goToPromptExplorer}
            setShowRenamePipelineModal={(show: boolean) => setShowRenamePipelineModal(show)}
            pipelineStatusString={getPipelineStatusLabel()}
            serviceLevel={<PipelineServiceLevelBadge serviceLevel={serviceLevel} />}
            deepsetCloudVersion={
              <PipelineDeepsetCloudVersionTag
                deepsetCloudVersion={pipeline.deepset_cloud_version}
              />
            }
          />
          {getAlertMessagePipelineStatus()}
          <PipelineServiceLevelSwitchSection />
          <Tabs
            activeKey={activeTab}
            onChange={(activeKey) => {
              setActiveTab(activeKey);
              dispatch(setActiveTabPipelineDetailsPage(activeKey));
            }}
            items={getTabItems()}
            className={styles.pipelineDetails_tabs}
          />
          {pipelineErrors.length > 0 && <MultipleErrorsPopup errors={pipelineErrors} />}
        </div>
      )}
      {pipelineToShare && (
        <ShareModal pipelineName={pipeline.name} onCancel={() => setPipelineToShare(false)} />
      )}
      {currentActiveSearchResult && (
        <DetailedQueryView
          isVisible={!!currentActiveSearchResult}
          pipelineName={pipelineName}
          pipelineId={pipeline.pipeline_id}
          isPreviousItemButtonDisabled={
            currentActiveFeedbackIndex === 0 && feedbackDataTableParams.current?.currentPage === 1
          }
          isNextItemButtonDisabled={
            currentActiveFeedbackIndex === pipelineFeedback.data.length - 1 &&
            !pipelineFeedback.has_more
          }
          onClose={onDetailedQueryViewClose}
          searchResult={getParsedFeedbackSearchResultToSearchResult(currentActiveSearchResult)}
          feedbackData={getSingleFeedbackData()}
          extraQueryInfo={getFeedbackExtraQueryInfo()}
          onPreviousClick={onPreviousFeedbackDataPointClick}
          onNextClick={onNextFeedbackDataPointClick}
        />
      )}
      <NamePipelineModal
        openModal={showRenamePipelineModal}
        closeModal={() => setShowRenamePipelineModal(false)}
        modalTitle={RENAME_PIPELINE_MODAL_TITLE}
        okButtonText={RENAME_PIPELINE_MODAL_OK_BUTTON}
        pipelineName={pipelineName}
        renamePipeline={(payload: { pipelineName: string; newPipelineName: string }) =>
          dispatch(updatePipeline(payload))
        }
        renamePipelineNameStatus={renamePipelineNameStatus}
        resetRenamePipelineNameStatus={() => dispatch(resetRenamePipelineNameStatus)}
        pipelineMessage={message}
        resetMessage={() => dispatch(resetMessage)}
      />
    </div>
  );
};

export default PipelineDetailsLogsPage;
