import { DeepsetCloudVersion, PipelineServiceLevel } from '@redux/types/types';

export const NAME_LABEL = 'Name';
export const DATE_LABEL = 'Created at';
export const STATUS_LABEL = 'Status';
export const CREATED_BY_LABEL = 'Created by';
export const SERVICE_LEVEL_LABEL = 'Service level';
export const ACCURACY_TOOLTIP =
  'Accuracy measures the number of times the search returned at least one answer that got positive feedback.';
export const NOT_APPLICABLE_LABEL = 'N/A';
export const CREATE_PIPELINE_LABEL = 'Create Pipeline';
export const EDIT_PIPELINE_NAME_LABEL = 'Edit pipeline name';
export const CREATE_PIPELINE_MODAL_PIPELINE_NAME_PLACEHOLDER = 'Enter a unique pipeline name';
export const DELETE_BUTTON_LABEL = 'Delete';
export const RENAME_PIPELINE_MODAL_TITLE = 'Rename your pipeline';
export const DEFINE_PIPELINE_MODAL_TITLE = 'Define your pipeline';
export const NAME_NEW_PIPELINE_MODAL_TITLE = 'Name your pipeline';
export const RENAME_PIPELINE_MODAL_OK_BUTTON = 'Done';
export const NAME_NEW_PIPELINE_MODAL_OK_BUTTON = 'Create Pipeline';
export const DELETE_PIPELINE_CONFIRMATION_MESSAGE = 'Delete pipeline?';
export const DELETE_PIPELINES_CONFIRMATION_MESSAGE = 'Delete pipelines?';
export const UNDEPLOY_PIPELINE_CONFIRMATION_MESSAGE = 'Undeploy pipeline?';
export const DECLINE_BUTTON_LABEL = 'No';
export const DEPLOY_BUTTON_LABEL = 'Deploy';
export const CANCEL_BUTTON_LABEL = 'Cancel';
export const UNDEPLOYING_BUTTON_LABEL = 'Undeploying';
export const DUPLICATE_BUTTON_LABEL = 'Duplicate';
export const PROMPT_EXPLORER_BUTTON_LABEL = 'Prompt studio';
export const SHARE_BUTTON_LABEL = 'Share';
export const TRY_AGAIN_BUTTON_LABEL = 'Try again';
export const DETAILS_BUTTON_LABEL = 'Details';
export const OBSERVABILITY_BUTTON_LABEL = 'Groundedness observability';
export const EDIT_BUTTON_LABEL = 'Edit';
export const RENAME_BUTTON_LABEL = 'Rename';
export const UNDEPLOY_BUTTON_LABEL = 'Undeploy';
export const ACTIVATE_PIPELINE_BUTTON_LABEL = 'Activate';
export const EMPTY_PIPELINES_ALL_TABLE_LABEL =
  'This is a list of all the pipelines you created in this workspace.';
export const EMPTY_PIPELINES_PRODUCTION_TABLE_LABEL =
  'This page shows all pipelines at the production service level in this workspace.';
export const EMPTY_PIPELINES_DEVELOPMENT_TABLE_LABEL =
  'This page shows all pipelines at the development service level in this workspace.';
export const EMPTY_PIPELINES_DRAFT_TABLE_LABEL =
  'You can see all your draft, undeployed pipelines here.';
export const DEPLOYMENT_FAILED_LABEL = 'Failed to deploy';
export const DEPLOYMENT_IN_PROGRESS_LABEL = 'Deploying';
export const UNDEPLOYMENT_IN_PROGRESS_LABEL = 'Undeploying';
export const UNDEPLOYMENT_FAILED_LABEL = 'Failed to undeploy';
export const PARTIALLY_INDEXED_LABEL = 'Partially Indexed';
export const IDLE_STATUS_LABEL = 'Standby';
export const ACTIVATING_PIPELINE_LABEL_STATUS = 'Activating';
export const EXPORT_PIPELINE_FEEDBACK_CSV_BUTTON_LABEL = 'Export feedback';
export const INDEXING_STATUS_HEADER = 'File Indexing Status';
export const SHARE_PIPELINE_MODAL_TITLE = 'Share as prototype';
export const SHARE_PIPELINE_MODAL_BANNER =
  'Share your pipeline as a prototype to let users test it without the ability to make changes.';
export const SHARE_PIPELINE_MODAL_ACTIVE_BANNER = 'Anyone with the link can view.';
export const SHARE_PIPELINE_MODAL_EXPIRED_BANNER =
  'This link has expired and is no longer accessible. To share this prototype again you need to generate another link.';
export const SHARE_PIPELINE_MODAL_EXPIRATION_DATE_LABEL = 'Link Expires';
export const SHARE_PIPELINE_MODAL_EXPIRATION_DATE_LABEL_TOOLTIP =
  'After the link expires, the prototype can no longer be accessed.';
export const SHARE_PIPELINE_MODAL_ADD_DESCRIPTION_LABEL = 'Add description';
export const SHARE_PIPELINE_MODAL_ADD_DESCRIPTION_LABEL_TOOLTIP =
  'This description shows on the prototype page. Add guidelines on how to use the prototype or other useful information here.';
export const SHARE_PIPELINE_MODAL_ENABLE_METADATA_FILTERS_LABEL = 'Enable metadata filters';
export const SHARE_PIPELINE_MODAL_ENABLE_FILES_LABEL = 'Allow users to browse sources';
export const SHARE_PIPELINE_MODAL_LINK_INPUT_PLACEHOLDER = 'Link will appear here once generated';
export const SHARE_PIPELINE_MODAL_COPY_LINK_MESSAGE = 'Link copied';
export const SHARE_PIPELINE_MODAL_DELETE_LINK_MESSAGE = 'Link deleted';
export const SHARE_PIPELINE_MODAL_COPY_LINK_BUTTON_LABEL = 'Copy link';
export const SHARE_PIPELINE_MODAL_COPIED_LINK_BUTTON_LABEL = 'Copied';
export const SHARE_PIPELINE_MODAL_GENERATE_LINK_BUTTON_LABEL = 'Generate link';
export const SHARE_PIPELINE_MODAL_GENERATED_LINK_BUTTON_LABEL = 'Generated';
export const SHARE_PIPELINE_MODAL_GENERATING_LINK_BUTTON_LABEL = 'Generating Link';
export const SHARE_PIPELINE_MODAL_DELETE_LINK_BUTTON_LABEL = 'Delete link';
export const SHARE_PIPELINE_MODAL_DELETING_LINK_BUTTON_LABEL = 'Deleting';
export const SHARE_PIPELINE_MODAL_DELETE_LINK_CONFIRM_LABEL = 'Yes';
export const SHARE_PIPELINE_MODAL_DELETE_LINK_CANCEL_LABEL = 'No';
export const SHARE_PIPELINE_MODAL_DELETE_LINK_CONFIRMATION_MESSAGE =
  'Are you sure you want to delete this link? This will block anyone from viewing this prototype.';
export const SHARE_PIPELINE_MODAL_LOGO_LABEL = 'Add your company logo';
export const SHARE_PIPELINE_MODAL_LOGO_DESCRIPTION =
  'Your logo is shown in the bottom right corner of the page. Supported formats are: SVG, PNG, and JPG. The logo should be at a 1:1 aspect ratio (72px by 72px). The maximum file size is 10MB.';
export const SHARE_PIPELINE_MODAL_BRAND_COLOR_LABEL = 'Brand color';
export const SHARE_PIPELINE_MODAL_BRAND_COLOR_DESCRIPTION =
  "Use your main brand color for the answers' background and to highlight the Search field.";
export const POP_CONTENT_PENDING = 'Pending Tasks';
export const POP_CONTENT_INDEXED = 'Indexed';
export const POP_CONTENT_FAILED = 'Failed';
export const POP_CONTENT_SKIPPED = 'Skipped';
export const POP_CONTENT_TOTAL = 'Total';
export const NAME_YOUR_PIPELINE_TITLE = 'Name your pipeline';
export const CREATE_PIPELINE_TITLE = 'Create Pipeline';
export const PIPELINE_NAME_LABEL = 'Pipeline name';
export const UNIQUE_PIPELINE_NAME_INPUT_PLACEHOLDER = 'Enter a unique pipeline name here';
export const PIPELINE_RENAMED_SUCCESSFULLY_MESSAGE = 'Pipeline renamed';
export const PIPELINE_UPDATED_SUCCESSFULLY_MESSAGE = 'Pipeline updated';
export const CREATE_PIPELINE_VERSION_LABEL = 'Version';
export const DOCS_PIPELINES_LINK = 'docs/about-pipelines';
export const DOCS_PIPELINES_LINK_LABEL = 'pipelines';
export const CREATE_PIPELINE_MORE_INFO = 'To learn more, check %pipelines% in our documentation.';
export const DATE_DRIVEN_METAFIELD_MISSING_MESSAGE_TITLE = 'Date field is missing';
export const OPENAI_KEY_MISSING_MESSAGE_TITLE = 'Missing OpenAI key';
export const CONNECTIONS_REDIRECTION_LINK_LABEL = 'Connections';
export const PIPELINE_PRODUCTION_LABEL = 'Production';
export const PIPELINE_PRODUCTION_TOOLTIP_LABEL =
  'Production pipelines come with a 30 day time-to-standby period and one replica by default, making them more reliable for production scenarios.';
export const PIPELINE_PRODUCTION_SWITCH_TO_PROD_DESCRIPTION = `Switching to the production service level increases the pipeline's time to standby and ensures one replica is always available. To learn more, see %documentation%.`;
export const DOCUMENTATION_PROD_DEV_PIPELINES_URL = 'docs/about-pipelines#pipeline-service-levels';
export const PIPELINE_PRODUCTION_DRAFT_TOOLTIP =
  'Deploy your pipeline before changing its service level to production.';
export const PIPELINE_PRODUCTION_SWITCH_ON = 'Switch';
export const PIPELINE_PRODUCTION_SWITCH_OFF = 'Switch off';
export const PIPELINE_PRODUCTION_SWITCH_ON_MODAL_TITLE = 'Switch to production';
export const PIPELINE_PRODUCTION_SWITCH_OFF_MODAL_TITLE = 'Switch off production';
export const PIPELINE_PRODUCTION_SWITCH_ON_MODAL_CONTENT = `You're about to activate the production service level for the pipeline %pipelineName%. This will start tracking the production hours usage. You can reverse this action at any time. Switch to production?`;
export const PIPELINE_PRODUCTION_SWITCH_OFF_MODAL_CONTENT = `You're changing the service level of the pipeline %pipelineName% to “development”. Note that development pipelines are designed for testing and evaluating, not business critical workflows. Switch off production?`;
export const DELETE_PIPELINE_DEV_MODAL_MESSAGE = `Deleting this pipeline is irreversible. Delete %pipelineName%?`;
export const DELETE_PIPELINE_PROD_MODAL_MESSAGE = `You're about to delete a production service level pipeline. Deleting this pipeline is irreversible.`;
export const DELETE_PIPELINE_PROD_MODAL_MESSAGE_CONFIRMATION = `Type “%pipelineName%” into the field to confirm.`;
export const DELETE_PIPELINE_MULTIPLE_MODAL_MESSAGE = `You're about to delete %pipelineCount% pipelines %pipelineList% Deleting these pipelines is irreversible.`;
export const DELETE_PIPELINE_MULTIPLE_MODAL_MESSAGE_CONFIRMATION = `To delete production service level “%pipelineName%” pipeline, type into the field to confirm.`;
export const UNDEPLOY_PIPELINE_DEV_MODAL_MESSAGE = `Undeploying %pipelineName%? will change its service level to draft.`;
export const UNDEPLOY_PIPELINE_PROD_MODAL_MESSAGE = `Undeploying a production pipeline will change its service level to draft.`;
export const UNDEPLOY_PIPELINE_PROD_MODAL_MESSAGE_CONFIRMATION = `Type “%pipelineName%” into the field to confirm.`;
export const STUDIO_LABEL = 'Studio';

export const PAGE_SIZE_PIPELINES_LIST = 10;
export const DEFAULT_PAGE_NUMBER_PIPELINES_LIST = 1;
export const PIPELINES_LIST_DEFAULT_SORTING_KEY_ALL_TAB = 'default';
export const PIPELINES_LIST_DEFAULT_SORTING_KEY = 'created_at_desc';

export enum PipelineMessages {
  UNDEPLOY_START_SUCCESS = 'Your pipeline is being undeployed.',
  DEPLOY_START_SUCCESS = `Your pipeline is being deployed. Once it's deployed, it will start indexing your files.`,
  DEPLOY_SUCCESS = 'Pipeline deployed successfully.',
  UNDEPLOY_SUCCESS = 'Pipeline undeployed successfully.',
  DELETE_SUCCESS = 'Pipeline deleted successfully.',
  MULTIPLE_DELETE_SUCCESS = 'Pipelines deleted successfully.',
  SAVE_SUCCESS = 'Pipeline saved successfully.',
  MISSIGN_PROPERTIES_ERROR = 'Missing properties in the request.',
}

export enum PipelineStatusCodes {
  UNDEPLOYED = 'UNDEPLOYED',
  DEPLOYED = 'DEPLOYED',
  IDLE = 'IDLE',
  DEPLOYMENT_IN_PROGRESS = 'DEPLOYMENT_IN_PROGRESS',
  UNDEPLOYMENT_IN_PROGRESS = 'UNDEPLOYMENT_IN_PROGRESS',
  DEPLOYMENT_SCHEDULED = 'DEPLOYMENT_SCHEDULED',
  UNDEPLOYMENT_SCHEDULED = 'UNDEPLOYMENT_SCHEDULED',
  UNDEPLOYMENT_FAILED = 'UNDEPLOYMENT_FAILED',
  DEPLOYMENT_FAILED = 'DEPLOYMENT_FAILED',
  IN_PROGRESS = 'IN_PROGRESS', // This is not normally in pipeline status, but it is used in the indexing status map in backend
  ACTIVATING = 'ACTIVATING',
}

export enum PipelineStatusCodeNumbers {
  UNDEPLOYED = 1,
  DEPLOYED_UNHEALTHY = 2,
  DEPLOYED = 3,
  DEPLOYMENT_IN_PROGRESS = 4,
  UNDEPLOYMENT_IN_PROGRESS = 5,
  DEPLOYMENT_SCHEDULED = 6,
  UNDEPLOYMENT_SCHEDULED = 7,
  UNDEPLOYMENT_FAILED = 8,
  DEPLOYMENT_FAILED = 9,
  IDLE = 10,
  ACTIVATING = 11,
}

export enum PipelineDesiredStatusCodes {
  DEPLOYED = 'DEPLOYED',
  UNDEPLOYED = 'UNDEPLOYED',
}

export enum IndexingStatusMessages {
  INDEXED = 'Indexed',
  IDLE = 'Standby',
  NOT_INDEXED = 'Not Indexed',
  IN_PROGRESS = 'Indexing',
  PARTIALLY_INDEXED = 'Partially Indexed',
  FAILED_INDEXING = 'Failed to index',
}

export enum PipelineNodeTypes {
  DOCUMENT_NODE = 'document_node',
  ANSWER_NODE = 'answer_node',
  GENERATIVE_NODE = 'generative_node',
}

export enum PipelineType {
  DOCUMENT_RETRIEVAL = 'documentRetrieval',
  EXTRACTIVE_QUESTION_ANSWERING = 'extractive',
  GENERATIVE_QUESTION_ANSWERING = 'generative',
}

export enum PipelineOutputType {
  DOCUMENT = 'DOCUMENT',
  EXTRACTIVE = 'EXTRACTIVE',
  GENERATIVE = 'GENERATIVE',
  CHAT = 'CHAT',
}

export enum FeedbackSearchResultType {
  DOCUMENT = 'document',
  EXTRACTIVE_QUESTION_ANSWERING = 'extractive',
  GENERATIVE_QUESTION_ANSWERING = 'generative',
}

export enum PipelineValidationYamlErrors {
  PIPELINE_RECENTNESS_RANKER_MISSING_METADATA_ERROR = 'PIPELINE_RECENTNESS_RANKER_MISSING_METADATA_ERROR',
  PIPELINE_MISSING_OPENAI_TOKEN_ERROR = 'PIPELINE_MISSING_OPENAI_TOKEN_ERROR',
  PIPELINE_CONFIG_ERROR = 'PIPELINE_CONFIG_ERROR',
}

export enum PipelineRuntimeIssues {
  INVALID_OPENAI_TOKEN_ERROR = 'Missing OpenAI key',
}

export enum PipelineServiceLevelTooltipLabels {
  PRODUCTION = 'Production pipeline',
  DEVELOPMENT = 'Development pipeline',
  DRAFT = 'Draft pipeline',
}

export const DEEPSET_CLOUD_VERSION_TAG_LABELS = {
  [DeepsetCloudVersion.V1]: 'v1.0',
  [DeepsetCloudVersion.V2]: 'v2.0',
};

export const DEEPSET_CLOUD_VERSION_RADIO_OPTIONS = [
  {
    value: DeepsetCloudVersion.V1,
    title: 'deepset Cloud 1.0',
    description:
      'Linear pipelines with data flowing directly from one node to the next and one YAML configuration for both indexing and query pipelines.',
  },
  {
    value: DeepsetCloudVersion.V2,
    title: 'deepset Cloud 2.0',
    tagLabel: 'Beta',
    description:
      'Flexible pipelines that allow loops and simultaneous flows with separate YAML configurations for indexing and query pipelines.',
  },
];

export enum MenuActionsPipelinesList {
  Details = 'DETAILS',
  Observability = 'OBSERVABILITY',
  Edit = 'EDIT',
  Studio = 'STUDIO',
  Delete = 'DELETE',
  Duplicate = 'DUPLICATE',
  ExportFeedbackCSV = 'EXPORT_FEEDBACK_CSV',
  Undeploy = 'UNDEPLOY',
  PromptExplorer = 'PROMPT_EXPLORER',
  RenamePipeline = 'RENAME_PIPELINE',
}

export const TABS_PIPELINES_LIST_KEYS = {
  ALL: 'ALL',
  PRODUCTION: 'PRODUCTION',
  DEVELOPMENT: 'DEVELOPMENT',
  DRAFT: 'DRAFT',
};

export const TABS_OPTIONS_PIPELINE_LIST = [
  { key: TABS_PIPELINES_LIST_KEYS.ALL, label: 'All' },
  { key: TABS_PIPELINES_LIST_KEYS.PRODUCTION, label: 'Production' },
  { key: TABS_PIPELINES_LIST_KEYS.DEVELOPMENT, label: 'Development' },
  { key: TABS_PIPELINES_LIST_KEYS.DRAFT, label: 'Drafts' },
];

export const PIPELINE_SERVICE_LEVELS_LABELS = {
  [PipelineServiceLevel.PRODUCTION]: 'Prod',
  [PipelineServiceLevel.DEVELOPMENT]: 'Dev',
  [PipelineServiceLevel.DRAFT]: 'Draft',
};

export const PIPELINES_LIST_SORTING_DATATABLE_OPTIONS = [
  { key: 'created_at_desc', label: 'Newest to oldest' },
  { key: 'created_at_asc', label: 'Oldest to newest' },
  { key: 'created_by_asc', label: 'Created by ascending' },
  { key: 'created_by_desc', label: 'Created by descending' },
];

export const PIPELINES_LIST_SORTING_PARAMS_BY_KEY = {
  default: {
    field: '',
    order: '',
  },
  created_at_desc: {
    field: 'created_at',
    order: 'DESC',
  },
  created_at_asc: {
    field: 'created_at',
    order: 'ASC',
  },
  created_by_asc: {
    field: 'created_by/given_name',
    order: 'ASC',
  },
  created_by_desc: {
    field: 'created_by/given_name',
    order: 'DESC',
  },
};

// Errors
export const POPOVER_ISSUES_LABEL = 'Issues';

export const VALIDATION_ERRORS = {
  PIPELINE_NAME_ALREADY_USED: 'This pipeline name already exists. Please use a different name ',
  EMPTY_PIPELINE_NAME: 'You must add a pipeline name to create the pipeline',
  DATE_DRIVEN_FIELD_MISSING:
    'For this template to work, your files must have a metadata field with temporal data (like date, time or year). Enter the name of this field in the %dateMetaField% parameter of the RecentnessRanker node.',
  OPENAI_KEY_MISSING:
    'Paste your OpenAI API key in %connections% to use OpenAI models in your pipelines.',
};

export const RUNTIME_ISSUES_DETECTION = {
  INVALID_OPENAI_TOKEN_ERROR: 'Go to %connections% and provide a valid OpenAI API key.',
};

export enum CreateNewPipelineOptionsKeysType {
  EMPTY_YAML = 'EmptyYaml',
  PIPELINE_TEMPLATE = 'pipelineTemplate',
}

export const CREATE_NEW_PIPELINE_OPTIONS = [
  {
    label: 'From empty file',
    key: CreateNewPipelineOptionsKeysType.EMPTY_YAML,
  },
  {
    label: 'Select a template',
    key: CreateNewPipelineOptionsKeysType.PIPELINE_TEMPLATE,
  },
];
