export const ERROR_RESULT_TITLE = 'The server did not start.';
export const ERROR_RESULT_SUBTITLE = 'Try again or contact customer support.';
export const INFO_RESULT_TITLE = `We're connecting all the technologies together to prepare the server for you.`;
export const INFO_RESULT_SUBTITLE = 'This may take a couple of minutes.';
export const SUCCESS_RESULT_TITLE = `Your server's ready.`;
export const SUCCESS_RESULT_SUBTITLE =
  'Note that JupyterLab opens in a new tab outside of deepset Cloud.';
export const TRY_AGAIN_BUTTON_LABEL = 'Try Again';
export const OPEN_NOTEBOOK_BUTTON_LABEL = 'Create a Notebook';
export const OPEN_NOTEBOOK_SERVER_BUTTON_LABEL = 'Go to JupyterLab';
export const START_SERVER_BUTTON_LABEL = 'Start Server';
export const CPU_SERVER_TITLE = 'CPU Server';
export const GPU_SERVER_TITLE = 'GPU Server';
export const CPU_SERVER_DESCRIPTION = 'Spawns a notebook server';
export const GPU_SERVER_DESCRIPTION =
  'Choose this server if you need more speed to run your pipelines.';

export enum ServerType {
  GPU = 'gpu-server',
  CPU = 'cpu-server',
  UNKNOWN = 'unknown',
}
