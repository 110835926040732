import { http } from './base-http';

/* Create a new notebook */
export function createNotebookApi(pipelineID: string) {
  const paramsMap = {
    ...(pipelineID && { pipeline_id: pipelineID }),
  };
  return http.post(`/api/v1/notebook`, null, { params: paramsMap });
}

export function getNotebookServerStatusApi() {
  return http.get('/api/v1/notebook-server');
}

export function startNotebookServerApi(serverType: string) {
  return http.post('/api/v1/notebook-server', {
    server_type: serverType,
  });
}
