import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import * as Sentry from '@sentry/react';
import { message } from 'antd';
import { auth } from '@utils/auth';
import { Navigator } from '@utils/navigator';
import { useCurrentRoute } from '@hooks/useCurrentRoute';
import { UserRoles } from '@constants/enum/common';
import { getWorkspaces } from '@redux/actions/organizationActions';
import { fetchUserInfo } from '@redux/actions/userActions';
import { userSelector } from '@redux/selectors/userSelectors';
import { IUserData, SharedAssetType } from '@redux/types/types';
import LayoutWrapper from '@components/common/Layout/LayoutWrapper';
import LoadingIndicator from '@components/common/LoadingIndicator/LoadingIndicator';
import PrivateRoute from '@components/common/PrivateRoute';
import ProtectedRoute from '@components/common/ProtectedRoute';
import NotificationCenter from '@components/notificationCenter/notificationCenter';
import ReleaseNotes from '@components/releaseNotes/ReleaseNotes';
import UploadPreview from '@components/uploadPreview/UploadPreview';
import LoginComponent from '@pages/auth/Login';
import LogoutComponent from '@pages/auth/Logout';
import ChatPage from '@pages/chat/ChatPage';
import DashboardPage from '@pages/dashboard/DashboardPage';
import ErrorPage from '@pages/error/ErrorPage';
import NotFoundPrototypePage from '@pages/error/NotFoundPrototypePage';
import EvalsetPage from '@pages/evalset/EvalsetPage';
import ExperimentDetailsPage from '@pages/experimentDetails/ExperimentDetailsPage';
import ExperimentsPage from '@pages/experiments/ExperimentsPage';
import AwsMarketplacePage from '@pages/external/awsMarketplace/AwsMarketplace';
import FilesPage from '@pages/files/FilesPage';
import LabelingPage from '@pages/labeling/LabelingPage';
import LabelingDetailsPage from '@pages/labelingDetails/LabelingDetailsPage';
import LabelingQueryPage from '@pages/labelingQuery/LabelingQueryPage';
import MeteringPage from '@pages/metering/MeteringPage';
import NotebookStart from '@pages/notebookStart/NotebookStartPage';
import PipelineDesigner from '@pages/pipelineDesigner/PipelineDesigner';
import PipelineDetails from '@pages/pipelineDetails/PipelineDetailsPage';
import PipelineEditor from '@pages/pipelineEditorV2/PipelineEditorV2';
import PipelinesPage from '@pages/pipelines/PipelinesLandingPage';
import PipelineTemplateDetailsPage from '@pages/pipelineTemplateDetails/PipelineTemplateDetails';
import PipelineTemplatesPage from '@pages/pipelineTemplates/PipelineTemplates';
import PromptExplorerPage from '@pages/promptExplorer/PromptExplorer';
import SearchPage from '@pages/search/SearchPage';
import ConnectionsPage from '@pages/settings/connections/ConnectionsPage';
import OrganizationPage from '@pages/settings/OrganizationPage';
import PersonalDetailsPage from '@pages/settings/PersonalDetailsPage';
import WorkspacesPage from '@pages/settings/WorkspacesPage';

const ObservabilityDashboard = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ObservabilityDashboard" */ '@pages/observabilityDashboard/ObservabilityDashboard'
    ),
);

const JobsPage = React.lazy(
  () => import(/* webpackChunkName: "JobsPage" */ '@pages/jobs/JobsPage'),
);

const JobDetailsPage = React.lazy(
  () => import(/* webpackChunkName: "JobDetailsPage" */ '@pages/jobs/JobDetailsPage'),
);

const SharedPrototypePage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "JobDetailsPage" */ '@pages/external/sharedPrototype/SharedPrototype'
    ),
);

const SharedJobReportPage = React.lazy(
  () =>
    import(/* webpackChunkName: "JobDetailsPage" */ '@pages/external/jobReportPage/jobReportPage'),
);

const PipelineStudio = React.lazy(
  () => import(/* webpackChunkName: "PipelineStudio" */ '@pages/pipelineStudio/pipelineStudioPage'),
);

message.config({
  maxCount: 1,
  duration: 5,
});

const AppRoutes: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { path: currentRoutePath } = useCurrentRoute();
  const { user, isAuthenticated, getAccessTokenSilently, logout, loginWithRedirect, isLoading } =
    useAuth0();
  const { role }: IUserData = useSelector(userSelector);

  useEffect(() => {
    auth.setAccessTokenSilently(getAccessTokenSilently);
    auth.setloginWithRedirect(loginWithRedirect);
    auth.setLogout(logout);
  }, [getAccessTokenSilently, loginWithRedirect, logout]);

  useEffect(() => {
    Navigator.setNavigate(navigate);
  }, [navigate]);

  useEffect(() => {
    if (isAuthenticated && role === UserRoles.PIPELINE_STUDIO) navigate('/logout');
  }, [role]);

  useEffect(() => {
    if (isAuthenticated && user) {
      dispatch(fetchUserInfo({ authUser: user }));
      dispatch(getWorkspaces());
    }
  }, [isAuthenticated, user]);

  if (isLoading) return <></>;

  const isEnabledUploadPreview =
    currentRoutePath !== '/guidedWorkflow' && currentRoutePath !== '/jobs';

  return (
    <Sentry.ErrorBoundary fallback={ErrorPage}>
      <NotificationCenter />
      {isAuthenticated && <ReleaseNotes />}
      {isEnabledUploadPreview && <UploadPreview />}
      <Routes>
        <Route path="/login" element={<LoginComponent />} />
        <Route path="/logout" element={<LogoutComponent />} />
        <Route path="/error" element={<ErrorPage />} />
        <Route path="/not-found-prototype" element={<NotFoundPrototypePage />} />
        <Route path="/aws-marketplace" element={<AwsMarketplacePage />} />
        <Route
          path="/shared_prototypes"
          element={<ProtectedRoute Component={SharedPrototypePage} />}
        />
        <Route
          path="/shared_job_results"
          element={<ProtectedRoute Component={SharedJobReportPage} type={SharedAssetType.JOB} />}
        />

        {/* sidebar pages */}
        <Route element={<LayoutWrapper />}>
          <Route
            path="/"
            element={<PrivateRoute component={DashboardPage} roles={[UserRoles.ADMIN]} />}
          />
          <Route
            path="/playground"
            element={
              <PrivateRoute
                component={SearchPage}
                roles={[UserRoles.ADMIN, UserRoles.SEARCH_USER]}
              />
            }
          />
          <Route
            path="/playground/:pipelineName"
            element={
              <PrivateRoute
                component={SearchPage}
                roles={[UserRoles.ADMIN, UserRoles.SEARCH_USER]}
              />
            }
          />
          <Route
            path="/pipelines"
            element={<PrivateRoute component={PipelinesPage} roles={[UserRoles.ADMIN]} />}
          />
          <Route
            path="/experiments"
            element={<PrivateRoute component={ExperimentsPage} roles={[UserRoles.ADMIN]} />}
          />
          <Route
            path="/experiments/:experimentName"
            element={<PrivateRoute component={ExperimentDetailsPage} roles={[UserRoles.ADMIN]} />}
          />

          {/* data pages */}
          <Route
            path="/files"
            element={<PrivateRoute component={FilesPage} roles={[UserRoles.ADMIN]} />}
          />
          <Route
            path="/evalset"
            element={<PrivateRoute component={EvalsetPage} roles={[UserRoles.ADMIN]} />}
          />

          {/* settings pages */}
          <Route
            path="/settings/personal-details"
            element={
              <PrivateRoute
                component={PersonalDetailsPage}
                roles={[UserRoles.ADMIN, UserRoles.SEARCH_USER]}
              />
            }
          />
          <Route
            path="/settings/organization"
            element={<PrivateRoute component={OrganizationPage} roles={[UserRoles.ADMIN]} />}
          />
          <Route
            path="/settings/connections"
            element={<PrivateRoute component={ConnectionsPage} roles={[UserRoles.ADMIN]} />}
          />
          <Route
            path="/settings/workspaces"
            element={<PrivateRoute component={WorkspacesPage} roles={[UserRoles.ADMIN]} />}
          />
          <Route
            path="/settings/usage"
            element={<PrivateRoute component={MeteringPage} roles={[UserRoles.ADMIN]} />}
          />

          {/* prompt explorer pages */}
          <Route
            path="/prompt-studio/:pipelineName"
            element={<PrivateRoute component={PromptExplorerPage} roles={[UserRoles.ADMIN]} />}
          />
          <Route
            path="/prompt-studio"
            element={<PrivateRoute component={PromptExplorerPage} roles={[UserRoles.ADMIN]} />}
          />

          {/* labeling pages */}
          <Route
            path="/labeling"
            element={<PrivateRoute component={LabelingPage} roles={[UserRoles.ADMIN]} />}
          />
          <Route
            path="/labeling/:projectId"
            element={<PrivateRoute component={LabelingDetailsPage} roles={[UserRoles.ADMIN]} />}
          />
          <Route
            path="/labeling/:projectId/query/:pipelineName"
            element={<PrivateRoute component={LabelingQueryPage} roles={[UserRoles.ADMIN]} />}
          />

          {/* chat pages */}
          <Route
            path="/chat/:pipelineName"
            element={
              <PrivateRoute component={ChatPage} roles={[UserRoles.ADMIN, UserRoles.SEARCH_USER]} />
            }
          />

          {/* pipeline pages */}
          <Route
            path="/pipelines/designer/:pipelineName"
            element={<PrivateRoute component={PipelineDesigner} roles={[UserRoles.ADMIN]} />}
          />
          <Route
            path="/pipelines/designer"
            element={<PrivateRoute component={PipelineDesigner} roles={[UserRoles.ADMIN]} />}
          />
          <Route
            path="/pipelines/editor/:pipelineName"
            element={<PrivateRoute component={PipelineEditor} roles={[UserRoles.ADMIN]} />}
          />
          <Route
            path="/pipelines/editor"
            element={<PrivateRoute component={PipelineEditor} roles={[UserRoles.ADMIN]} />}
          />
          <Route
            path="/pipelines/:pipelineName"
            element={<PrivateRoute component={PipelineDetails} roles={[UserRoles.ADMIN]} />}
          />
          <Route
            path="/notebookStart"
            element={<PrivateRoute component={NotebookStart} roles={[UserRoles.ADMIN]} />}
          >
            <Route
              path=":create"
              element={<PrivateRoute component={NotebookStart} roles={[UserRoles.ADMIN]} />}
            >
              <Route
                path=":pipelineID"
                element={<PrivateRoute component={NotebookStart} roles={[UserRoles.ADMIN]} />}
              />
            </Route>
          </Route>
          <Route
            path="/pipelines/templates"
            element={<PrivateRoute component={PipelineTemplatesPage} roles={[UserRoles.ADMIN]} />}
          />
          <Route
            path="/pipelines/templates/:templateName"
            element={
              <PrivateRoute component={PipelineTemplateDetailsPage} roles={[UserRoles.ADMIN]} />
            }
          />

          {/* Studio */}
          <Route
            path="/pipelines/studio"
            element={
              <PrivateRoute
                component={() => (
                  <React.Suspense fallback={<LoadingIndicator />}>
                    <PipelineStudio />
                  </React.Suspense>
                )}
                roles={[UserRoles.ADMIN]}
              />
            }
          />
          <Route
            path="/pipelines/:pipelineName/studio"
            element={
              <PrivateRoute
                component={() => (
                  <React.Suspense fallback={<LoadingIndicator />}>
                    <PipelineStudio />
                  </React.Suspense>
                )}
                roles={[UserRoles.ADMIN]}
              />
            }
          />

          {/* Observability */}
          <Route
            path="/groundedness"
            element={
              <PrivateRoute
                component={() => (
                  <React.Suspense fallback={<LoadingIndicator />}>
                    <ObservabilityDashboard />
                  </React.Suspense>
                )}
                roles={[UserRoles.ADMIN]}
              />
            }
          />
          <Route
            path="/groundedness/:pipelineName"
            element={
              <PrivateRoute
                component={() => (
                  <React.Suspense fallback={<LoadingIndicator />}>
                    <ObservabilityDashboard />
                  </React.Suspense>
                )}
                roles={[UserRoles.ADMIN]}
              />
            }
          />

          {/* Jobs page */}
          <Route
            path="/jobs"
            element={
              <PrivateRoute
                component={() => (
                  <React.Suspense fallback={<LoadingIndicator />}>
                    <JobsPage />
                  </React.Suspense>
                )}
                roles={[UserRoles.ADMIN]}
              />
            }
          />
          <Route
            path="/jobs/:jobId"
            element={
              <PrivateRoute
                component={() => (
                  <React.Suspense fallback={<LoadingIndicator />}>
                    <JobDetailsPage />
                  </React.Suspense>
                )}
                roles={[UserRoles.ADMIN]}
              />
            }
          />
        </Route>
        {/* redirect for 404 pages */}
        <Route path="*" element={<Navigate to="/login" />} />
        <Route path="/dashboard" element={<Navigate to="/" />} />
        <Route path="/search/*" element={<Navigate to="/playground" />} />
        <Route path="/prompt-explorer/*" element={<Navigate to="/prompt-studio" />} />
        <Route path="/observability/*" element={<Navigate to="/groundedness" />} />
        <Route path="/data/files" element={<Navigate to="/files" />} />
        <Route path="/data/evalset" element={<Navigate to="/evalset" />} />
      </Routes>
    </Sentry.ErrorBoundary>
  );
};

export default AppRoutes;
