import React, { ReactNode } from 'react';
import CountUp from 'react-countup';
import { InfoCircleOutlined, WarningFilled } from '@ant-design/icons';
import { Progress, Tag, theme, Tooltip } from 'antd';
import { isNil } from 'lodash';
import { kFormatter } from '@utils/math';
import { NO_DATA_AVAILABLE } from '@constants/common';
import { NO_LIMIT_LABEL } from '@constants/metering';
import styles from './usageCard.module.scss';

const { useToken } = theme;

interface IUsageCardProps {
  label: string;
  metric?: string | number;
  tooltip?: string | ReactNode;
  limit?: {
    current?: number;
    target: number | null;
    targetLabel?: string;
    name: string;
  };
  showLimitInfo?: boolean;
  metricSuffix?: string;
}

const UsageCard = ({
  label,
  metric,
  tooltip,
  limit,
  showLimitInfo = true,
  metricSuffix,
}: IUsageCardProps) => {
  const { token } = useToken();
  const limitExceeded =
    limit && limit.target && limit.current ? limit.current > limit.target : false;

  const getProgressPercent = () => {
    if (!limit?.target || !limit.current) return 0;
    return (limit.current / limit.target) * 100;
  };

  const getTargetText = () => {
    if (!limit?.target) return NO_LIMIT_LABEL;
    if (limit.targetLabel) return limit.targetLabel;
    return kFormatter(limit.target, false);
  };

  const renderMetricValue = () => {
    let result = <>{metric}</>;
    if (isNil(metric))
      return (
        <span>
          -<Tag className={styles.tag}>{NO_DATA_AVAILABLE}</Tag>
        </span>
      );
    if (typeof metric === 'number') result = <CountUp end={metric} separator="," />;

    return (
      <>
        {result}
        {metricSuffix}
      </>
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.header_surtitle}>
          {label}
          {tooltip && (
            <Tooltip title={tooltip}>
              <InfoCircleOutlined />
            </Tooltip>
          )}
        </div>
        <h5>
          {limitExceeded && <WarningFilled className={styles.warningIcon} />}
          {renderMetricValue()}
        </h5>
      </div>
      <div className={styles.body}>
        {limit && showLimitInfo && (
          <>
            <div className={styles.body_labels}>
              <span> {limit.name} </span>
              <span>{getTargetText()}</span>
            </div>
            <Progress
              percent={getProgressPercent()}
              strokeColor={limitExceeded ? token.colorWarning : ''}
              showInfo={false}
              size="small"
            />
          </>
        )}
      </div>
    </div>
  );
};

export default UsageCard;
