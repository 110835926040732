import { createAsyncThunk } from '@reduxjs/toolkit';
import { normalizeErrorMessage } from '@utils/error';
import { downloadBlobFile } from '@utils/file';
import {
  getODataConjunctionFilterFrom,
  getODataFilterFrom,
  getODataSearchFilterFrom,
} from '@utils/odata';
import {
  createFeedbackExternalApi,
  getPipelineFeedbackTagsExternalApi,
  updateFeedbackExternalApi,
} from '@api/external/pipeline-feedback';
import {
  createFeedbackApi,
  createPipelineFeedbackTagApi,
  deletePipelineFeedbackTagApi,
  getFeedbackDataApi,
  getPipelineFeedbackDataCSVApi,
  getPipelineFeedbackTagsApi,
  updateFeedbackApi,
} from '@api/pipeline-feedback';
import { SelectedFilters } from '@constants/data-table';
import { MIMETypes } from '@constants/enum/common';
import {
  FEEDBACK_SENT_SUCCESS_MESSAGE,
  FEEDBACK_TAG_CREATED_SUCCESS_MESSAGE,
  FEEDBACK_TAG_DELETED_SUCCESS_MESSAGE,
  FEEDBACK_UPDATED_SUCCESS_MESSAGE,
  SORTING_PARAMS_BY_KEY,
} from '@constants/pipeline-feedback';
import { initialState } from '@redux/rootReducer';
import {
  CREATE_PIPELINE_FEEDBACK_TAG,
  DELETE_PIPELINE_FEEDBACK_TAG,
  EXPORT_PIPELINE_FEEDBACK_CSV,
  FeedbackType,
  GET_PIPELINE_FEEDBACK,
  GET_PIPELINE_FEEDBACK_TAGS,
  IPipelineFeedbackData,
  NotificationType,
  PROVIDE_SEARCH_RESULT_FEEDBACK,
  RESET_PIPELINE_FEEDBACK_DATA,
  RESET_PIPELINE_FEEDBACK_TAGS,
  RESET_PROVIDED_FEEDBACK_STATUS,
  SELECT_FEEDBACK_SORT_VALUE,
  SET_PROVIDED_FEEDBACK_BY_RESULT_ID,
  UPDATE_SEARCH_RESULT_FEEDBACK,
} from '@redux/types/types';
import { addNotification } from './notificationActions';

export const selectFeedbackSortValue = (value: string) => ({
  type: SELECT_FEEDBACK_SORT_VALUE,
  payload: value,
});

export const resetFeedbackData = {
  type: RESET_PIPELINE_FEEDBACK_DATA,
};

export const resetProvidedFeedbackStatus = {
  type: RESET_PROVIDED_FEEDBACK_STATUS,
};

export const setProvidedFeedbackByResultId = (payload: {
  resultId: string;
  feedback: IPipelineFeedbackData;
}) => ({
  type: SET_PROVIDED_FEEDBACK_BY_RESULT_ID,
  payload,
});

export const providedSearchResultFeedback = createAsyncThunk(
  PROVIDE_SEARCH_RESULT_FEEDBACK,
  async (
    {
      type,
      resultId,
      queryId,
      pipelineId,
      comment,
      tags,
      bookmarked,
      isExternal,
      successMessage,
    }: {
      resultId: string;
      queryId: string;
      pipelineId: string;
      type: FeedbackType | null;
      comment?: string;
      tags?: string[];
      bookmarked?: boolean;
      isExternal?: boolean;
      successMessage?: string;
    },
    { dispatch, rejectWithValue, getState },
  ) => {
    const apiCall = isExternal ? createFeedbackExternalApi : createFeedbackApi;
    let workspaceId: string | undefined;

    if (isExternal) {
      const { tokenData } = (getState() as typeof initialState).sharedPrototypeStore;
      workspaceId = tokenData?.workspaceID;
    }

    const feedbackPayload = {
      score: type,
      comment,
      tags,
      bookmarked,
      result_id: resultId,
      query_id: queryId,
    };
    try {
      const { data } = await apiCall(pipelineId, feedbackPayload, workspaceId);
      dispatch(
        addNotification({
          content: successMessage ?? FEEDBACK_SENT_SUCCESS_MESSAGE,
          type: NotificationType.Success,
        }),
      );
      return data;
    } catch (error) {
      dispatch(
        addNotification({
          content: normalizeErrorMessage(error),
          type: NotificationType.Error,
        }),
      );
      return rejectWithValue(error);
    }
  },
);

export const updateSearchResultFeedback = createAsyncThunk(
  UPDATE_SEARCH_RESULT_FEEDBACK,
  async (
    {
      type,
      feedbackId,
      resultId,
      queryId,
      pipelineId,
      comment,
      tags,
      bookmarked,
      isExternal,
      successMessage,
    }: {
      feedbackId: string;
      resultId: string;
      pipelineId: string;
      queryId: string;
      type?: FeedbackType | null;
      comment?: string;
      tags?: string[];
      bookmarked?: boolean;
      isExternal?: boolean;
      successMessage?: string;
    },
    { dispatch, rejectWithValue, getState },
  ) => {
    const apiCall = isExternal ? updateFeedbackExternalApi : updateFeedbackApi;
    let workspaceId: string | undefined;

    if (isExternal) {
      const { tokenData } = (getState() as typeof initialState).sharedPrototypeStore;
      workspaceId = tokenData?.workspaceID;
    }

    const { providedFeedbackByResultId } = (getState() as typeof initialState)
      .pipelineFeedbackStore;
    const { score: currentSelectedFeedbackType } = providedFeedbackByResultId[resultId];

    const notificationMessage =
      currentSelectedFeedbackType !== type
        ? FEEDBACK_UPDATED_SUCCESS_MESSAGE
        : FEEDBACK_SENT_SUCCESS_MESSAGE;

    const feedbackPayload = {
      score: type,
      comment,
      tags,
      bookmarked,
      result_id: resultId,
      query_id: queryId,
    };
    try {
      const { data } = await apiCall(pipelineId, feedbackId, feedbackPayload, workspaceId);
      dispatch(
        addNotification({
          content: successMessage || notificationMessage,
          type: NotificationType.Success,
        }),
      );
      return data;
    } catch (error) {
      dispatch(
        addNotification({
          content: normalizeErrorMessage(error),
          type: NotificationType.Error,
        }),
      );
      return rejectWithValue(error);
    }
  },
);

export const getPipelineFeedback = createAsyncThunk(
  GET_PIPELINE_FEEDBACK,
  async (
    {
      pipelineId,
      currentPage,
      pageSize,
      searchValue,
      sortValue,
      filterValues,
    }: {
      pipelineId: string;
      currentPage: number;
      pageSize: number;
      searchValue: string;
      sortValue?: string;
      filterValues?: SelectedFilters;
    },
    { rejectWithValue, getState, dispatch },
  ) => {
    const { feedbackSortValue: defaultSortValue } = (getState() as typeof initialState)
      .pipelineFeedbackStore;
    const sortingKey = (sortValue || defaultSortValue) as keyof typeof SORTING_PARAMS_BY_KEY;

    dispatch(selectFeedbackSortValue(sortingKey));

    const { field, order } = SORTING_PARAMS_BY_KEY[sortingKey] || {};

    const searchFilter =
      searchValue && getODataSearchFilterFrom('search_history/search_query', searchValue);
    const filters = filterValues && getODataFilterFrom(filterValues);

    const params = {
      page_number: currentPage,
      limit: pageSize,
      field,
      order,
      filter: getODataConjunctionFilterFrom(searchFilter, filters),
    };
    try {
      const { data } = await getFeedbackDataApi(pipelineId, params);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const exportPipelineFeedbackCSV = createAsyncThunk(
  EXPORT_PIPELINE_FEEDBACK_CSV,
  async (
    { pipelineId, pipelineName }: { pipelineId: string; pipelineName: string },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data } = await getPipelineFeedbackDataCSVApi(pipelineId);
      downloadBlobFile(`${pipelineName}.csv`, data, MIMETypes.CSV);
      return data;
    } catch (error) {
      dispatch(
        addNotification({
          content: normalizeErrorMessage(error),
          type: NotificationType.Error,
        }),
      );
      return rejectWithValue(error);
    }
  },
);

// Tags

export const resetFeedbackTags = {
  type: RESET_PIPELINE_FEEDBACK_TAGS,
};

export const getPipelineFeedbackTags = createAsyncThunk(
  GET_PIPELINE_FEEDBACK_TAGS,
  async (
    { pipelineId, isExternal }: { pipelineId: string; isExternal?: boolean },
    { rejectWithValue, getState },
  ) => {
    const apiCall = isExternal ? getPipelineFeedbackTagsExternalApi : getPipelineFeedbackTagsApi;
    let workspaceId: string | undefined;

    if (isExternal) {
      const { tokenData } = (getState() as typeof initialState).sharedPrototypeStore;
      workspaceId = tokenData?.workspaceID;
    }

    try {
      const { data } = await apiCall(pipelineId, {}, workspaceId);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const createPipelineFeedbackTag = createAsyncThunk(
  CREATE_PIPELINE_FEEDBACK_TAG,
  async (
    { pipelineId, tagName }: { pipelineId: string; tagName: string },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const payload = {
        name: tagName,
      };
      const { data } = await createPipelineFeedbackTagApi(pipelineId, payload);
      dispatch(
        addNotification({
          content: FEEDBACK_TAG_CREATED_SUCCESS_MESSAGE,
          type: NotificationType.Success,
        }),
      );
      return data;
    } catch (error) {
      dispatch(
        addNotification({
          content: normalizeErrorMessage(error),
          type: NotificationType.Error,
        }),
      );
      return rejectWithValue(error);
    }
  },
);

export const deletePipelineFeedbackTag = createAsyncThunk(
  DELETE_PIPELINE_FEEDBACK_TAG,
  async (
    { pipelineId, tagId }: { pipelineId: string; tagId: string },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data } = await deletePipelineFeedbackTagApi(pipelineId, tagId);
      dispatch(
        addNotification({
          content: FEEDBACK_TAG_DELETED_SUCCESS_MESSAGE,
          type: NotificationType.Success,
        }),
      );
      return data;
    } catch (error) {
      dispatch(
        addNotification({
          content: normalizeErrorMessage(error),
          type: NotificationType.Error,
        }),
      );
      return rejectWithValue(error);
    }
  },
);
