import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { SendOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';
import { camelCase, get } from 'lodash';
import { Resizable } from 're-resizable';
import { v4 as uuidv4 } from 'uuid';
import { normalizeErrorMessage } from '@utils/error';
import { docsSearchApi } from '@api/search';
import {
  CHAT_DESCRIPTION,
  CLEAR_BUTTON_LABEL,
  DOCUMENTATION_LINK_LABEL,
  sampleQuestions,
  SEARCH_PLACEHOLDER,
} from '@constants/docs-chat';
import ThreeDots from '@components/common/threeDots/threeDots';
import FormattedResultText from '@components/search/molecules/formattedResultText/FormattedResultText';
import styles from './docsChat.module.scss';

interface IResult {
  question: string;
  answer?: string;
  error?: string;
}

const DocsModel = () => {
  const DOCS_URL = import.meta.env.VITE_DOCS_URL;
  const [searchQuery, setSearchQuery] = React.useState('');
  const [currentQuestion, setCurrentQuestion] = React.useState('');
  const [results, setResults] = React.useState<IResult[]>([]);
  const [searchInProgress, setSearchInProgress] = React.useState(false);
  const [modalHeight, setModalHeight] = React.useState(400);
  const [modalWidth, setModalWidth] = React.useState(400);

  const searchResultsWrapperRef = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const { Search } = Input;

  const pathID = camelCase(location.pathname);
  const questions = (pathID && sampleQuestions[pathID]) || sampleQuestions.common;

  useEffect(() => {
    setTimeout(() => {
      searchResultsWrapperRef.current?.scrollTo({
        top: searchResultsWrapperRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }, 1);
  }, [results, currentQuestion]);

  const submitQuery = async (query: string) => {
    setSearchInProgress(true);
    setSearchQuery('');
    setCurrentQuestion(query);

    try {
      const response = await docsSearchApi(
        'doc_search',
        {
          queries: [query],
        },
        'readme',
      );

      const answer = get(response, 'data.results[0].answers[0].answer', '');
      setResults([
        ...results,
        {
          question: query,
          answer,
        },
      ]);
    } catch (error) {
      const errorMessage = normalizeErrorMessage(error);
      setResults([
        ...results,
        {
          question: query,
          error: errorMessage,
        },
      ]);
    }
    setSearchInProgress(false);
    setCurrentQuestion('');
  };

  const handleModalResize = (height: number, width: number) => {
    setModalHeight(modalHeight + height);
    setModalWidth(modalWidth + width);
  };

  const resetState = () => {
    setSearchInProgress(false);
    setSearchQuery('');
    setCurrentQuestion('');
    setResults([]);
  };

  return (
    <Resizable
      size={{ width: modalWidth, height: modalHeight }}
      minWidth={400}
      minHeight={400}
      maxHeight="90vh"
      maxWidth="100vw"
      grid={[10, 10]}
      snapGap={10}
      lockAspectRatio
      enable={{
        top: false,
        right: false,
        bottom: true,
        left: true,
        topRight: false,
        bottomRight: false,
        bottomLeft: true,
        topLeft: false,
      }}
      onResizeStop={(e, direction, ref, d) => {
        handleModalResize(d.height, d.width);
      }}
    >
      <div className={styles.docsPopoverContent}>
        {results.length === 0 && !currentQuestion && (
          <div>
            {CHAT_DESCRIPTION}
            <ul>
              {questions.map((question) => (
                <li key={uuidv4()}>
                  <Button
                    block
                    className={styles.questionButton}
                    type="link"
                    onClick={() => {
                      setSearchQuery(question);
                      submitQuery(question);
                    }}
                    disabled={searchInProgress}
                  >
                    {question}
                  </Button>
                </li>
              ))}
            </ul>
          </div>
        )}

        {(results.length > 0 || currentQuestion) && (
          <div className={styles.docsPopoverAnswerContainer} ref={searchResultsWrapperRef}>
            {results.map((result: IResult) => {
              const { answer, error } = result;
              return (
                <div key={uuidv4()} className={styles.docsPopoverAnswerWrapper}>
                  <p className={styles.docsPopoverQuestion}>{result.question}</p>
                  {answer && (
                    <p className={styles.docsPopoverAnswer}>
                      <FormattedResultText text={answer} />
                    </p>
                  )}
                  {error && <p className={styles.docsPopoverError}>{error}</p>}
                </div>
              );
            })}
            {currentQuestion && (
              <div className={styles.docsPopoverAnswerWrapper}>
                <p className={styles.docsPopoverQuestion}>{currentQuestion}</p>
                <p className={styles.docsPopoverWaiting}>
                  <ThreeDots />
                </p>
              </div>
            )}
            {!currentQuestion && (
              <Button
                type="link"
                size="small"
                className={styles.clearButton}
                onClick={() => resetState()}
              >
                {CLEAR_BUTTON_LABEL}
              </Button>
            )}
            <div />
          </div>
        )}
        <section>
          <Search
            placeholder={SEARCH_PLACEHOLDER}
            enterButton={
              <Button type="primary" disabled={searchInProgress} icon={<SendOutlined />} />
            }
            value={searchQuery}
            onSearch={(query) => submitQuery(query)}
            onChange={(event) => setSearchQuery(event.target.value)}
            disabled={searchInProgress}
          />
          <a
            href={DOCS_URL || 'https://docs.cloud.deepset.ai/'}
            target="_blank"
            rel="noreferrer"
            className={styles.docsLink}
          >
            {DOCUMENTATION_LINK_LABEL}
          </a>
        </section>
      </div>
    </Resizable>
  );
};

export default DocsModel;
