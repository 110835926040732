import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FileTwoTone } from '@ant-design/icons';
import Icon from '@ant-design/icons/lib/components/Icon';
import { Alert, Button, Divider, Form, Input, Select } from 'antd';
import { pipelineInProgress } from '@utils/pipelines';
import ExternalSvg from '@assets/icons/external.svg?react';
import { CANCEL_BUTTON_LABEL } from '@constants/common';
import { StatusCodes } from '@constants/enum/common';
import {
  DEPLOY_BUTTON_LABEL,
  DETAILS_BUTTON_LABEL,
  EDIT_BUTTON_LABEL,
  FILES_STATISTIC_TITLE,
  MANDATORY_LABEL,
  PROJECT_CONFIGURATION_DEPLOYED_PIPELINE_ALERT,
  PROJECT_CONFIGURATION_FILES,
  PROJECT_CONFIGURATION_FILES_DESCRIPTION,
  PROJECT_CONFIGURATION_INDEXING_PIPELINE_ALERT,
  PROJECT_CONFIGURATION_LABELING_GUIDELINE,
  PROJECT_CONFIGURATION_LABELING_GUIDELINE_ADD_BUTTON,
  PROJECT_CONFIGURATION_LABELING_GUIDELINE_DESCRIPTION,
  PROJECT_CONFIGURATION_LABELING_GUIDELINE_EDIT_BUTTON,
  PROJECT_CONFIGURATION_PIPELINE,
  PROJECT_CONFIGURATION_PIPELINE_DESCRIPTION,
  PROJECT_CONFIGURATION_QUERY_TARGET,
  PROJECT_CONFIGURATION_QUERY_TARGET_DESCRIPTION,
  PROJECT_CONFIGURATION_QUERY_TARGET_INPUT_LABEL,
  PROJECT_CONFIGURATION_UNDEPLOYED_PIPELINE_ALERT,
  REMOVE_FROM_PROJECT_BUTTON_LABEL,
  REMOVE_SELECTED_PIPELINE_CONFIRMATION_MESSAGE,
  SELECT_PIPELINE_PLACEHOLDER,
  SELECT_USE_TEMPLATE_PIPELINE_OPTION,
  SelectedPipelineMenuActions,
  TRY_AGAIN_BUTTON_LABEL,
  UNDEPLOY_BUTTON_LABEL,
} from '@constants/labeling';
import {
  CREATE_PIPELINE_TITLE,
  NAME_YOUR_PIPELINE_TITLE,
  PipelineDesiredStatusCodes,
  PipelineOutputType,
  PipelineStatusCodes,
} from '@constants/pipelines';
import {
  createPipeline,
  deployPipeline,
  fetchPipeline,
  fetchPipelineIndexing,
  resetMessage,
  undeployPipeline,
} from '@redux/actions/pipelineActions';
import {
  pipelineCreateUpdatePipelineStatusSelector,
  pipelineIndexingDetailsSelector,
  pipelineMessageSelector,
  pipelinesSelector,
} from '@redux/selectors/pipelineSelectors';
import {
  EditableLabelingProjectFields,
  IAPIPaginationData,
  IMessage,
  IPipeline,
  IPipelineIndexingData,
} from '@redux/types/types';
import DataTableActions from '@components/dataTable/DataTableActions';
import LabelingGuidelineModal from '@components/labelingGuidelineModal/LabelingGuidelineModal';
import NamePipelineModal from '@components/namePipelineModal/NamePipelineModal';
import PipelineStatusTag from '@components/pipelineStatusTag/PipelineStatusTag';
import styles from './labelingProjectSettings.module.scss';

const { Item } = Form;

const NEW_PIPELINE_FROM_TEMPLATE_ID = 'TEMPLATE';

interface ILabelingProjectSettingsProps {
  projectConfigurationFormValues: EditableLabelingProjectFields;
  totalFiles: number;
  selectedPipeline: IPipeline | null;
  onSelectPipeline: (pipeline: IPipeline) => void;
  onProjectConfigurationLocalStateChange: (field: string, value: string | number | null) => void;
}

const LabelingProjectSettings = ({
  projectConfigurationFormValues,
  totalFiles,
  selectedPipeline,
  onSelectPipeline,
  onProjectConfigurationLocalStateChange,
}: ILabelingProjectSettingsProps) => {
  const dispatch = useDispatch();
  const { data: pipelines }: IAPIPaginationData<IPipeline[]> = useSelector((state) =>
    pipelinesSelector(state, null),
  );
  const pipelineMessage: IMessage = useSelector(pipelineMessageSelector);
  const pipelineCreateUpdatePipelineStatus: StatusCodes = useSelector(
    pipelineCreateUpdatePipelineStatusSelector,
  );
  const pipelineIndexingData: IPipelineIndexingData = useSelector(pipelineIndexingDetailsSelector);
  const [selectedPipelineToRemove, setSelectedPipelineToRemove] = useState('');
  const [createPipelineModalVisible, setCreatePipelineModalVisible] = useState(false);
  const [labelingGuidelineModalVisible, setLabelingGuidelineModalVisible] = useState(false);

  const queryTargetOptions = [50, 100, 200];

  // Pipeline methods

  // TODO: Do the filtering through the API once implemented
  const getPipelinesSelectOptions = () => {
    const retrievalPipelinesOptions = pipelines
      .filter(({ output_type }) => output_type === PipelineOutputType.DOCUMENT)
      .map(({ pipeline_id: id, name }) => ({
        id,
        value: id,
        label: name,
      }));
    const newPipelineFromTemplate = {
      id: NEW_PIPELINE_FROM_TEMPLATE_ID,
      value: NEW_PIPELINE_FROM_TEMPLATE_ID,
      label: SELECT_USE_TEMPLATE_PIPELINE_OPTION,
    };

    return [newPipelineFromTemplate, ...retrievalPipelinesOptions];
  };

  const handleDeployingPipeline = async (pipelineName: string) => {
    dispatch(deployPipeline(pipelineName));
  };

  const handleUndeployPipeline = async (pipelineName: string) => {
    dispatch(undeployPipeline(pipelineName));
  };

  const handleRemovingSelectedPipeline = () => {
    onProjectConfigurationLocalStateChange('pipelineId', null);
  };

  const handleAfterCreatingPipeline = async (pipelineName: string) => {
    const { payload: fetchedPipeline } = await (dispatch(
      fetchPipeline({ pipelineName }),
    ) as unknown as {
      payload: IPipeline;
    });
    // TODO: Review logic if we could unify it with a useEffect.
    // The problem lies with a race condition between fetching pipelines and setting the selected one
    onSelectPipeline(fetchedPipeline);
    onProjectConfigurationLocalStateChange('pipelineId', fetchedPipeline.pipeline_id);
    setCreatePipelineModalVisible(false);
    window.open(`/pipelines/designer/${pipelineName}`, '_blank');
  };

  const getSelectedPipelineCardPrimaryButton = () => {
    if (!selectedPipeline) return { label: '', action: () => {} };

    const { desired_status: desiredStatus, status } = selectedPipeline;
    let primaryButtonProps = {
      label: DEPLOY_BUTTON_LABEL,
      action: handleDeployingPipeline,
      loading: pipelineInProgress(status),
    };

    if (
      desiredStatus === PipelineDesiredStatusCodes.DEPLOYED &&
      status === PipelineStatusCodes.DEPLOYMENT_FAILED
    ) {
      primaryButtonProps = { ...primaryButtonProps, label: TRY_AGAIN_BUTTON_LABEL };
    }

    if (pipelineInProgress(status))
      primaryButtonProps = {
        ...primaryButtonProps,
        label: CANCEL_BUTTON_LABEL,
        action:
          status === PipelineStatusCodes.DEPLOYMENT_IN_PROGRESS ||
          status === PipelineStatusCodes.DEPLOYMENT_SCHEDULED
            ? handleUndeployPipeline
            : handleDeployingPipeline,
        loading: false,
      };

    if (status === PipelineStatusCodes.DEPLOYED) {
      primaryButtonProps = {
        ...primaryButtonProps,
        label: UNDEPLOY_BUTTON_LABEL,
        action: handleUndeployPipeline,
      };
    }

    return primaryButtonProps;
  };

  const handleMoreActionClick = async (key: string, pipelineName: string) => {
    if (key === SelectedPipelineMenuActions.Details)
      window.open(`/pipelines/${pipelineName}`, '_blank');
    if (key === SelectedPipelineMenuActions.Edit)
      window.open(`/pipelines/designer/${pipelineName}`, '_blank');
    if (key === SelectedPipelineMenuActions.Remove) setSelectedPipelineToRemove(pipelineName);
  };

  // Renders
  const renderPipelineSelectionSectionBody = () => {
    const moreOptionsMenuItems = [
      {
        label: EDIT_BUTTON_LABEL,
        key: SelectedPipelineMenuActions.Edit,
      },
      {
        label: DETAILS_BUTTON_LABEL,
        key: SelectedPipelineMenuActions.Details,
      },
      {
        label: REMOVE_FROM_PROJECT_BUTTON_LABEL,
        key: SelectedPipelineMenuActions.Remove,
        danger: true,
      },
    ];
    let alertProps = {
      description: PROJECT_CONFIGURATION_DEPLOYED_PIPELINE_ALERT,
      type: 'info',
    };

    if (selectedPipeline?.status !== PipelineStatusCodes.DEPLOYED) {
      alertProps = {
        description: PROJECT_CONFIGURATION_UNDEPLOYED_PIPELINE_ALERT,
        type: 'warning',
      };
    } else if (selectedPipeline?.indexing?.pending_file_count > 0) {
      alertProps = {
        description: PROJECT_CONFIGURATION_INDEXING_PIPELINE_ALERT,
        type: 'warning',
      };
    }

    return (
      <>
        {!selectedPipeline && (
          <Select
            showSearch
            value={projectConfigurationFormValues.pipelineId}
            onChange={(pipelineId: string) => {
              if (pipelineId === NEW_PIPELINE_FROM_TEMPLATE_ID) {
                setCreatePipelineModalVisible(true);
                return;
              }
              onProjectConfigurationLocalStateChange('pipelineId', pipelineId);
            }}
            options={getPipelinesSelectOptions()}
            placeholder={SELECT_PIPELINE_PLACEHOLDER}
          />
        )}
        {selectedPipeline && (
          <div className={styles.selectedPipelineWrapper}>
            <Alert
              className={styles.alert}
              showIcon
              type={alertProps.type as 'info' | 'warning'}
              description={alertProps.description}
            />
            <div className={styles.selectedPipelineCard}>
              <h6 className={styles.selectedPipelineCard_name}>{selectedPipeline.name}</h6>
              <div className={styles.selectedPipelineCard_date}>
                {new Date(selectedPipeline.created_at).toLocaleString()}
              </div>
              <div className={styles.selectedPipelineCard_status}>
                <PipelineStatusTag
                  pipeline={selectedPipeline}
                  indexingData={pipelineIndexingData}
                  getIndexingDetails={(name) => dispatch(fetchPipelineIndexing(name))}
                />
              </div>
              <div className={styles.selectedPipelineCard_action}>
                <DataTableActions
                  menu={{
                    items: moreOptionsMenuItems,
                    onClick: ({ key }) => handleMoreActionClick(key, selectedPipeline.name),
                  }}
                  item={selectedPipeline.name}
                  primaryButton={getSelectedPipelineCardPrimaryButton()}
                  onDelete={handleRemovingSelectedPipeline}
                  itemToDelete={selectedPipelineToRemove}
                  onCancelDelete={() => setSelectedPipelineToRemove('')}
                  deleteConfirmationMessage={REMOVE_SELECTED_PIPELINE_CONFIRMATION_MESSAGE}
                  cancelButtonLabel={CANCEL_BUTTON_LABEL}
                />
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  return (
    <div className={styles.projectConfiguration}>
      <NamePipelineModal
        openModal={createPipelineModalVisible}
        closeModal={() => setCreatePipelineModalVisible(false)}
        modalTitle={NAME_YOUR_PIPELINE_TITLE}
        okButtonText={CREATE_PIPELINE_TITLE}
        pipelineMessage={pipelineMessage}
        resetMessage={() => dispatch(resetMessage)}
        onAfterPipelineCreated={handleAfterCreatingPipeline}
        pipelineCreateUpdatePipelineStatus={pipelineCreateUpdatePipelineStatus}
        createPipeline={(payload) => dispatch(createPipeline(payload))}
        okButtonIcon={<Icon component={ExternalSvg} />}
      />
      <LabelingGuidelineModal
        initValue={projectConfigurationFormValues.labelingGuideline}
        open={labelingGuidelineModalVisible}
        onCancel={() => setLabelingGuidelineModalVisible(false)}
        onChange={(value) => onProjectConfigurationLocalStateChange('labelingGuideline', value)}
        onDone={() => setLabelingGuidelineModalVisible(false)}
      />
      <div className={styles.projectConfiguration_inputContainer}>
        <div className={styles.projectConfiguration_inputContainer_header}>
          <h5>
            {PROJECT_CONFIGURATION_PIPELINE} <span>({MANDATORY_LABEL})</span>
          </h5>
          <div>{PROJECT_CONFIGURATION_PIPELINE_DESCRIPTION}</div>
        </div>
        <div className={styles.projectConfiguration_inputContainer_body}>
          {renderPipelineSelectionSectionBody()}
        </div>
      </div>
      <Divider />
      <div className={styles.projectConfiguration_inputContainer}>
        <div className={styles.projectConfiguration_inputContainer_header}>
          <h5>{PROJECT_CONFIGURATION_LABELING_GUIDELINE}</h5>
          <div>{PROJECT_CONFIGURATION_LABELING_GUIDELINE_DESCRIPTION}</div>
        </div>
        <div className={styles.projectConfiguration_inputContainer_body}>
          <Button
            className={styles.labelingGuidelineButton}
            onClick={() => setLabelingGuidelineModalVisible(true)}
            type={projectConfigurationFormValues.labelingGuideline ? 'link' : 'default'}
          >
            {projectConfigurationFormValues.labelingGuideline
              ? PROJECT_CONFIGURATION_LABELING_GUIDELINE_EDIT_BUTTON
              : PROJECT_CONFIGURATION_LABELING_GUIDELINE_ADD_BUTTON}
          </Button>
        </div>
      </div>
      <Divider />
      <div className={styles.projectConfiguration_inputContainer}>
        <div className={styles.projectConfiguration_inputContainer_header}>
          <h5>{PROJECT_CONFIGURATION_QUERY_TARGET}</h5>
          <div>{PROJECT_CONFIGURATION_QUERY_TARGET_DESCRIPTION}</div>
        </div>
        <div className={styles.projectConfiguration_inputContainer_body}>
          <Form layout="vertical" className={styles.labelingTarget_form}>
            <Item
              className={styles.queryTarget}
              label={PROJECT_CONFIGURATION_QUERY_TARGET_INPUT_LABEL}
            >
              {queryTargetOptions.map((option) => (
                <Button
                  key={option}
                  onClick={() => onProjectConfigurationLocalStateChange('queryTarget', option)}
                >
                  {option}
                </Button>
              ))}
              <Input
                type="number"
                className={styles.queryTarget_customInput}
                value={projectConfigurationFormValues.queryTarget || 0}
                onChange={(event) =>
                  onProjectConfigurationLocalStateChange('queryTarget', event.target.value)
                }
              />
            </Item>
          </Form>
        </div>
      </div>
      <Divider />
      <div className={styles.projectConfiguration_inputContainer}>
        <div className={styles.projectConfiguration_inputContainer_header}>
          <h5>{PROJECT_CONFIGURATION_FILES}</h5>
          <div>{PROJECT_CONFIGURATION_FILES_DESCRIPTION}</div>
        </div>
        <div className={styles.projectConfiguration_inputContainer_body}>
          <div className={styles.filesCard}>
            <div className={styles.filesCard_title}>{FILES_STATISTIC_TITLE}</div>
            <div className={styles.filesCard_value}>
              <FileTwoTone /> <h3>{totalFiles}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LabelingProjectSettings;
