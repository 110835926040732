import React from 'react';
import { Button } from 'antd';
import {
  BY_USER_LABEL,
  USE_TEMPLATE_BUTTON_LABEL,
  VIEW_DETAILS_BUTTON_LABEL,
} from '@constants/pipeline-templates';
import { IPipelineTemplate } from '@redux/types/types';
import DcLogoAvatar from '@components/common/dcLogoAvatar/DcLogoAvatar';
import styles from './templateCard.module.scss';

const DESCRIPTION_CHARACTER_LIMIT = 250;

interface ITemplatesCardProps {
  template: IPipelineTemplate;
  onViewDetails?: () => void;
  onUseTemplate?: () => void;
}

const avatarSize = 'small';

const TemplatesCard = ({ template, onViewDetails, onUseTemplate }: ITemplatesCardProps) => {
  const { author, name, description } = template;

  const parseDescription = () => {
    if (!description) return '';
    if (description.length < DESCRIPTION_CHARACTER_LIMIT) return description;
    return `${description.slice(0, DESCRIPTION_CHARACTER_LIMIT)}...`;
  };

  return (
    <div className={styles.card} data-testid={template.pipeline_name}>
      <div className={styles.card_body}>
        <h6>{name}</h6>
        <div className={styles.card_body_description}>{parseDescription()}</div>
      </div>
      <div className={styles.card_createdBy}>
        <DcLogoAvatar size={avatarSize} />
        <span>
          {BY_USER_LABEL} {author}
        </span>
      </div>
      <div className={styles.card_actionButtons}>
        <Button size="small" block onClick={onViewDetails}>
          {VIEW_DETAILS_BUTTON_LABEL}
        </Button>
        <Button size="small" type="primary" block onClick={onUseTemplate}>
          {USE_TEMPLATE_BUTTON_LABEL}
        </Button>
      </div>
    </div>
  );
};

export default TemplatesCard;
