import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppstoreAddOutlined } from '@ant-design/icons';
import { Alert, message as AntdMessage, Button, Divider, Input, Modal, Popconfirm } from 'antd';
import { interpolateString } from '@utils/string';
import { StatusCodes } from '@constants/enum/common';
import {
  ADD_WORKSPACE_LABEL,
  ADD_WORKSPACE_MODAL_BUTTON_LABEL,
  DELETE_WORKSPACE_BUTTON_LABEL,
  DELETE_WORKSPACE_POPCONFIRM_CONFIRM_LABEL,
  DELETE_WORKSPACE_POPCONFIRM_LABEL,
  DELETE_WORKSPACE_POPCONFIRM_SUBTITLE,
  REACHED_MAX_WORKSPACES_BANNER,
  WORKSPACE_INPUT_PLACEHOLDER,
  WORKSPACES_TITLE,
} from '@constants/settings-page';
import { DEFAULT_WORKSPACE, MAX_WORKSPACES, ORGANIZATIONS_ID } from '@constants/workspace';
import {
  createWorkspace,
  deleteWorkspace,
  getWorkspaces,
  resetMessage,
} from '@redux/actions/organizationActions';
import {
  addWorkspaceStatusSelector,
  currentDeletingWorkspacesSelector,
  organizationMessageSelector,
  workspaceSelector,
} from '@redux/selectors/organizationSelectors';
import { userSelector } from '@redux/selectors/userSelectors';
import { IMessage, IUserData, IWorkspaceItem } from '@redux/types/types';
import TopNavigation from '@components/settings/TopNavigation';
import '@styles/components/layout.scss';
import styles from './workspacesPage.module.scss';

const WorkspacesPage = () => {
  const dispatch = useDispatch();
  const [messageApi, contextHolder] = AntdMessage.useMessage();
  const message: IMessage = useSelector(organizationMessageSelector);
  const { workspaces } = useSelector(workspaceSelector);
  const addWorkspaceStatus = useSelector(addWorkspaceStatusSelector);
  const currentDeletingWorkspaces = useSelector(currentDeletingWorkspacesSelector);
  const { organization }: IUserData = useSelector(userSelector);
  const [deleteWorkspaceName, setDeleteWorkspaceName] = useState('');
  const [isAddWorkspaceModalOpen, setIsAddWorkspaceModalOpen] = useState(false);
  const [newWorkspaceNameValue, setNewWorkspaceNameValue] = useState('');

  const isAddingNewWorkspace = addWorkspaceStatus === StatusCodes.IN_PROGRESS;

  useEffect(() => {
    if (message?.content) messageApi[message.type](message.content);

    return () => {
      dispatch(resetMessage);
    };
  }, [message]);

  const onDeleteOrganizationClick = (organizationId: string) => {
    dispatch(deleteWorkspace(organizationId));
    setDeleteWorkspaceName('');
  };

  const onAddNewWorkspace = async () => {
    await dispatch(createWorkspace(newWorkspaceNameValue));
    dispatch(getWorkspaces());
    setIsAddWorkspaceModalOpen(false);
    setNewWorkspaceNameValue('');
  };

  const isValidNewWorkspaceName = () => newWorkspaceNameValue.length > 2;

  const renderAddWorkspaceModal = () => (
    <Modal
      data-testid="workspacesSettings_addWorkspace_modal"
      title={ADD_WORKSPACE_LABEL}
      open={isAddWorkspaceModalOpen}
      okText={ADD_WORKSPACE_MODAL_BUTTON_LABEL}
      okButtonProps={{
        disabled: !isValidNewWorkspaceName() || isAddingNewWorkspace,
        loading: isAddingNewWorkspace,
      }}
      onOk={onAddNewWorkspace}
      onCancel={() => setIsAddWorkspaceModalOpen(false)}
    >
      <Input
        data-testid="workspacesSettings_addWorkspace_modal_input"
        placeholder={WORKSPACE_INPUT_PLACEHOLDER}
        value={newWorkspaceNameValue}
        onChange={(event) => setNewWorkspaceNameValue(event.target.value)}
      />
    </Modal>
  );

  return (
    <>
      {contextHolder}
      <div className="content-wrapper">
        {renderAddWorkspaceModal()}
        <TopNavigation />
        {workspaces.length ===
          (ORGANIZATIONS_ID.includes(organization) ? MAX_WORKSPACES * 2 : MAX_WORKSPACES) && (
          <Alert
            message={interpolateString(REACHED_MAX_WORKSPACES_BANNER, {
              maxWorkspaces: MAX_WORKSPACES,
            })}
            type="info"
            banner
          />
        )}
        <div className="content-wrapper_padding">
          <div>
            <div className={styles.workspaces_header}>
              <h5>{WORKSPACES_TITLE}</h5>
              {workspaces.length <
                (ORGANIZATIONS_ID.includes(organization) ? MAX_WORKSPACES * 2 : MAX_WORKSPACES) && (
                <Button
                  data-testid="workspacesSettings_addWorkspace"
                  type="primary"
                  icon={<AppstoreAddOutlined />}
                  onClick={() => setIsAddWorkspaceModalOpen(true)}
                >
                  {ADD_WORKSPACE_LABEL}
                </Button>
              )}
            </div>
            <div
              className={styles.workspaces_list}
              data-testid="workspacesSettings_workspaces_list"
            >
              {workspaces.map((item: IWorkspaceItem, index: number) => {
                return (
                  <Fragment key={item.name}>
                    <div
                      className={styles.workspaces_list_item}
                      data-testid={`workspacesSettings_workspaces_list_item_${item.name}`}
                    >
                      {item.name}
                      {item.name !== DEFAULT_WORKSPACE && (
                        <Popconfirm
                          data-testid="workspacesSettings_deleteWorkspace_popconfirm"
                          title={
                            <div className={styles.workspaces_popconfirm_body}>
                              <h6>
                                {interpolateString(DELETE_WORKSPACE_POPCONFIRM_LABEL, {
                                  workspace: item.name,
                                })}
                              </h6>
                              <div className={styles.workspaces_popconfirm_body_subtitle}>
                                {interpolateString(DELETE_WORKSPACE_POPCONFIRM_SUBTITLE, {
                                  workspace: item.name,
                                })}
                              </div>
                              <Input
                                data-testid="workspacesSettings_deleteWorkspace_popconfirm_input"
                                placeholder={WORKSPACE_INPUT_PLACEHOLDER}
                                value={deleteWorkspaceName}
                                onChange={(event) =>
                                  setDeleteWorkspaceName(event.currentTarget.value)
                                }
                              />
                            </div>
                          }
                          placement="left"
                          okText={DELETE_WORKSPACE_POPCONFIRM_CONFIRM_LABEL}
                          onConfirm={() => onDeleteOrganizationClick(item.name)}
                          okButtonProps={{
                            disabled:
                              deleteWorkspaceName !== item.name ||
                              !!currentDeletingWorkspaces[item.name],
                            loading: !!currentDeletingWorkspaces[item.name],
                          }}
                          onOpenChange={(open) => !open && setDeleteWorkspaceName('')}
                        >
                          <Button
                            type="link"
                            danger
                            data-testid={`workspacesSettings_workspaces_list_item_${item.name}_deleteButton`}
                            disabled={!!currentDeletingWorkspaces[item.name]}
                            loading={!!currentDeletingWorkspaces[item.name]}
                          >
                            {DELETE_WORKSPACE_BUTTON_LABEL}
                          </Button>
                        </Popconfirm>
                      )}
                    </div>
                    {index !== workspaces.length - 1 && <Divider />}
                  </Fragment>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WorkspacesPage;
