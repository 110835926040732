import React from 'react';
import { Alert, Button } from 'antd';
import { interpolateString } from '@utils/string';
import { DOCS_URL } from '@constants/constant';
import {
  DOCUMENTATION_BUTTON_LABEL,
  ERRORED_ALL_UPLOAD_DESCRIPTION,
  ERRORED_ALL_UPLOAD_HEADER,
  ERRORED_SINGLE_UPLOAD_DESCRIPTION,
  ERRORED_WRONG_SINGLE_FILE_TYPE,
  FILE_TYPES_DOCS_URL,
  LARGE_AMOUNT_FILES_DESCRIPTION,
  LARGE_AMOUNT_FILES_HEADER,
  RETRY_FAILED_BUTTON_LABEL,
  SUCCESSFUL_UPLOAD_HEADER,
  SUCCESSFUL_UPLOAD_HEADER_SINGULAR,
  UPLOADING_WITH_PYTHON_SDK_LABEL,
} from '@constants/upload';
import { ExtendedUploadFile, FileUploadStatus } from '@redux/types/types';
import styles from './fileUploadAlert.module.scss';

const DEFAULT_LABELS = {
  ERRORED_ALL_UPLOAD_DESCRIPTION,
  ERRORED_ALL_UPLOAD_HEADER,
  ERRORED_SINGLE_UPLOAD_DESCRIPTION,
  ERRORED_WRONG_SINGLE_FILE_TYPE,
  RETRY_FAILED_BUTTON_LABEL,
  SUCCESSFUL_UPLOAD_HEADER,
  SUCCESSFUL_UPLOAD_HEADER_SINGULAR,
  LARGE_AMOUNT_FILES_HEADER,
  LARGE_AMOUNT_FILES_DESCRIPTION,
  UPLOADING_WITH_PYTHON_SDK_LABEL,
};

const WRONG_FILE_TYPE_ERROR_CODE = 415;
const LARGE_AMOUNT_FILES_THRESHOLD = 3000;
const UPLOAD_WITH_PYTHON_SDK_DOCS_URL = `${DOCS_URL}docs/upload-files#upload-with-the-python-sdk`;

interface IFileUploadAlertProps {
  filesList: ExtendedUploadFile[];
  isUploadingCompleted: boolean;
  onRetryFailedFiles: () => void;
  isRetrying: boolean;
  fileName: string;
  labels?: Record<string, string>;
}

const FileUploadAlert = ({
  filesList,
  isUploadingCompleted,
  onRetryFailedFiles,
  isRetrying,
  fileName,
  labels = {},
}: IFileUploadAlertProps) => {
  let successfulUploads = 0;
  let erroredUploads = 0;

  const messageLabels = {
    ...DEFAULT_LABELS,
    ...labels,
  };

  if (filesList.length >= LARGE_AMOUNT_FILES_THRESHOLD) {
    return (
      <div className={styles.allFailedAlert}>
        <Alert
          message={messageLabels.LARGE_AMOUNT_FILES_HEADER}
          description={interpolateString(messageLabels.LARGE_AMOUNT_FILES_DESCRIPTION, {
            uploadWithPythoSDK: (
              <a href={UPLOAD_WITH_PYTHON_SDK_DOCS_URL} target="_blank" rel="noreferrer">
                {messageLabels.UPLOADING_WITH_PYTHON_SDK_LABEL}
              </a>
            ),
          })}
          type="warning"
          banner
          showIcon={false}
          data-testid="uploadModal_alert_warning"
        />
      </div>
    );
  }

  if (isUploadingCompleted) {
    successfulUploads = filesList.filter((f) => f.uploadStatus === FileUploadStatus.success).length;
    erroredUploads = filesList.filter((f) => f.uploadStatus === FileUploadStatus.error).length;

    if (erroredUploads > 0 || erroredUploads === filesList.length) {
      let errorMessage = messageLabels.ERRORED_ALL_UPLOAD_HEADER;
      if (erroredUploads === 1) {
        const erroredFile = filesList.find((f) => f.uploadStatus === FileUploadStatus.error);
        if (erroredFile?.errorCode === WRONG_FILE_TYPE_ERROR_CODE)
          errorMessage = messageLabels.ERRORED_WRONG_SINGLE_FILE_TYPE;
        else errorMessage = erroredFile?.errorMessage || errorMessage;
      }

      return (
        <div className={styles.allFailedAlert}>
          <Alert
            message={interpolateString(errorMessage, {
              documentation: (
                <a href={FILE_TYPES_DOCS_URL} target="_blank" rel="noreferrer">
                  {DOCUMENTATION_BUTTON_LABEL}
                </a>
              ),
            })}
            description={interpolateString(
              erroredUploads === 1
                ? messageLabels.ERRORED_SINGLE_UPLOAD_DESCRIPTION
                : messageLabels.ERRORED_ALL_UPLOAD_DESCRIPTION,
              {
                retryButton: (
                  <Button
                    key="retryButton"
                    type="link"
                    className={styles.retryButton}
                    onClick={onRetryFailedFiles}
                  >
                    {messageLabels.RETRY_FAILED_BUTTON_LABEL} ({erroredUploads})
                  </Button>
                ),
              },
            )}
            type="error"
            banner
            showIcon={false}
            data-testid="uploadModal_alert_error"
          />
        </div>
      );
    }

    if (erroredUploads === 0 && successfulUploads > 0 && !isRetrying) {
      return (
        <Alert
          message={
            successfulUploads === 1
              ? interpolateString(messageLabels.SUCCESSFUL_UPLOAD_HEADER_SINGULAR, { fileName })
              : interpolateString(messageLabels.SUCCESSFUL_UPLOAD_HEADER, { successfulUploads })
          }
          type="success"
          banner
          showIcon
          data-testid="uploadModal_alert_success"
        />
      );
    }
  }

  return null;
};

export default FileUploadAlert;
