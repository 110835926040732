import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { Button, Collapse, DatePicker, Empty, Select } from 'antd';
import type { RangePickerProps } from 'antd/es/date-picker';
import type { SliderMarks } from 'antd/es/slider';
import dayjs from 'dayjs';
import { debounce, isNil } from 'lodash';
import { formatDecimalPlaces } from '@utils/math';
import { interpolateString, transformToTitleCase } from '@utils/string';
import useEffectUpdateOnly from '@hooks/useEffectUpdateOnly';
import { useScreen } from '@hooks/useScreen';
import {
  CLEAR_SINGLE_FILTER,
  EMPTY_KEYWORD_SELECT_LABEL,
  EMPTY_METADATA_MESSAGE,
  EMPTY_PIPELINE_METADATA_MESSAGE,
  SELECT_KEYWORD_PLACEHOLDER,
  UPLOAD_FILE_LINK,
} from '@constants/search';
import {
  getMetadataValues as getMetadataValuesAction,
  getPipelineMinMaxAggregationMetadata,
  selectMetaDataFilterValues,
  setAppliedMetaDataFilterValues,
} from '@redux/actions/metadataFiltersActions';
import {
  metadataFiltersSelector,
  metadataFiltersValuesSelector,
  selectedMetaFilterValuesSelector,
} from '@redux/selectors/metadataFiltersSelectors';
import { IDateMetadataFilters, INumericRangeMetadataFilters } from '@redux/types/types';
import RangeSlider from '@components/common/rangeSlider/RangeSlider';
import styles from './metadataFilters.module.scss';

const DEFAULT_NUM_EXPANDED_KEYS = 5;

const { Option } = Select;
const { Panel } = Collapse;
const { RangePicker: DateRangePicker } = DatePicker;

interface IMetadataFiltersProps {
  pipelineName: string;
  requestWithoutErrors?: boolean;
  showAll?: boolean;
  isExternal?: boolean;
}

const MetadataFilters = ({
  pipelineName,
  requestWithoutErrors,
  showAll,
  isExternal,
}: IMetadataFiltersProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isMobileScreen, isTabletScreen } = useScreen();
  const isSmallerScreen = isMobileScreen || isTabletScreen;

  const metadataFields = useSelector(metadataFiltersSelector);
  const metadataValues = useSelector(metadataFiltersValuesSelector);
  const selectedMetaFilterValues = useSelector(selectedMetaFilterValuesSelector);
  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
  const [dateValues, setDateValues] = useState<Record<string, IDateMetadataFilters>>({});
  const [numericRangeValues, setNumericRangeValues] = useState<
    Record<string, INumericRangeMetadataFilters>
  >({});

  useEffect(() => {
    if (pipelineName) {
      dispatch(selectMetaDataFilterValues({}));
      dispatch(setAppliedMetaDataFilterValues({}));
    }
  }, [pipelineName]);

  const getMetadataValues = (payload: {
    pipelineName: string;
    fieldName: string;
    query: string;
    limit?: number;
  }) => {
    dispatch(getMetadataValuesAction({ ...payload, isExternal }));
  };

  const getMinMaxAggregationMetadataValues = (payload: {
    pipelineName: string;
    fieldName: string;
  }) => {
    dispatch(getPipelineMinMaxAggregationMetadata({ ...payload, isExternal }));
  };

  const getMetadataValuesByType = (keyword: string) => {
    const { type } = metadataFields[keyword] || {};
    if (type === 'keyword') {
      getMetadataValues({
        pipelineName,
        fieldName: keyword,
        query: '',
        limit: 10,
      });
    }

    if (type === 'float' || type === 'long' || type === 'date') {
      getMinMaxAggregationMetadataValues({
        pipelineName,
        fieldName: keyword,
      });
    }
  };

  useEffect(() => {
    if (pipelineName && metadataFields) {
      const keys = Object.keys(metadataFields).filter((keyword) => {
        const { type } = metadataFields[keyword] || {};
        return (
          type === 'keyword' ||
          type === 'float' ||
          type === 'long' ||
          type === 'date' ||
          type === 'boolean'
        );
      });

      // Only set first DEFAULT_NUM_EXPANDED_KEYS as default expanded
      if (keys && !showAll) setExpandedKeys(keys.slice(0, DEFAULT_NUM_EXPANDED_KEYS));
      else if (showAll) setExpandedKeys(keys);
      else setExpandedKeys([]);
    }
  }, [metadataFields]);

  useEffectUpdateOnly(() => {
    if (expandedKeys.length)
      expandedKeys.forEach((keyword) => {
        getMetadataValuesByType(keyword);
      });
  }, [expandedKeys]);

  useEffect(() => {
    Object.keys(metadataFields).forEach((keyword) => {
      const { type } = metadataFields[keyword] || {};
      if (type === 'float' || type === 'long' || type === 'date') {
        const minMaxKeyword = {
          min: (metadataValues[keyword] as any)?.min,
          max: (metadataValues[keyword] as any)?.max,
        };
        if ((minMaxKeyword.min || minMaxKeyword.max) && !selectedMetaFilterValues[keyword]) {
          if (metadataFields[keyword].type === 'date') {
            setDateValues({
              ...dateValues,
              [keyword]: { ...minMaxKeyword },
            });
          } else {
            setNumericRangeValues({
              ...numericRangeValues,
              [keyword]: { ...minMaxKeyword },
            });
          }
        }
      }
    });
  }, [metadataValues]);

  const resetDateAndNumericFiltersType = (keyword: string, type: string) => {
    if (type === 'date') {
      const selectedMetaFilterCopy = {
        min: (metadataValues[keyword] as any)?.min,
        max: (metadataValues[keyword] as any)?.max,
      };
      setDateValues((prev) => ({
        ...prev,
        [keyword]: { ...selectedMetaFilterCopy },
      }));
    } else if (type === 'float' || type === 'long') {
      const selectedMetaFilterCopy = {
        min: (metadataValues[keyword] as any)?.min,
        max: (metadataValues[keyword] as any)?.max,
      };
      setNumericRangeValues((prev) => ({
        ...prev,
        [keyword]: { ...selectedMetaFilterCopy },
      }));
    }
  };

  useEffect(() => {
    if (Object.keys(selectedMetaFilterValues).length === 0) {
      Object.keys(metadataFields).forEach((keyword) => {
        resetDateAndNumericFiltersType(keyword, metadataFields[keyword].type);
      });
    }
  }, [selectedMetaFilterValues]);

  // Get values for a specific metadata filter
  const handleMetaSelectSearch = (keyword: string) => {
    const getMetadataValuesHandler = (value: string) => {
      getMetadataValues({
        pipelineName,
        fieldName: keyword,
        query: value,
      });
    };

    return debounce(getMetadataValuesHandler, 1000);
  };

  // Store the values selected for a specific metadata filter
  const handleMetaSelectChange = (keyword: string) => {
    return (value: string[]) => {
      if (!value || value.length === 0) {
        const { [keyword]: ignore, ...rest } = selectedMetaFilterValues;
        dispatch(selectMetaDataFilterValues(rest));
      } else {
        dispatch(
          selectMetaDataFilterValues({
            ...selectedMetaFilterValues,
            [keyword]: value,
          }),
        );
      }
    };
  };

  const clearSingleFilterHandler = (event: any, keyword: string, type: string) => {
    event.stopPropagation();
    resetDateAndNumericFiltersType(keyword, type);
    const { [keyword]: ignore, ...rest } = selectedMetaFilterValues;
    dispatch(selectMetaDataFilterValues(rest));
  };

  const MetadataFiltersComponent = () => {
    const clearSingleMetaFilterButton = (keyword: string, type: string) => {
      if (
        (selectedMetaFilterValues[keyword] || selectedMetaFilterValues[keyword] === false) &&
        expandedKeys.includes(keyword)
      ) {
        return (
          <Button
            type="link"
            onClick={(event) => clearSingleFilterHandler(event, keyword, type)}
            className={styles.metaFilters_clear}
          >
            {CLEAR_SINGLE_FILTER}
          </Button>
        );
      }
      return null;
    };

    const handleCollapseChange = (key: string | string[]) => {
      setExpandedKeys(typeof key === 'string' ? [key] : key);
    };

    const handleSwitchChange = (keyword: string) => {
      return (value: boolean) => {
        dispatch(
          selectMetaDataFilterValues({
            ...selectedMetaFilterValues,
            [keyword]: value,
          }),
        );
      };
    };

    const handleNumericSlideChange = (keyword: string) => {
      return (value: [number, number]) => {
        const selectedMetaFilterCopy = {
          min: value[0],
          max: value[1],
        };
        dispatch(
          selectMetaDataFilterValues({
            ...selectedMetaFilterValues,
            [keyword]: selectedMetaFilterCopy as any,
          }),
        );
        setNumericRangeValues({
          ...numericRangeValues,
          [keyword]: { ...selectedMetaFilterCopy },
        });
      };
    };

    const handleDatePickerChange = (keyword: string) => {
      const onChange: RangePickerProps['onChange'] = (dates, dateStrings) => {
        if (dates) {
          const selectedMetaFilterCopy = {
            min: dateStrings[0],
            max: dateStrings[1],
          };
          dispatch(
            selectMetaDataFilterValues({
              ...selectedMetaFilterValues,
              [keyword]: selectedMetaFilterCopy as any,
            }),
          );
          setDateValues({
            ...dateValues,
            [keyword]: { ...selectedMetaFilterCopy },
          });
        } else {
          const { [keyword]: ignore, ...rest } = selectedMetaFilterValues;
          dispatch(selectMetaDataFilterValues(rest));
          const selectedMetaFilterCopy = {
            min: (metadataValues[keyword] as any)?.min,
            max: (metadataValues[keyword] as any)?.max,
          };
          setDateValues({
            ...dateValues,
            [keyword]: { ...selectedMetaFilterCopy },
          });
        }
      };
      return onChange;
    };

    // Message when there are no pipelines and no metadata filters
    const emptyPipelineAndMetadataMessage = interpolateString(EMPTY_PIPELINE_METADATA_MESSAGE, {
      uploadFile: (
        <Button
          key="uploadFile"
          type="link"
          onClick={() => navigate('/files')}
          className={styles.emptyPipeline_uploadFile}
        >
          {UPLOAD_FILE_LINK}
        </Button>
      ),
    });

    const emptyPipelineMeta =
      (!isNil(requestWithoutErrors) && requestWithoutErrors) || !pipelineName ? (
        <div className={styles.emptyPipeline}>
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={
              <span>{pipelineName ? EMPTY_METADATA_MESSAGE : emptyPipelineAndMetadataMessage}</span>
            }
          />
        </div>
      ) : null;

    const getComponentByMetadataType = (keyword: string, type: string) => {
      if (type === 'keyword') {
        return (
          <Select
            mode="multiple"
            size={isSmallerScreen ? 'large' : 'middle'}
            showSearch
            allowClear
            suffixIcon={null}
            optionFilterProp="children"
            className={styles.metaFilters_select}
            value={selectedMetaFilterValues[keyword] as string[]}
            filterOption={false}
            placeholder={SELECT_KEYWORD_PLACEHOLDER}
            onSearch={handleMetaSelectSearch(keyword)}
            onChange={handleMetaSelectChange(keyword)}
            notFoundContent={EMPTY_KEYWORD_SELECT_LABEL}
            data-testid="metadataFilters_keywordSelect"
          >
            {Array.isArray(metadataValues[keyword])
              ? (metadataValues[keyword] as string[])?.map((value) => (
                  <Option key={value} value={value}>
                    {value}
                  </Option>
                ))
              : null}
          </Select>
        );
      }
      if (type === 'boolean') {
        return (
          <Select
            size={isSmallerScreen ? 'large' : 'middle'}
            value={selectedMetaFilterValues[keyword] as boolean}
            onChange={(value) => handleSwitchChange(keyword)(value)}
            className={styles.metaFilters_select}
            data-testid="metadataFilters_booleanSelect"
            defaultActiveFirstOption={false}
            allowClear
          >
            <Option value>True</Option>
            <Option value={false}>False</Option>
          </Select>
        );
      }
      if (type === 'float' || type === 'long') {
        const formatedMinValue = formatDecimalPlaces((metadataValues[keyword] as any)?.min);
        const formatedMaxValue = formatDecimalPlaces((metadataValues[keyword] as any)?.max);

        const minMaxRange: SliderMarks = {
          [formatedMinValue]: {
            label: <span>{formatedMinValue}</span>,
          },
          [formatedMaxValue]: {
            label: <span>{formatedMaxValue}</span>,
          },
        };

        return (
          <RangeSlider
            data-testid="metadataFilters_rangeSlider"
            min={formatedMinValue}
            max={formatedMaxValue}
            marks={minMaxRange}
            values={[
              numericRangeValues[keyword]?.min
                ? +formatDecimalPlaces(numericRangeValues[keyword].min)
                : 0,
              numericRangeValues[keyword]?.max
                ? +formatDecimalPlaces(numericRangeValues[keyword].max)
                : 0,
            ]}
            onChange={handleNumericSlideChange(keyword)}
          />
        );
      }
      if (type === 'date') {
        return (
          <DateRangePicker
            data-testid="metadataFilters_datePicker"
            size={isSmallerScreen ? 'large' : 'middle'}
            value={[
              dayjs((dateValues[keyword] as any)?.min),
              dayjs((dateValues[keyword] as any)?.max),
            ]}
            disabledDate={(current) => {
              return (
                current.isBefore((metadataValues[keyword] as any)?.min) ||
                current.isAfter((metadataValues[keyword] as any)?.max)
              );
            }}
            onChange={handleDatePickerChange(keyword)}
          />
        );
      }

      return null;
    };

    const metadataFiltersSelects = (
      <>
        <Collapse
          bordered={false}
          activeKey={expandedKeys}
          expandIconPosition="end"
          expandIcon={({ isActive }) =>
            isActive ? (
              <Button
                size="small"
                icon={<UpOutlined />}
                type="text"
                className={styles.metaFilters_collapseButton}
              />
            ) : (
              <Button
                size="small"
                icon={<DownOutlined />}
                type="text"
                className={styles.metaFilters_collapseButton}
              />
            )
          }
          onChange={handleCollapseChange}
          className={styles.metaFilters}
        >
          {Object.keys(metadataFields).map((keyword) => {
            return (
              <Panel
                header={transformToTitleCase(keyword)}
                key={keyword.toString()}
                extra={clearSingleMetaFilterButton(keyword, metadataFields[keyword].type)}
                className={styles.metaFilters_panel}
                data-testid="metadataFilters_item"
              >
                {getComponentByMetadataType(keyword, metadataFields[keyword].type)}
              </Panel>
            );
          })}
        </Collapse>
      </>
    );

    return (
      <>{Object.keys(metadataFields).length > 0 ? metadataFiltersSelects : emptyPipelineMeta}</>
    );
  };

  return MetadataFiltersComponent();
};

export default MetadataFilters;
