import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Icon, { LoadingOutlined } from '@ant-design/icons';
import { Button, Radio, Result, Spin } from 'antd';
import ExternalSvg from '@assets/icons/external.svg?react';
import {
  CPU_SERVER_DESCRIPTION,
  CPU_SERVER_TITLE,
  ERROR_RESULT_SUBTITLE,
  ERROR_RESULT_TITLE,
  GPU_SERVER_DESCRIPTION,
  GPU_SERVER_TITLE,
  INFO_RESULT_SUBTITLE,
  INFO_RESULT_TITLE,
  OPEN_NOTEBOOK_BUTTON_LABEL,
  OPEN_NOTEBOOK_SERVER_BUTTON_LABEL,
  ServerType,
  START_SERVER_BUTTON_LABEL,
  SUCCESS_RESULT_SUBTITLE,
  SUCCESS_RESULT_TITLE,
  TRY_AGAIN_BUTTON_LABEL,
} from '@src/constants/notebook';
import {
  createNotebook,
  getNotebookServerStatus,
  resetStatus,
  startNotebookServer,
  startPollingServerStatus,
  stopPollingServerStatus,
} from '@src/redux/actions/notebookActions';
import { notebookServerStateSelector } from '@src/redux/selectors/notebookSelectors';
import { NotebookServerStates } from '@src/redux/types/types';
import styles from './notebookStartPage.module.scss';

type ResultStatus = 'success' | 'error' | 'info' | 'warning' | 404 | 403 | 500;

const NotebookStartPage = () => {
  const dispatch = useDispatch();
  const JUPYTER_URL = import.meta.env.VITE_JUPYTER_URL;
  const serverState: NotebookServerStates = useSelector(notebookServerStateSelector);
  const { pipelineID, create } = useParams() as { pipelineID: string; create: string };
  const [resultStatus, setResultStatus] = useState<ResultStatus>('info');
  const [serverResource, setServerResource] = useState<ServerType>(ServerType.UNKNOWN);
  const [resultTitle, setResultTitle] = useState(
    'Please wait while we prepare the server for you.',
  );
  const [resultSubtitle, setResultSubtitle] = useState(
    'This may take up to several minutes as it connects all the technologies together so, please bear with us.',
  );
  const [resultExtra, setResultExtra] = useState<any>([]);
  const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

  useEffect(() => {
    dispatch(getNotebookServerStatus());
    return () => {
      dispatch(stopPollingServerStatus());
    };
  }, [dispatch]);

  useEffect(() => {
    switch (serverState) {
      case NotebookServerStates.DOWN:
        dispatch(stopPollingServerStatus());
        break;
      case NotebookServerStates.ERROR:
        dispatch(stopPollingServerStatus());
        setResultStatus('error');
        setResultTitle(ERROR_RESULT_TITLE);
        setResultSubtitle(ERROR_RESULT_SUBTITLE);
        setResultExtra([
          <Button type="primary" key="try" onClick={() => dispatch(resetStatus)}>
            {TRY_AGAIN_BUTTON_LABEL}
          </Button>,
        ]);
        break;
      case NotebookServerStates.UP:
        dispatch(stopPollingServerStatus());
        setResultStatus('success');
        setResultTitle(SUCCESS_RESULT_TITLE);
        setResultSubtitle(SUCCESS_RESULT_SUBTITLE);
        if (create) {
          setResultExtra([
            <Button
              icon={<Icon component={ExternalSvg} />}
              type="primary"
              key="try"
              onClick={() => dispatch(createNotebook(pipelineID))}
            >
              {OPEN_NOTEBOOK_BUTTON_LABEL}
            </Button>,
          ]);
        } else {
          setResultExtra([
            <Button
              icon={<Icon component={ExternalSvg} />}
              type="primary"
              key="try"
              onClick={() => window.open(JUPYTER_URL, '_blank')}
            >
              {OPEN_NOTEBOOK_SERVER_BUTTON_LABEL}
            </Button>,
          ]);
        }
        break;
      case NotebookServerStates.STARTING:
        dispatch(startPollingServerStatus());
        setResultStatus('info');
        setResultTitle(INFO_RESULT_TITLE);
        setResultSubtitle(INFO_RESULT_SUBTITLE);
        setResultExtra([]);
        break;
      default:
        break;
    }
  }, [serverState, dispatch, pipelineID, create, JUPYTER_URL]);

  const onStartServer = (resource: ServerType) => dispatch(startNotebookServer(resource));

  const Card = (header: string, description: string, resource: ServerType, onClick: any) => {
    return (
      <div
        role="button"
        tabIndex={0}
        className={`${styles.resourceSelectionCard} ${
          serverResource === resource ? styles.selected : ''
        }`}
        onClick={() => onClick()}
        onKeyDown={() => onClick()}
      >
        <h4 className={styles.cardHeader}> {header} </h4>
        <p> {description} </p>
        <Radio checked={serverResource === resource} />
      </div>
    );
  };

  const SelectResourceSection = () => (
    <>
      <div className={styles.resourceSelection}>
        {Card(CPU_SERVER_TITLE, CPU_SERVER_DESCRIPTION, ServerType.CPU, () =>
          setServerResource(ServerType.CPU),
        )}
        {Card(GPU_SERVER_TITLE, GPU_SERVER_DESCRIPTION, ServerType.GPU, () =>
          setServerResource(ServerType.GPU),
        )}
      </div>
      <div className={styles.resourceSelectionAction}>
        <Button
          type="primary"
          onClick={() => onStartServer(serverResource)}
          disabled={!serverResource || serverResource === ServerType.UNKNOWN}
        >
          {START_SERVER_BUTTON_LABEL}
        </Button>
      </div>
    </>
  );

  return (
    <div className={styles.container}>
      <div className={styles.resultContainer}>
        {serverState === NotebookServerStates.DOWN ? (
          <SelectResourceSection />
        ) : (
          <Result
            status={resultStatus}
            title={resultTitle}
            subTitle={resultSubtitle}
            extra={resultExtra}
            icon={resultStatus === 'info' && <Spin indicator={antIcon} />}
          />
        )}
      </div>
    </div>
  );
};

export default NotebookStartPage;
