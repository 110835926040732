import produce from 'immer';
import { MessageCodes, StatusCodes } from '@src/constants/enum/common';
import { normalizeErrorMessage } from '@src/utils/error';
import {
  CREATE_NOTEBOOK,
  FULFILLED,
  GET_NOTEBOOK_SERVER_STATUS,
  IMessage,
  NotebookServerStates,
  PENDING,
  REJECTED,
  RESET_NOTEBOOK_STATUS,
  START_NOTEBOOK_SERVER_STATUS,
} from '../types/types';

interface IInitialStateProps {
  serverState: NotebookServerStates;
  status: StatusCodes;
  message: IMessage;
}

export const initialState: IInitialStateProps = {
  serverState: NotebookServerStates.DOWN,
  status: StatusCodes.IDLE,
  message: {
    type: MessageCodes.INFO,
    content: '',
  },
};

function notebookReducer(state = initialState, action: any) {
  return produce(state, (draft) => {
    const localDraft = draft;
    switch (action.type) {
      case RESET_NOTEBOOK_STATUS:
        return initialState;
      case `${CREATE_NOTEBOOK}/${PENDING}`:
        localDraft.status = StatusCodes.IN_PROGRESS;
        localDraft.message = initialState.message;
        break;
      case `${START_NOTEBOOK_SERVER_STATUS}/${PENDING}`:
        localDraft.status = StatusCodes.IN_PROGRESS;
        localDraft.message = initialState.message;
        localDraft.serverState = NotebookServerStates.STARTING;
        break;
      case `${GET_NOTEBOOK_SERVER_STATUS}/${REJECTED}`:
      case `${START_NOTEBOOK_SERVER_STATUS}/${REJECTED}`:
        localDraft.serverState = NotebookServerStates.ERROR;
        localDraft.status = StatusCodes.ERROR;
        localDraft.message = {
          type: MessageCodes.ERROR,
          content: normalizeErrorMessage(action.payload),
        };
        break;
      case `${CREATE_NOTEBOOK}/${REJECTED}`:
        localDraft.status = StatusCodes.ERROR;
        localDraft.message = {
          type: MessageCodes.ERROR,
          content: normalizeErrorMessage(action.payload),
        };
        break;
      case `${GET_NOTEBOOK_SERVER_STATUS}/${FULFILLED}`:
        if (
          state.serverState === NotebookServerStates.STARTING &&
          action.payload === NotebookServerStates.DOWN
        ) {
          localDraft.serverState = NotebookServerStates.ERROR;
          localDraft.status = StatusCodes.ERROR;
          localDraft.message = {
            type: MessageCodes.ERROR,
            content: normalizeErrorMessage(action.payload),
          };
        } else {
          localDraft.serverState = action.payload;
          localDraft.status = action.payload;
        }
        break;

      default:
        break;
    }

    return localDraft;
  });
}

export default notebookReducer;
