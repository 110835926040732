import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactFlowProvider } from 'reactflow';
import { PartitionOutlined } from '@ant-design/icons';
import { COMMON_FORM_ERRORS } from '@constants/common';
import { PIPELINE_PREVIEW_TITLE } from '@constants/pipeline-templates';
import {
  NAME_NEW_PIPELINE_MODAL_OK_BUTTON,
  NAME_NEW_PIPELINE_MODAL_TITLE,
} from '@constants/pipelines';
import {
  createPipeline,
  resetMessage as resetPipelineMessage,
} from '@redux/actions/pipelineActions';
import {
  getPipelineTemplateByName,
  resetSelectedTemplate,
} from '@redux/actions/pipelineTemplatesActions';
import {
  pipelineCreateUpdatePipelineStatusSelector,
  pipelineMessageSelector,
} from '@redux/selectors/pipelineSelectors';
import { selectedTemplateSelector } from '@redux/selectors/pipelineTemplatesSelectors';
import { DeepsetCloudVersion, IPipelineTemplate } from '@redux/types/types';
import LoadingIndicator from '@components/common/LoadingIndicator/LoadingIndicator';
import NamePipelineModal from '@components/namePipelineModal/NamePipelineModal';
import PipelineDeepsetCloudVersionTag from '@components/pipelines/pipelineDeepsetCloudVersionTag/PipelineDeepsetCloudVersionTag';
import PipelineTemplateInfo from './components/PipelineTemplateInfo';
import styles from './pipelineTemplateDetails.module.scss';

const YamlVisualizer = React.lazy(
  () =>
    import(/* webpackChunkName: "YamlVisualizer" */ '@components/yamlVisualizer/YamlVisualizer'),
);

const PipelineTemplateDetailsPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedTemplate: IPipelineTemplate | null = useSelector(selectedTemplateSelector);
  const pipelineCreateUpdatePipelineStatus = useSelector(
    pipelineCreateUpdatePipelineStatusSelector,
  );
  const pipelineMessage = useSelector(pipelineMessageSelector);
  const [showPipelinePreview, setShowPipelinePreview] = useState(true);
  const [showNamePipelineModal, setShowNamePipelineModal] = useState(false);
  const [selectedNewTemplate, setSelectedNewTemplate] = useState<IPipelineTemplate>(
    selectedTemplate!,
  );
  const { templateName } = useParams() as {
    templateName: string;
  };

  useEffect(() => {
    return () => {
      dispatch(resetSelectedTemplate);
    };
  }, []);

  useEffect(() => {
    if (selectedTemplate?.deepset_cloud_version === DeepsetCloudVersion.V1)
      setShowPipelinePreview(true);
    else setShowPipelinePreview(false);
  }, [selectedTemplate]);

  useEffect(() => {
    if (templateName) dispatch(getPipelineTemplateByName({ templateName }));
  }, [templateName]);

  const onCreatePipeline = ({ pipelineName }: { pipelineName: string }) => {
    const { pipeline_yaml: pipelineYAML } = selectedNewTemplate;
    let payload = {};

    if (selectedNewTemplate?.deepset_cloud_version === DeepsetCloudVersion.V1)
      payload = {
        code: pipelineYAML,
        pipelineName,
        deepsetCloudVersion: selectedNewTemplate?.deepset_cloud_version,
      };
    else if (selectedNewTemplate?.deepset_cloud_version === DeepsetCloudVersion.V2)
      payload = {
        pipelineName,
        deepsetCloudVersion: selectedNewTemplate?.deepset_cloud_version,
        // TODO: Add values once the templates are ready
        indexingYaml: selectedTemplate?.indexing_yaml || '',
        queryYaml: selectedTemplate?.query_yaml || '',
      };

    dispatch(createPipeline(payload));
  };

  return (
    <>
      {selectedTemplate && (
        <>
          <div className={styles.wrapper}>
            <div
              className={`${styles.container} ${
                selectedTemplate?.deepset_cloud_version === DeepsetCloudVersion.V2
                  ? styles.containerDeepsetCloudVersion2
                  : ''
              }`}
            >
              <div className={styles.templateInfo}>
                <PipelineTemplateInfo
                  template={selectedTemplate!}
                  setShowNamePipelineModal={(showModal) => setShowNamePipelineModal(showModal)}
                  setSelectedNewTemplate={(template) => setSelectedNewTemplate(template)}
                  deepsetCloudVersion={
                    <PipelineDeepsetCloudVersionTag
                      deepsetCloudVersion={selectedTemplate!.deepset_cloud_version}
                    />
                  }
                />
              </div>
              {showPipelinePreview && (
                <div className={styles.pipelinePreview}>
                  <div className={styles.pipelinePreview_title}>
                    <PartitionOutlined className={styles.pipelinePreview_title_icon} />
                    <div className={styles.pipelinePreview_title_name}>
                      {PIPELINE_PREVIEW_TITLE}
                    </div>
                  </div>
                  <ReactFlowProvider>
                    <React.Suspense fallback={<LoadingIndicator />}>
                      <YamlVisualizer
                        code={selectedTemplate?.pipeline_yaml!}
                        yamlVisualizerExtended={false}
                      />
                    </React.Suspense>
                  </ReactFlowProvider>
                </div>
              )}
            </div>
          </div>
          <NamePipelineModal
            openModal={showNamePipelineModal}
            closeModal={() => {
              setShowNamePipelineModal(false);
              setSelectedNewTemplate(selectedNewTemplate!);
            }}
            description={!selectedNewTemplate ? COMMON_FORM_ERRORS.INVALID_CHARACTERS : ''}
            defaultPipelineNameValue={selectedNewTemplate?.pipeline_name}
            modalTitle={NAME_NEW_PIPELINE_MODAL_TITLE}
            okButtonText={NAME_NEW_PIPELINE_MODAL_OK_BUTTON}
            createPipeline={onCreatePipeline}
            onAfterPipelineCreated={() => navigate(`/pipelines`)}
            pipelineCreateUpdatePipelineStatus={pipelineCreateUpdatePipelineStatus}
            pipelineMessage={pipelineMessage}
            resetMessage={() => dispatch(resetPipelineMessage)}
          />
        </>
      )}
    </>
  );
};

export default PipelineTemplateDetailsPage;
