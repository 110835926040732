import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUsageLimits, getUsageMetricValue } from '@redux/actions/usageActions';
import { usageLimitsSelector, usageMetricsSelector } from '@redux/selectors/usageSelectors';
import useUsageDateRange from './useUsageDateRange';

const useMeteringUsage = () => {
  const dispatch = useDispatch();
  const { selectedDateRange } = useUsageDateRange();
  const usageMetrics = useSelector(usageMetricsSelector);
  const usageLimits = useSelector(usageLimitsSelector);

  useEffect(() => {
    const dateRangeValues = {
      from: selectedDateRange?.from,
      to: selectedDateRange?.to,
    };
    dispatch(getUsageLimits());
    dispatch(getUsageMetricValue({ dateRange: dateRangeValues }));
  }, []);

  return { usageMetrics, usageLimits };
};

export default useMeteringUsage;
