import { useDispatch, useSelector } from 'react-redux';
import { SelectedFilters } from '@constants/data-table';
import {
  FEEDBACK_BOOKMARK_REMOVED_SUCCESS_MESSAGE,
  FEEDBACK_BOOKMARK_SUCCESS_MESSAGE,
} from '@constants/pipeline-feedback';
import {
  createPipelineFeedbackTag as createPipelineFeedbackTagAction,
  deletePipelineFeedbackTag as deletePipelineFeedbackTagAction,
  exportPipelineFeedbackCSV as exportPipelineFeedbackCSVAction,
  getPipelineFeedback as getPipelineFeedbackAction,
  getPipelineFeedbackTags as getPipelineFeedbackTagsAction,
  providedSearchResultFeedback as providedSearchResultFeedbackAction,
  resetFeedbackData as resetFeedbackDataAction,
  resetFeedbackTags as resetFeedbackTagsAction,
  resetProvidedFeedbackStatus as resetProvidedFeedbackStatusAction,
  setProvidedFeedbackByResultId as setProvidedFeedbackByResultIdAction,
  updateSearchResultFeedback as updateSearchResultFeedbackAction,
} from '@redux/actions/pipelineFeedbackActions';
import {
  createPipelineFeedbackTagStatusSelector,
  deletePipelineFeedbackTagStatusSelector,
  feedbackSortValueSelector,
  getPipelineFeedbackStatusSelector,
  pipelineFeedbackDataSelector,
  pipelineFeedbackTagsByPipelineIdSelector,
  providedFeedbackByResultIdSelector,
  providePipelineFeedbackStatusSelector,
  updatedPipelineFeedbackStatusSelector,
} from '@redux/selectors/pipelineFeedbackSelectors';
import { FeedbackType, IPipelineFeedbackData } from '@redux/types/types';

const usePipelineFeedback = () => {
  const dispatch = useDispatch();

  const pipelineFeedback = useSelector(pipelineFeedbackDataSelector);
  const getPipelineFeedbackStatus = useSelector(getPipelineFeedbackStatusSelector);
  const selectedFeedbackSortValue: string = useSelector(feedbackSortValueSelector);
  const providedFeedbackByResultId = useSelector(providedFeedbackByResultIdSelector);
  const providedPipelineFeedbackStatus = useSelector(providePipelineFeedbackStatusSelector);
  const updatedPipelineFeedbackStatus = useSelector(updatedPipelineFeedbackStatusSelector);
  const pipelineFeedbackTagsByPipelineId = useSelector(pipelineFeedbackTagsByPipelineIdSelector);
  const createPipelineFeedbackTagStatus = useSelector(createPipelineFeedbackTagStatusSelector);
  const deletePipelineFeedbackTagStatus = useSelector(deletePipelineFeedbackTagStatusSelector);

  const getFeedbackTags = ({
    pipelineId,
    isExternal,
  }: {
    pipelineId: string;
    isExternal?: boolean;
  }) => {
    dispatch(getPipelineFeedbackTagsAction({ pipelineId, isExternal }));
  };

  const exportPipelineFeedbackCSV = (pipelineId: string, pipelineName: string) => {
    dispatch(exportPipelineFeedbackCSVAction({ pipelineId, pipelineName }));
  };

  const createFeedbackTag = (pipelineId: string, name: string) => {
    dispatch(createPipelineFeedbackTagAction({ pipelineId, tagName: name }));
  };

  const deleteFeedbackTag = (pipelineId: string, tagId: string) => {
    dispatch(deletePipelineFeedbackTagAction({ pipelineId, tagId }));
  };

  const resetFeedbackData = () => {
    dispatch(resetFeedbackDataAction);
  };

  const resetFeedbackTags = () => {
    dispatch(resetFeedbackTagsAction);
  };

  const resetProvidedFeedbackStatus = () => {
    dispatch(resetProvidedFeedbackStatusAction);
  };

  const setProvidedFeedbackByResultId = (resultId: string, feedback: IPipelineFeedbackData) => {
    dispatch(setProvidedFeedbackByResultIdAction({ resultId, feedback }));
  };

  const getPipelineFeedback = (params: {
    pipelineId: string;
    currentPage: number;
    pageSize: number;
    searchValue: string;
    sortValue?: string;
    filterValues?: SelectedFilters;
  }) => {
    dispatch(getPipelineFeedbackAction(params));
  };

  const updateSearchResultFeedback = ({
    type,
    feedbackId,
    resultId,
    queryId,
    pipelineId,
    comment,
    tags,
    bookmarked,
    successMessage,
    isExternal,
  }: {
    feedbackId: string;
    pipelineId: string;
    resultId: string;
    queryId: string;
    type?: FeedbackType | null;
    comment?: string;
    tags?: string[];
    bookmarked?: boolean;
    isExternal?: boolean;
    successMessage?: string;
  }) => {
    dispatch(
      updateSearchResultFeedbackAction({
        feedbackId,
        resultId,
        pipelineId,
        queryId,
        type,
        comment,
        tags,
        bookmarked,
        isExternal,
        successMessage,
      }),
    );
  };

  const providedSearchResultFeedback = ({
    type,
    resultId,
    queryId,
    pipelineId,
    comment,
    tags,
    isExternal,
  }: {
    type: FeedbackType | null;
    resultId: string;
    queryId: string;
    pipelineId: string;
    comment?: string;
    tags?: string[];
    isExternal?: boolean;
  }) => {
    if (providedFeedbackByResultId[resultId]) {
      const { feedback_id: feedbackId } = providedFeedbackByResultId[resultId];
      updateSearchResultFeedback({
        type,
        feedbackId,
        resultId,
        queryId,
        pipelineId,
        comment,
        tags,
        isExternal,
      });
      return;
    }

    dispatch(
      providedSearchResultFeedbackAction({
        resultId,
        pipelineId,
        queryId,
        type,
        comment,
        tags,
        isExternal,
      }),
    );
  };

  const bookmarkSearchResult = ({
    resultId,
    queryId,
    pipelineId,
    bookmarked,
    isExternal,
  }: {
    resultId: string;
    queryId: string;
    pipelineId: string;
    comment?: string;
    tags?: string[];
    bookmarked?: boolean;
    isExternal?: boolean;
  }) => {
    const successMessage = bookmarked
      ? FEEDBACK_BOOKMARK_SUCCESS_MESSAGE
      : FEEDBACK_BOOKMARK_REMOVED_SUCCESS_MESSAGE;

    if (providedFeedbackByResultId[resultId]) {
      const { feedback_id: feedbackId } = providedFeedbackByResultId[resultId];
      updateSearchResultFeedback({
        feedbackId,
        resultId,
        queryId,
        pipelineId,
        bookmarked,
        isExternal,
        successMessage,
      });
      return;
    }

    dispatch(
      providedSearchResultFeedbackAction({
        type: null,
        resultId,
        pipelineId,
        queryId,
        bookmarked,
        isExternal,
        successMessage,
      }),
    );
  };

  return {
    providedFeedbackByResultId,
    providedPipelineFeedbackStatus,
    updatedPipelineFeedbackStatus,
    pipelineFeedback,
    getPipelineFeedbackStatus,
    selectedFeedbackSortValue,
    setProvidedFeedbackByResultId,
    resetFeedbackData,
    getPipelineFeedback,
    exportPipelineFeedbackCSV,
    providedSearchResultFeedback,
    updateSearchResultFeedback,
    bookmarkSearchResult,
    pipelineFeedbackTagsByPipelineId,
    createPipelineFeedbackTagStatus,
    deletePipelineFeedbackTagStatus,
    getFeedbackTags,
    createFeedbackTag,
    deleteFeedbackTag,
    resetFeedbackTags,
    resetProvidedFeedbackStatus,
  };
};

export default usePipelineFeedback;
