import { createSelector } from 'reselect';
import { RootState } from '@redux/store';

export const fileStoreSelector = (state: RootState) => state.fileStore;

export const filesSelector = createSelector(fileStoreSelector, (fileStore) => fileStore.files);

export const fileMessageSelector = createSelector(
  fileStoreSelector,
  (fileStore) => fileStore.message,
);

export const fileActionStatusSelector = createSelector(
  fileStoreSelector,
  (fileStore) => fileStore.actionStatus,
);

export const fileFetchStatusSelector = createSelector(
  fileStoreSelector,
  (fileStore) => fileStore.fetchStatus,
);

export const updateFileMetaStatusSelector = createSelector(
  fileStoreSelector,
  (fileStore) => fileStore.updateFileMetaStatus,
);

export const sortValueSelector = createSelector(
  fileStoreSelector,
  (fileStore) => fileStore.sortValue,
);

export const selectedFileDocumentSelector = createSelector(
  fileStoreSelector,
  (fileStore) => fileStore.selectedFileDocument,
);

export const fileContentSelector = createSelector(
  fileStoreSelector,
  (fileStore) => fileStore.fileContent,
);

export const getFileContentStatusSelector = createSelector(
  fileStoreSelector,
  (fileStore) => fileStore.getFileContentStatus,
);
