import { getWorkspace } from '@utils/store';
import { NUMBER_OF_FILES_PER_PAGE } from '@constants/common';
import { MIMETypes } from '@constants/enum/common';
import { FETCH_PIPELINE_LOGS_LIMIT } from '@constants/pipeline-details';
import { PipelineDesiredStatusCodes, PipelineStatusCodes } from '@constants/pipelines';
import {
  DeepsetCloudVersion,
  IAPIPaginationData,
  ISearchHistory,
  IValidatePipelineParams,
  PipelineServiceLevel,
} from '@redux/types/types';
import { http } from './base-http';

const DEFAULT_TEMPLATES_FETCH_LIMIT = 20;

export interface IAllPipelinesData {
  limit?: number;
  page_number?: number;
  after?: string;
  name?: string;
  status?: PipelineStatusCodes;
  desired_status?: PipelineDesiredStatusCodes;
  supports_prompt?: boolean;
  service_level?: PipelineServiceLevel;
  field?: string;
  order?: string;
}

export interface IPipelineFeedbackData {
  limit?: number;
  page_number?: number;
  after?: string;
  field?: string;
  order?: string;
  filter?: string;
}

export interface IPipelinePrototypeLinkData {
  expirationDate: Date;
  description?: string;
  showMetadataFilters?: boolean;
  showFiles?: boolean;
  brandColor?: string;
  brandLogo?: string | null;
}

export type IPipelineUpdatePrototypeLinkData = Omit<IPipelinePrototypeLinkData, 'expirationDate'>;

export interface IPipelineLatestQueriesParams {
  limit?: number;
  before?: string;
  after?: number;
  filter?: string;
}

export interface IPipelineLogsParamsAPI {
  limit?: number;
  after?: string;
  filter?: string;
}

export interface IPipelineTemplatesAPIParams {
  limit?: number;
  page_number?: number;
  field?: string;
  order?: string;
  filter?: string;
  select?: string;
}

export interface IPipelineTemplateByNameParams {
  deepset_cloud_version?: string;
}

export interface IHaystackComponentInitParams {
  component_type: string;
  init_params: Record<string, unknown>;
  component_name?: string;
}

/* Get pipelines */
export function getPipelinesApi(
  params?: IAllPipelinesData,
  workspaceName: string = getWorkspace(),
) {
  // TODO: Apply to all other endpoints and probably autogenerate by key-value map
  const parameterMap = {
    limit: params?.limit || NUMBER_OF_FILES_PER_PAGE,
    ...(params?.name && { pipeline_name: params?.name }),
    ...(params?.after && { after: params?.after }),
    ...(params?.page_number && { page_number: params?.page_number }),
    ...(params?.desired_status && { desired_status: params?.desired_status }),
    ...(params?.status && { status: params?.status }),
    ...(params?.supports_prompt && { supports_prompt: params?.supports_prompt }),
    ...(params?.service_level && { service_level: params?.service_level }),
    ...(params?.field && { field: params?.field }),
    ...(params?.order && { order: params?.order }),
  };
  return http.get(`/api/v1/workspaces/${workspaceName}/pipelines`, { params: parameterMap });
}

/* Get pipeline */
export function getPipelineApi(pipelineName: string, workspaceName: string = getWorkspace()) {
  return http.get(`/api/v1/workspaces/${workspaceName}/pipelines/${pipelineName}`);
}

/* Get pipeline yaml */
export function getPipelineYamlApi(pipelineName: string, workspaceName: string = getWorkspace()) {
  return http.get(`/api/v1/workspaces/${workspaceName}/pipelines/${pipelineName}/yaml`);
}

/* Get pipeline indexing status */
export function getPipelineIndexing(pipelineName: string, workspaceName: string = getWorkspace()) {
  return http.get(`/api/v1/workspaces/${workspaceName}/pipelines/${pipelineName}/indexing`);
}

/* Create a new pipeline */
export function createPipelineApi(
  payload: {
    name: string;
    config?: string;
    deepset_cloud_version?: DeepsetCloudVersion;
    indexing_yaml?: string;
    query_yaml?: string;
  },
  params: Record<string, unknown> = {},
  workspaceName: string = getWorkspace(),
) {
  return http.post(`/api/v1/workspaces/${workspaceName}/pipelines`, payload, { params });
}

/* Update pipeline */
export function updatePipelineYamlApi(
  pipelineName: string,
  payload: string | { indexing_yaml: string; query_yaml: string },
  workspaceName: string = getWorkspace(),
) {
  return http.put(`/api/v1/workspaces/${workspaceName}/pipelines/${pipelineName}/yaml`, payload);
}

/* Update pipeline */
export function updatePipelineApi(
  pipelineName: string,
  payload: { name?: string; service_level?: string },
  workspaceName: string = getWorkspace(),
) {
  return http.patch(`/api/v1/workspaces/${workspaceName}/pipelines/${pipelineName}`, payload);
}

/* Delete pipeline */
export function deletePipelineApi(pipelineName: string, workspaceName: string = getWorkspace()) {
  return http.delete(`/api/v1/workspaces/${workspaceName}/pipelines/${pipelineName}`);
}

/* Get pipeline yaml */
export function deployPipelineYamlApi(
  pipelineName: string,
  workspaceName: string = getWorkspace(),
) {
  return http.post(`/api/v1/workspaces/${workspaceName}/pipelines/${pipelineName}/deploy`);
}

/* Get pipeline yaml */
export function undeployPipelineYamlApi(
  pipelineName: string,
  workspaceName: string = getWorkspace(),
) {
  return http.post(`/api/v1/workspaces/${workspaceName}/pipelines/${pipelineName}/undeploy`);
}

export function activatePipelineApi(pipelineName: string, workspaceName: string = getWorkspace()) {
  return http.post(`/api/v1/workspaces/${workspaceName}/pipelines/${pipelineName}/activate`);
}

export const getPipelineStatisticsApi = (
  pipelineName: string,
  workspaceName: string = getWorkspace(),
) => {
  return http.get(`/api/v1/workspaces/${workspaceName}/pipelines/${pipelineName}/stats`);
};

export const getPipelineQueryHistoryApi = <T>(
  pipelineName: string,
  params: IPipelineLatestQueriesParams,
  workspaceName: string = getWorkspace(),
) => {
  const parameterMap = {
    limit: params?.limit,
    ...(params?.after && { after: params.after }),
    ...(params?.filter && { filter: params.filter }),
  };
  return http.get<T>(
    `/api/v1/workspaces/${workspaceName}/pipelines/${pipelineName}/search_history`,
    {
      params: parameterMap,
      headers: {
        Accept: 'application/json',
      },
    },
  );
};

// TODO: Remove once we impliment infinite scrolling for prompt search results
export const getRecursivelyPipelineQueryHistoryApi = async (
  pipelineName: string,
  params: IPipelineLatestQueriesParams,
  workspaceName: string = getWorkspace(),
): Promise<ISearchHistory[]> => {
  const {
    data: { data, has_more: hasMore },
  } = await getPipelineQueryHistoryApi<IAPIPaginationData<ISearchHistory[]>>(
    pipelineName,
    params,
    workspaceName,
  );

  if (!hasMore || data.length === 0) return data;

  const nextOffset = params.after !== undefined ? params.after + data.length - 1 : data.length - 1;
  const nextParams = { ...params, after: nextOffset };

  return [
    ...data,
    ...(await getRecursivelyPipelineQueryHistoryApi(pipelineName, nextParams, workspaceName)),
  ];
};

/* Creates pipelines's shared prototype link */
export function generatePrototypeLinkApi(
  pipelineName: string,
  params: IPipelinePrototypeLinkData,
  workspaceName: string = getWorkspace(),
) {
  const data = {
    expiration_date: params.expirationDate.toISOString(),
    pipeline_names: [pipelineName],
    ...(params?.description && { description: params.description }),
    show_metadata_filters: !!params.showMetadataFilters,
    show_files: !!params.showFiles,
    ...(params?.brandColor && { brand_color: params.brandColor }),
    ...(params?.brandLogo && { brand_logo: params.brandLogo }),
  };
  return http.post(`/api/v1/workspaces/${workspaceName}/shared_prototypes`, data);
}

/* Get pipelines's shared prototype */
export const getPipelinePrototypeApi = (
  pipelineName: string,
  workspaceName: string = getWorkspace(),
) => {
  const params = {
    filter: `shared_pipelines/any(d:d/name eq '${pipelineName}')`,
  };
  return http.get(`/api/v1/workspaces/${workspaceName}/shared_prototypes`, { params });
};

/* Updates pipelines's shared prototype properties */
export const updatePipelinePrototypeApi = (
  sharedPrototypeId: string,
  params: IPipelineUpdatePrototypeLinkData,
  workspaceName: string = getWorkspace(),
) => {
  const payload = {
    description: params.description,
    show_metadata_filters: params.showMetadataFilters,
    show_files: params.showFiles,
    brand_color: params.brandColor,
    brand_logo: params.brandLogo,
  };
  return http.patch(
    `/api/v1/workspaces/${workspaceName}/shared_prototypes/${sharedPrototypeId}`,
    payload,
  );
};

/* Deletes shared prototype link */
export const deletePrototypeLinkApi = (
  sharedPrototypeId: string,
  workspaceName: string = getWorkspace(),
) => http.delete(`/api/v1/workspaces/${workspaceName}/shared_prototypes/${sharedPrototypeId}`);

export const getPipelinePromptsApi = (pipelineName: string, workspace: string = getWorkspace()) => {
  const paramsMap = {
    convert_special_tokens: true,
  };
  return http.get(`/api/v1/workspaces/${workspace}/pipelines/${pipelineName}/prompts`, {
    params: paramsMap,
  });
};

export const getPipelineLogsApi = (
  pipelineName: string,
  params: IPipelineLogsParamsAPI,
  workspaceName: string = getWorkspace(),
) => {
  const parameterMap = {
    limit: params?.limit || FETCH_PIPELINE_LOGS_LIMIT,
    ...(params?.after && { after: params?.after }),
    ...(params?.filter && { filter: params?.filter }),
  };

  return http.get(`/api/v1/workspaces/${workspaceName}/pipelines/${pipelineName}/logs`, {
    params: parameterMap,
  });
};

export const getPipelineLogsCSVApi = (
  pipelineName: string,
  workspaceName: string = getWorkspace(),
) => {
  return http.get(`/api/v1/workspaces/${workspaceName}/pipelines/${pipelineName}/logs`, {
    responseType: 'blob',
    headers: {
      Accept: MIMETypes.CSV,
    },
  });
};

// Templates

export const getPipelineTemplatesApi = <T>(
  params?: IPipelineTemplatesAPIParams,
  workspaceName: string = getWorkspace(),
) => {
  const mappedParams = {
    limit: params?.limit || DEFAULT_TEMPLATES_FETCH_LIMIT,
    ...(params?.page_number && { page_number: params.page_number }),
    ...(params?.field && { field: params.field }),
    ...(params?.order && { order: params.order }),
    ...(params?.filter && { filter: params.filter }),
    ...(params?.select && { select: params.select }),
  };

  return http.get<T>(`/api/v1/workspaces/${workspaceName}/pipeline_templates`, {
    params: mappedParams,
  });
};

export const getRecursivelyPipelineTemplatesApi = async <T extends unknown[]>(
  params?: IPipelineTemplatesAPIParams,
  apiFnCall = getPipelineTemplatesApi,
  workspaceName: string = getWorkspace(),
): Promise<T | unknown[]> => {
  const {
    data: { data, has_more: hasMore },
  } = await apiFnCall<IAPIPaginationData<T>>(params, workspaceName);

  if (!hasMore) return data;

  const nextPage = params?.page_number ? params.page_number + 1 : 2;
  const nextParams = { ...params, page_number: nextPage };

  return [
    ...data,
    ...(await getRecursivelyPipelineTemplatesApi(nextParams, apiFnCall, workspaceName)),
  ];
};

export const getPipelineTemplateApi = (
  templateName: string,
  workspaceName: string = getWorkspace(),
) => {
  return http.get(`/api/v1/workspaces/${workspaceName}/pipeline_templates/${templateName}`);
};

export const validatePipelineYamlApi = (
  payload: IValidatePipelineParams,
  workspace: string = getWorkspace(),
) => {
  return http.post(`/api/v1/workspaces/${workspace}/pipeline_validations`, payload);
};

export const getRuntimeIssueDetectionApi = (
  pipelineName: string,
  workspaceName: string = getWorkspace(),
) => {
  return http.get(`/api/v1/workspaces/${workspaceName}/pipelines/${pipelineName}/issues`);
};

export const getPipelineSchemaV2Api = () => {
  const isStudio = import.meta.env.VITE_IS_STUDIO === 'true';
  return http.get(`/api/v1/haystack/components${isStudio ? '?domain=community' : ''}`);
};

export const getPipelineSchemaV2InputOutputApi = () => {
  const isStudio = import.meta.env.VITE_IS_STUDIO === 'true';
  return http.get(`/api/v1/haystack/components/input-output${isStudio ? '?domain=community' : ''}`);
};

export const createPipelineComponentsDynamicIOApi = (
  dynamicIOBody: IHaystackComponentInitParams[],
) => {
  return http.post(`/api/v1/haystack/components/input-output/generate`, dynamicIOBody);
};
