import { isString } from 'lodash';
import { ExtendedUploadFile } from '@redux/types/types';

/**
 * Converts a long string of bytes into a readable format e.g KB, MB, GB, TB, YB
 *
 * @param {Int} num The number of bytes.
 */
export const readableBytes = (num: number) => {
  let localNum = num;
  const neg = localNum < 0;
  const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  if (neg) {
    localNum = -localNum;
  }
  if (localNum < 1) {
    return `${(neg ? '-' : '') + localNum} B`;
  }
  const exponent = Math.min(Math.floor(Math.log(localNum) / Math.log(1000)), units.length - 1);
  localNum = Number((localNum / 1000 ** exponent).toFixed(2));
  const unit = units[exponent];
  return `${(neg ? '-' : '') + localNum} ${unit}`;
};

/* transfroms date for chart eg. 08-04-2020 -> 4/08 */
export function transformDateFormat(date: string) {
  /* remove year and change month and year places */
  const result = date.split('-').slice(0, 2).reverse();
  /* remove leading 0 in day */
  result[0] = result[0].replace(/^0+/, '');
  /* add slash separator */
  return result.join('/');
}

export const getFileExtension = (file: File | string) => {
  const fileName = isString(file) ? file : file.name;
  const fileSplitByDot = fileName.split('.');
  const fileExtension = fileSplitByDot[fileSplitByDot.length - 1];
  return fileExtension;
};

export const checkFileLimit = (file: File | ExtendedUploadFile, limit: number) => {
  if (file.size) {
    const mainSize = file.size / 1024 / 1024;
    return mainSize < limit;
  }
  return false;
};
