import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  DEFAULT_PAGE_NUMBER_PIPELINES_LIST,
  PAGE_SIZE_PIPELINES_LIST,
  PIPELINES_LIST_DEFAULT_SORTING_KEY,
  PIPELINES_LIST_DEFAULT_SORTING_KEY_ALL_TAB,
} from '@constants/pipelines';
import {
  deleteMultiplePipelines as deleteMultiplePipelinesAction,
  fetchPipelines,
  selectSortValuePipelinesList,
  setActiveTabPipelinesList as setActiveTabPipelinesListAction,
  startPollingPipelinesList as startPollingPipelinesListAction,
  stopPollingPipelinesList as stopPollingPipelinesListAction,
} from '@redux/actions/pipelineActions';
import {
  activeTabPipelinesLandingPageSelector,
  pipelinesSelector,
  sortValuePipelinesListSelector,
} from '@redux/selectors/pipelineSelectors';
import { PipelineServiceLevel } from '@redux/types/types';

const usePipelines = () => {
  const dispatch = useDispatch();
  const { data: pipelinesList, total: totalPipelines }: any = useSelector((state) =>
    pipelinesSelector(state),
  );
  const activeTabPipelinesLandingPage = useSelector(activeTabPipelinesLandingPageSelector);
  const sortValuePipelinesList = useSelector(sortValuePipelinesListSelector);
  const [isGettingPipelinesList, setIsGettingPipelinesList] = useState(false);

  const getSortValue = (sortValue: string | undefined) => {
    if (activeTabPipelinesLandingPage?.length === 0 && sortValue === undefined)
      return PIPELINES_LIST_DEFAULT_SORTING_KEY_ALL_TAB;
    if (sortValue === undefined) return PIPELINES_LIST_DEFAULT_SORTING_KEY;
    return sortValue;
  };

  const getPipelinesList = async (
    currentPage: number,
    pageSize: number,
    searchValue: string,
    sortValue?: string,
    serviceLevel?: PipelineServiceLevel,
  ) => {
    setIsGettingPipelinesList(true);
    await dispatch(
      fetchPipelines({
        currentPage,
        pageSize,
        searchValue,
        sortValue: await getSortValue(sortValue),
        serviceLevel: serviceLevel || activeTabPipelinesLandingPage,
      }),
    );
    setIsGettingPipelinesList(false);
  };

  const startPollingPipelinesList = (
    currentPage: number,
    pageSize: number,
    searchValue: string,
  ) => {
    dispatch(startPollingPipelinesListAction(currentPage, pageSize, searchValue, 10000));
  };

  const stopPollingPipelinesList = () => {
    dispatch(stopPollingPipelinesListAction());
  };

  const deleteMultiplePipelines = (pipelineNames: string[]) => {
    dispatch(deleteMultiplePipelinesAction(pipelineNames));
  };

  const setActiveTabPipelinesList = (tab: string) => {
    dispatch(setActiveTabPipelinesListAction(tab));
  };

  useEffect(() => {
    if (activeTabPipelinesLandingPage?.length === 0)
      dispatch(selectSortValuePipelinesList(PIPELINES_LIST_DEFAULT_SORTING_KEY_ALL_TAB));
    else dispatch(selectSortValuePipelinesList(PIPELINES_LIST_DEFAULT_SORTING_KEY));
    getPipelinesList(DEFAULT_PAGE_NUMBER_PIPELINES_LIST, PAGE_SIZE_PIPELINES_LIST, '');
  }, [activeTabPipelinesLandingPage]);

  return {
    pipelinesList,
    totalPipelines,
    getPipelinesList,
    startPollingPipelinesList,
    stopPollingPipelinesList,
    deleteMultiplePipelines,
    activeTabPipelinesLandingPage,
    setActiveTabPipelinesList,
    isGettingPipelinesList,
    sortValuePipelinesList,
  };
};

export default usePipelines;
