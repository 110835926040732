import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UserAddOutlined, UserDeleteOutlined, UserSwitchOutlined } from '@ant-design/icons';
import { Button, Dropdown, Pagination, Popconfirm, Tooltip } from 'antd';

import { PEOPLE_BLOCK } from '@constants/common';
import { UserRoles, UserRolesMap } from '@constants/enum/common';
import {
  changeUserRole,
  fetchUsers,
  removeUser,
  resetMessage,
} from '@redux/actions/organizationActions';
import { organizationUsersSelector } from '@redux/selectors/organizationSelectors';
import { userSelector } from '@redux/selectors/userSelectors';
import { IUserData } from '@redux/types/types';
import InvitePeopleModal from './InvitePeopleModal';
import styles from './peopleBlock.module.scss';
import ContentCenter from '../ContentCenter/ContentCenter';

export interface IPeopleBlockProps {
  withInvite?: boolean;
  withChange?: boolean;
}

const PeopleBlock = (props: IPeopleBlockProps) => {
  const dispatch = useDispatch();
  const { withInvite = false, withChange = false } = props;
  const { users, total } = useSelector(organizationUsersSelector);
  const { userID }: IUserData = useSelector(userSelector);

  const [inviteModalOpened, setInviteModalOpened] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);

  useEffect(() => {
    dispatch(resetMessage);
    dispatch(fetchUsers(currentPage));
  }, [dispatch, currentPage]);

  const handleInviteModalOpen = () => {
    setInviteModalOpened(true);
  };

  const handleInviteModalClose = async () => {
    setInviteModalOpened(false);
    dispatch(fetchUsers(currentPage));
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  function handleInviteUser() {
    dispatch(fetchUsers(currentPage));
  }

  async function handleRemovePerson(userId: string) {
    await dispatch(removeUser(userId));
    dispatch(fetchUsers(currentPage));
  }

  function handleChangeClick() {
    setIsEditing((prev) => !prev);
  }

  function renderPagination() {
    return (
      <Pagination
        current={currentPage}
        total={total}
        defaultPageSize={PEOPLE_BLOCK.USERS_PER_PAGE}
        showSizeChanger={false}
        onChange={(page) => handlePageChange(page)}
        className={styles.peopleBlock_pagination}
      />
    );
  }

  async function handleMenuClick(key: UserRoles, userId: string) {
    await dispatch(changeUserRole({ userId, role: key }));
    dispatch(fetchUsers(currentPage));
  }

  const getUserRolesDropdownItems = () =>
    Object.entries(UserRolesMap).map(([key, value]) => ({
      key,
      label: value,
    }));

  function renderUsersList() {
    return users.map((person: IUserData) => {
      const { userID: personUserID, email, role, firstName, lastName } = person;

      return (
        <div className={styles.personRow} key={personUserID} data-testid={`personRow_${email}`}>
          <div>
            <div>
              {`${firstName || ''} ${lastName || ''}`}
              <strong>{userID === personUserID && ` (${PEOPLE_BLOCK.YOU_LABEL})`}</strong>
            </div>
            <div>
              <small> {email} </small>
            </div>
          </div>
          <div className={styles.personRow_options}>
            {UserRolesMap[role]}
            <Tooltip placement="bottom" title={PEOPLE_BLOCK.CHANGE_USER_ROLE_TOOLTIP}>
              <Dropdown
                menu={{
                  items: getUserRolesDropdownItems(),
                  onClick: ({ key }) => handleMenuClick(key as UserRoles, personUserID),
                }}
                placement="topRight"
                trigger={['click']}
              >
                <Button
                  type="link"
                  size="small"
                  icon={<UserSwitchOutlined />}
                  className={styles.personRow_options_button}
                />
              </Dropdown>
            </Tooltip>
            <Tooltip placement="bottom" title={PEOPLE_BLOCK.REMOVE_USER_TOOLTIP}>
              <Popconfirm
                title={PEOPLE_BLOCK.REMOVE_USER_CONFIRMATION_MESSAGE}
                placement="topRight"
                okText={PEOPLE_BLOCK.CONFIRM_BUTTON_LABEL}
                cancelText={PEOPLE_BLOCK.CANCEL_BUTTON_LABEL}
                onConfirm={() => handleRemovePerson(personUserID)}
              >
                <Button
                  type="link"
                  size="small"
                  danger
                  icon={<UserDeleteOutlined />}
                  data-testid="deleteUser_button"
                  className={styles.personRow_options_button}
                />
              </Popconfirm>
            </Tooltip>
          </div>
        </div>
      );
    });
  }

  const inviteModalProps = {
    onInviteUser: handleInviteUser,
    visible: inviteModalOpened,
    onClose: handleInviteModalClose,
    onSubmit: handleInviteModalOpen,
  };

  function renderHeader() {
    return (
      <div className={styles.peopleTitle}>
        <div className={styles.peopleTitleLabel}>
          {PEOPLE_BLOCK.TITLE} ({total})
        </div>
        <div>
          {withChange && (
            <Button
              type="primary"
              icon={<UserSwitchOutlined />}
              onClick={() => handleChangeClick()}
            >
              {isEditing ? PEOPLE_BLOCK.CLOSE_LABEL : PEOPLE_BLOCK.CHANGE_LABEL}
            </Button>
          )}
          {withInvite && (
            <Button
              type="primary"
              icon={<UserAddOutlined />}
              onClick={() => handleInviteModalOpen()}
              data-testid="inviteUserModal_button"
            >
              {PEOPLE_BLOCK.INVITE_USERS_LABEL}
            </Button>
          )}
        </div>
      </div>
    );
  }

  return (
    <ContentCenter>
      {renderHeader()}
      <div data-testid="userList">{renderUsersList()}</div>
      <InvitePeopleModal {...inviteModalProps} />
      {renderPagination()}
    </ContentCenter>
  );
};

export default PeopleBlock;
