import produce from 'immer';
import {
  FULFILLED,
  GET_METADATA_VALUES,
  GET_PIPELINE_META,
  IMessage,
  MetadataFiltersType,
  REJECTED,
  SELECT_METADATA_FILTER_VALUES,
  SET_APPLIED_METADATA_FILTER_VALUES,
} from '@redux/types/types';
import { HTTPStatusCode, MessageCodes, StatusCodes } from '@src/constants/enum/common';
import { normalizeErrorMessage } from '@src/utils/error';

interface IInitialStateProps {
  pipelineMeta: Record<string, { type: string }>;
  pipelineMetaValues: Record<string, MetadataFiltersType>;
  selectedMetaFilterValues: Record<string, MetadataFiltersType>;
  appliedMetaFilterValues: Record<string, MetadataFiltersType>;
  message: IMessage;
  status: StatusCodes;
}

export const initialState: IInitialStateProps = {
  pipelineMeta: {},
  pipelineMetaValues: {},
  selectedMetaFilterValues: {},
  appliedMetaFilterValues: {},
  message: {
    type: MessageCodes.INFO,
    content: '',
    status: HTTPStatusCode.OK,
  },
  status: StatusCodes.IDLE,
};

function metadataFiltersReducer(state = initialState, action: any) {
  return produce(state, (draft) => {
    const localDraft = draft;

    switch (action.type) {
      case `${GET_PIPELINE_META}/${FULFILLED}`:
        localDraft.message = initialState.message;
        localDraft.pipelineMeta = action.payload;
        break;
      case `${GET_METADATA_VALUES}/${FULFILLED}`:
        localDraft.message = initialState.message;
        localDraft.pipelineMetaValues = { ...localDraft.pipelineMetaValues, ...action.payload };
        break;
      case `${GET_PIPELINE_META}/${REJECTED}`:
      case `${GET_METADATA_VALUES}/${REJECTED}`:
        localDraft.status = StatusCodes.ERROR;
        localDraft.message = {
          type: MessageCodes.ERROR,
          content: normalizeErrorMessage(action.payload),
          status: action.payload?.response?.status,
        };
        break;
      case SELECT_METADATA_FILTER_VALUES:
        localDraft.selectedMetaFilterValues = action.payload;
        break;
      case SET_APPLIED_METADATA_FILTER_VALUES:
        localDraft.appliedMetaFilterValues = action.payload;
        break;
      default:
        break;
    }

    return localDraft;
  });
}

export default metadataFiltersReducer;
