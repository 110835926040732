import React, { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  FileTextFilled,
  MoreOutlined,
} from '@ant-design/icons';
import { Button, Collapse, Divider, Drawer, Dropdown, Tabs, Tooltip } from 'antd';
import dayjs from 'dayjs';
import { groupBy, isEmpty } from 'lodash';
import { getFileExtension } from '@utils/common';
import { parseFileContentToViewer } from '@utils/file';
import { formatNumberToLocaleString } from '@utils/math';
import {
  getResultsByType,
  getSearchResultPipelineType,
  groupParsedDuplicateReferences,
} from '@utils/search';
import { interpolateString } from '@utils/string';
import { getFileMetaApi, getFilePreviewBlobByTypeApi } from '@api/data';
import ArrowUpDownSVG from '@assets/arrow-up-down.svg?react';
import useFilePreviewFormat from '@hooks/useFilePreviewFormat';
import { useUserEvent } from '@hooks/useUserEvent';
import {
  FILE_DRAWER_COLLAPSE_TABS,
  RAW_FORMAT_LABEL,
  REFERENCE_LABEL,
  VIEW_METADATA_BUTTON_LABEL,
} from '@constants/common';
import { UploadFileType } from '@constants/constant';
import { StatusCodes, SupportedViewerLanguage } from '@constants/enum/common';
import { EventControlComponent, EventControlElement, UserEventType } from '@constants/event';
import {
  COLUMN_TITLES,
  DONE_BUTTON_LABEL,
  FEEDBACK_CAROUSEL_LIST_OPTIONS_KEYS,
  FILTERS_SECTION_TITLE,
  NEXT_QUERY_BUTTON_LABEL,
  PREV_QUERY_BUTTON_LABEL,
} from '@constants/pipeline-feedback';
import { PipelineType } from '@constants/pipelines';
import {
  ANSWER_TITLE,
  MORE_OPTIONS_VIEW_FILTERS_LABEL,
  MORE_OPTIONS_VIEW_PROMPT_LABEL,
  SCROLL_TO_REFERENCE_TITLE,
  SOURCES_FILE_LABEL,
} from '@constants/search';
import {
  FeedbackType,
  FileDrawerCollapseTabOptions,
  IFilePreview,
  IHighlightData,
  ISearchResult,
  ISearchResultAnswer,
  ISearchResultDocument,
  ITag,
  SearchResultMoreOption,
} from '@redux/types/types';
import DisplayDataModal from '@components/common/displayDataModal/DisplayDataModal';
import ListCarousel from '@components/common/ListCarousel/ListCarousel';
import LoadingIndicator from '@components/common/LoadingIndicator/LoadingIndicator';
import SwitchWithText from '@components/common/switchWithText/SwitchWithText';
import HighlightContent from '@components/highlightContent/HighlightContent';
import MarkdownViewer from '@components/MarkdownViewer/MarkdownViewer';
import MetadataModal from '@components/metadataModal/MetadataModal';
import PipelineFeedbackRatingTag from '@components/PipelineFeedbackRatingTag/PipelineFeedbackRatingTag';
import PromptModal from '@components/search/features/PromptModal/PromptModal';
import usePromptHook from '@components/search/features/PromptModal/usePromptHook';
import useReferences from '@components/search/hooks/useReferences';
import ResultContainer from '@components/search/organisms/result/ResultContainer';
import styles from './detailedQueryView.module.scss';

const PDFHighlighter = React.lazy(
  () => import(/* webpackChunkName: "PDFHighlighter" */ '@components/pdf/PDFHighligther'),
);
const CodeViewer = React.lazy(
  () => import(/* webpackChunkName: "CodeViewer" */ '@components/codeViewer/CodeViewer'),
);

const { Panel } = Collapse;

type FeedbackData = {
  score: FeedbackType;
  tags: ITag[];
};

interface IDetailedQueryView {
  searchResult: ISearchResult;
  feedbackData?: FeedbackData | null;
  extraQueryInfo?: {
    user: { given_name: string; family_name: string } | null;
    duration: number;
    createdAt: string;
    rank?: number | null;
    filters: Record<string, unknown> | null;
  } | null;
  pipelineName: string;
  pipelineId: string;
  isVisible: boolean;
  isPreviousItemButtonDisabled: boolean;
  isNextItemButtonDisabled: boolean;
  parsedSearchResult?: ISearchResult;
  onPreviousClick: () => void;
  onClose: () => void;
  onNextClick: () => void;
}

const DetailedQueryView = ({
  searchResult,
  extraQueryInfo,
  feedbackData,
  pipelineName,
  pipelineId,
  isVisible,
  isPreviousItemButtonDisabled,
  isNextItemButtonDisabled,
  onClose,
  onPreviousClick,
  onNextClick,
}: IDetailedQueryView) => {
  const { openPromptModal } = usePromptHook();
  const { getSearchResultReferences, activeReference, resetReferenceDrawer } = useReferences();
  const { trackUserEvent, setEventProperties } = useUserEvent();

  const [isMetaModalVisible, setIsMetaModalVisible] = useState(false);
  const [currentModalMetaData, setCurrentModalMetaData] = useState({});
  const [isFiltersModalVisible, setIsFiltersModalVisible] = useState(false);
  const [currentActiveCollapseTab, setCurrentActiveCollapseTab] = useState<
    FileDrawerCollapseTabOptions | string
  >(FILE_DRAWER_COLLAPSE_TABS[1].key);
  const [selectedFileID, setSelectedFileID] = useState('');
  const [loadingFileContent, setLoadingFileContent] = useState<boolean>(false);
  const [filePreview, setFileContent] = useState<IFilePreview>();
  const { supportedRawFilePreviewLanguagues, showRawCodeFormat, setShowRawCodeFormat } =
    useFilePreviewFormat();
  const [documentsGroupedByFileId, setDocumentsGroupedByFileId] = useState(
    groupBy(searchResult.documents, 'file.id'),
  );
  const highlightContentRefs = useRef<React.ElementRef<typeof HighlightContent>[]>([]);

  const pipelineType = getSearchResultPipelineType(searchResult);

  useEffect(() => {
    if (searchResult) {
      const [result] = getResultsByType(searchResult);
      setEventProperties({ result_id: result.result_id, pipeline_name: pipelineName });
    }
  }, [searchResult]);

  const getGroupedDocumentsFileName = (id: string) => documentsGroupedByFileId[id][0]?.file?.name;

  const getFileContentById = async (fileId: string) => {
    try {
      setLoadingFileContent(true);
      const fileName = getGroupedDocumentsFileName(fileId);
      const fileType = getFileExtension(fileName) as UploadFileType;
      const { data } = await getFilePreviewBlobByTypeApi(fileId, fileType);
      const { data: metaData } = await getFileMetaApi(fileId);

      const parsedFileContent = await parseFileContentToViewer(data);

      setFileContent(parsedFileContent);
      setCurrentModalMetaData({ ...metaData, file_id: fileId });
      setLoadingFileContent(false);
    } catch {
      setLoadingFileContent(false);
    }
  };

  useEffect(() => {
    if (activeReference?.referenceId) {
      const resultReferences = getSearchResultReferences(searchResult, activeReference.resultId);
      const [, referenceIdx] = activeReference.referenceId.split('_');

      if (!resultReferences || !referenceIdx || !resultReferences[Number(referenceIdx)]) return;

      const { file_id: refFileId } = resultReferences[Number(referenceIdx)];
      setCurrentActiveCollapseTab(FileDrawerCollapseTabOptions.DOCUMENT);
      setSelectedFileID(refFileId);
    }
  }, [activeReference, activeReference?.referenceId]);

  useEffect(() => {
    if (currentActiveCollapseTab === FileDrawerCollapseTabOptions.FILE && selectedFileID)
      getFileContentById(selectedFileID);
  }, [currentActiveCollapseTab, selectedFileID]);

  useEffect(() => {
    setDocumentsGroupedByFileId(groupBy(searchResult.documents, 'file.id'));
  }, [searchResult.query_id]);

  const getDocuments = (
    result: ISearchResultAnswer | ISearchResultDocument,
  ): ISearchResultDocument[] => {
    const { documents: parsedDocuments = [] } = searchResult!;
    if (pipelineType === PipelineType.DOCUMENT_RETRIEVAL)
      return [result] as ISearchResultDocument[];
    if (!parsedDocuments.length) return parsedDocuments;

    if (!('document_ids' in result) || !result?.document_ids) return [];

    return result.document_ids.reduce((acc: ISearchResultDocument[], id: string) => {
      const document = parsedDocuments.find((doc: { id: string }) => doc.id === id);
      if (document) return [...acc, document];
      return acc;
    }, []);
  };

  const onFileCollapseChange = (key: string[]) => {
    const [fileId] = key;
    setSelectedFileID(fileId || '');
  };

  const onMoreOptionsDropdownMenuItemClick = ({ key }: { key: string }) => {
    const [{ prompt }] = getResultsByType(searchResult) as ISearchResultAnswer[];

    if (key === SearchResultMoreOption.VIEW_PROMPT && prompt) openPromptModal({ prompt });
    if (key === SearchResultMoreOption.VIEW_FILTERS) setIsFiltersModalVisible(true);
  };

  const closeDrawer = () => {
    resetReferenceDrawer();
    onClose();
  };

  // Highlights

  const getContentHighlightDataById = (
    highlightData: IHighlightData[] | null,
    contentId?: string,
  ) => {
    if (!contentId) return highlightData;
    return highlightData?.filter((data) => data.contentId === contentId) ?? null;
  };

  const getFileReferencesHighlightData = (): IHighlightData[] | null => {
    if (!searchResult) return null;
    const { answers } = searchResult;
    const [firstAnswer] = answers;

    const references = getSearchResultReferences(searchResult, firstAnswer.result_id);
    if (!references?.length) return null;

    const groupedDuplicates = groupParsedDuplicateReferences(references, REFERENCE_LABEL);

    return groupedDuplicates.map((ref) => {
      const referenceText = ref.content.slice(ref.doc_start_idx, ref.doc_end_idx);

      return {
        offsetsInDocument: null,
        answer: referenceText,
        context: ref.content,
        label: ref.highlightLabel,
        contentId: ref.file_id,
      };
    });
  };

  const getDocumentReferencesHighlightData = (): IHighlightData[] | null => {
    if (!searchResult) return null;
    const { answers } = searchResult;
    const [firstAnswer] = answers;

    const references = getSearchResultReferences(searchResult, firstAnswer.result_id);
    if (!references?.length) return null;

    const groupedDuplicates = groupParsedDuplicateReferences(references, REFERENCE_LABEL);

    return groupedDuplicates.map((ref) => {
      const referenceText = ref.content.slice(ref.doc_start_idx, ref.doc_end_idx);
      const offsetsInDocumentValue = [
        {
          start: ref.doc_start_idx,
          end: ref.doc_end_idx,
        },
      ];

      return {
        offsetsInDocument: offsetsInDocumentValue,
        answer: referenceText,
        context: null,
        label: ref.highlightLabel,
        contentId: ref.document_id,
      };
    });
  };

  const getFileHighlightData = (): IHighlightData[] | null => {
    if (pipelineType === PipelineType.GENERATIVE_QUESTION_ANSWERING)
      return getFileReferencesHighlightData();

    return null;
  };

  const getDocumentsHighlightData = (): IHighlightData[] | null => {
    if (pipelineType === PipelineType.GENERATIVE_QUESTION_ANSWERING)
      return getDocumentReferencesHighlightData();

    return null;
  };

  const showRawCodeFormatSwitch = () => {
    if (loadingFileContent || !filePreview?.displayLanguage) return false;
    return supportedRawFilePreviewLanguagues.includes(filePreview.displayLanguage);
  };

  const showScrollButton = () => {
    if (loadingFileContent || !filePreview) return false;
    const isArrayBufferType = filePreview.content instanceof ArrayBuffer;

    const contentHighlightData = getContentHighlightDataById(
      getFileHighlightData(),
      selectedFileID,
    );

    return !!contentHighlightData?.length && !isArrayBufferType;
  };

  const getFeedbackOptions = () =>
    feedbackData
      ? Object.entries(feedbackData).reduce(
          (acc: { key: string; label: string; value: ReactNode | string }[], [key, value]) => {
            if (key === FEEDBACK_CAROUSEL_LIST_OPTIONS_KEYS.FEEDBACK_TYPE)
              return [
                ...acc,
                {
                  key,
                  label: COLUMN_TITLES.FEEDBACK_RATING,
                  value: <PipelineFeedbackRatingTag type={value as FeedbackType} />,
                },
              ];

            return acc;
          },
          [],
        )
      : [];

  const getCarouselOptions = () => {
    const { query_id: queryId } = searchResult;
    const { user, duration, createdAt, rank } = extraQueryInfo || {};
    const { family_name: familyName, given_name: givenName } = user || {};

    return [
      ...(rank
        ? [
            {
              key: FEEDBACK_CAROUSEL_LIST_OPTIONS_KEYS.RANK,
              value: rank,
              label: COLUMN_TITLES.RANK,
            },
          ]
        : []),
      ...(user
        ? [
            {
              key: FEEDBACK_CAROUSEL_LIST_OPTIONS_KEYS.QUERY_BY,
              value: `${givenName} ${familyName}`,
              label: COLUMN_TITLES.USER,
            },
          ]
        : []),
      ...getFeedbackOptions(),
      {
        key: FEEDBACK_CAROUSEL_LIST_OPTIONS_KEYS.QUERY_ID,
        value: queryId,
        label: COLUMN_TITLES.QUERY_ID,
      },
      ...(duration
        ? [
            {
              key: FEEDBACK_CAROUSEL_LIST_OPTIONS_KEYS.DURATION,
              value: `${formatNumberToLocaleString(duration)}s`,
              label: COLUMN_TITLES.DURATION,
            },
          ]
        : []),
      ...(createdAt
        ? [
            {
              key: FEEDBACK_CAROUSEL_LIST_OPTIONS_KEYS.QUERY_CREATED,
              value: dayjs(createdAt).format('DD/MM/YYYY HH:mm'),
              label: COLUMN_TITLES.QUERY_DATE,
            },
          ]
        : []),
      {
        key: FEEDBACK_CAROUSEL_LIST_OPTIONS_KEYS.FILE_ID,
        value: selectedFileID,
        label: COLUMN_TITLES.FILE_ID,
      },
    ];
  };

  const renderPDFContent = useMemo(() => {
    if (!filePreview || !(filePreview.content instanceof ArrayBuffer)) return null;
    const { content } = filePreview;
    return (
      <React.Suspense fallback={<LoadingIndicator />}>
        <PDFHighlighter pdfData={content} />
      </React.Suspense>
    );
  }, [filePreview]);

  const renderMarkdownContent = (content: string) => {
    return <MarkdownViewer>{content}</MarkdownViewer>;
  };

  const renderCodeContent = (content: string, codeLanguage: SupportedViewerLanguage) => {
    return (
      <div className={styles.documentsPreview_content}>
        <React.Suspense fallback={<LoadingIndicator />}>
          <CodeViewer code={content} codeLanguage={codeLanguage} />
        </React.Suspense>
      </div>
    );
  };

  const renderTextContent = (
    content: string,
    highlights: IHighlightData[] | null,
    itemIdx: number,
  ) => {
    if (highlights)
      return (
        <HighlightContent
          content={content}
          highlightData={highlights}
          ref={(ref) => {
            if (ref && highlightContentRefs.current) {
              highlightContentRefs.current[itemIdx] = ref;
            }
          }}
        />
      );

    return (
      <div className={styles.textContent_container}>
        <pre>{content}</pre>
      </div>
    );
  };

  const renderFileDocument = (fileDocuments: ISearchResultDocument[], itemIdx: number) => {
    if (!fileDocuments.length) return null;

    return fileDocuments.map(({ content, id: documentId, meta }, idx) => (
      <div key={documentId} className={styles.fileDocument_container}>
        <div>
          {/* TODO: Create a container component for document content, and reuse it in the document preview drawer https://github.com/deepset-ai/haystack-hub-ui/issues/2437 */}
          {renderTextContent(
            content,
            getContentHighlightDataById(getDocumentsHighlightData(), documentId),
            itemIdx,
          )}
          <Button
            size="small"
            className={styles.viewMetadata_button}
            onClick={() => {
              setIsMetaModalVisible(true);
              setCurrentModalMetaData(meta);
            }}
          >
            {VIEW_METADATA_BUTTON_LABEL}
          </Button>
        </div>
        {idx !== fileDocuments.length - 1 && <Divider />}
      </div>
    ));
  };

  // TODO: Create a component for displaying file content + with highlight logic https://github.com/deepset-ai/haystack-hub-ui/issues/2438
  const renderFile = (itemIdx: number) => {
    if (!selectedFileID) return null;
    if (loadingFileContent || !filePreview) return <LoadingIndicator />;

    const { content, displayLanguage } = filePreview;
    const isArrayBufferType = content instanceof ArrayBuffer;

    if (isArrayBufferType) return renderPDFContent;

    const contentHighlightData = getContentHighlightDataById(
      getFileHighlightData(),
      selectedFileID,
    );

    if (displayLanguage === SupportedViewerLanguage.PLAIN_TEXT)
      return renderTextContent(content, contentHighlightData, itemIdx);

    if (displayLanguage === SupportedViewerLanguage.MARKDOWN && !showRawCodeFormat)
      return renderMarkdownContent(content);

    return renderCodeContent(content, displayLanguage || SupportedViewerLanguage.PLAIN_TEXT);
  };

  const renderResultMoreOptions = () => {
    const [{ prompt }] = getResultsByType(searchResult) as ISearchResultAnswer[];
    const { filters } = extraQueryInfo || {};

    if (!prompt && isEmpty(filters ?? {})) return null;

    const items = [
      ...(prompt
        ? [
            {
              key: SearchResultMoreOption.VIEW_PROMPT,
              label: MORE_OPTIONS_VIEW_PROMPT_LABEL,
            },
          ]
        : []),
      ...(!isEmpty(filters ?? {})
        ? [
            {
              key: SearchResultMoreOption.VIEW_FILTERS,
              label: MORE_OPTIONS_VIEW_FILTERS_LABEL,
            },
          ]
        : []),
    ];

    return (
      <Dropdown
        menu={{
          items,
          onClick: onMoreOptionsDropdownMenuItemClick,
        }}
        trigger={['click']}
      >
        <Button type="text" onClick={(e) => e.preventDefault()} icon={<MoreOutlined />} />
      </Dropdown>
    );
  };

  const renderResult = () => {
    // We only display one results at the time
    const [result] = getResultsByType(searchResult);
    return (
      <div className={styles.section}>
        <div className={styles.section_subheader}>
          <h5>{ANSWER_TITLE}</h5>
          {renderResultMoreOptions()}
        </div>
        <div className={styles.answerContainer}>
          <ResultContainer
            searchStatus={StatusCodes.IDLE}
            searchResult={result}
            documents={getDocuments(result)}
            pipelineId={pipelineId}
            queryId={searchResult.query_id || ''}
            pipelineName={pipelineName}
            withGenerativeTypingEffect={false}
            pipelineType={pipelineType}
            displayFeedbackOptions={false}
            documentResultOptions={{
              displayFullDocument: true,
            }}
            displayFileOptions
            displayFileSources={false}
            displayMoreOptions={false}
            displayReferencesPopover={false}
          />
        </div>
      </div>
    );
  };

  const renderFilesCollapse = () => {
    if (isEmpty(documentsGroupedByFileId)) return null;

    return (
      <div className={styles.section}>
        <div className={styles.section_header}>
          <h5>{SOURCES_FILE_LABEL}</h5>
        </div>
        <Collapse
          className={styles.filesCollapse_container}
          accordion
          activeKey={selectedFileID}
          onChange={(key) => onFileCollapseChange(key as string[])}
          expandIconPosition="end"
        >
          {Object.keys(documentsGroupedByFileId).map((id, idx) => (
            <Panel
              header={
                <div className={styles.filesCollapse_container_panel_title}>
                  <FileTextFilled />
                  <Tooltip title={getGroupedDocumentsFileName(id)}>
                    {getGroupedDocumentsFileName(id)}
                  </Tooltip>
                </div>
              }
              key={id}
            >
              <div className={styles.filesCollapse_container_panel_header}>
                <Tabs
                  activeKey={currentActiveCollapseTab}
                  items={FILE_DRAWER_COLLAPSE_TABS}
                  onChange={(activeKey) => {
                    setCurrentActiveCollapseTab(activeKey);
                    setSelectedFileID(id);

                    trackUserEvent({
                      type: UserEventType.CLICK,
                      control: `${EventControlComponent.QUERY_DETAILED_DRAWER}/${EventControlElement.TAB}`,
                      properties: {
                        tab: activeKey,
                        file_id: id,
                      },
                    });
                  }}
                />
                {currentActiveCollapseTab === FileDrawerCollapseTabOptions.FILE && (
                  <div className={styles.buttonsWrapper}>
                    {showRawCodeFormatSwitch() && (
                      <SwitchWithText
                        text={interpolateString(RAW_FORMAT_LABEL, {
                          format: filePreview?.displayLanguage || '',
                        })}
                        value={showRawCodeFormat}
                        onSwitchChange={setShowRawCodeFormat}
                      />
                    )}
                    <Button
                      size="small"
                      className={styles.viewMetadata_button}
                      onClick={() => {
                        setIsMetaModalVisible(true);
                      }}
                    >
                      {VIEW_METADATA_BUTTON_LABEL}
                    </Button>
                    {showScrollButton() && (
                      <Tooltip title={SCROLL_TO_REFERENCE_TITLE}>
                        <Button
                          size="small"
                          icon={<ArrowUpDownSVG />}
                          onClick={() => highlightContentRefs?.current?.[idx]?.scrollToHighlight()}
                        />
                      </Tooltip>
                    )}
                  </div>
                )}
              </div>
              {currentActiveCollapseTab === FileDrawerCollapseTabOptions.FILE ? (
                <div className={styles.file_container}>{renderFile(idx)}</div>
              ) : (
                renderFileDocument(documentsGroupedByFileId[id], idx)
              )}
            </Panel>
          ))}
        </Collapse>
      </div>
    );
  };

  const renderMetaDataModal = () => {
    return (
      <MetadataModal
        data={currentModalMetaData}
        nonEditableFields={['file_id']}
        open={isMetaModalVisible}
        onCancel={() => setIsMetaModalVisible(false)}
        onOk={() => setIsMetaModalVisible(false)}
      />
    );
  };

  const renderFiltersDataModal = () => {
    const { filters } = extraQueryInfo || {};
    return (
      <DisplayDataModal
        title={FILTERS_SECTION_TITLE}
        open={isFiltersModalVisible}
        onCancel={() => setIsFiltersModalVisible(false)}
        onOk={() => setIsFiltersModalVisible(false)}
      >
        <code>{JSON.stringify(filters, null, 2)}</code>
      </DisplayDataModal>
    );
  };

  return (
    <>
      <Drawer
        rootClassName={styles.detailedQueryView}
        open={isVisible}
        title={searchResult.query}
        size="large"
        onClose={closeDrawer}
        extra={
          <Button onClick={onClose} type="primary">
            {DONE_BUTTON_LABEL}
          </Button>
        }
        footer={
          <div className={styles.footer}>
            <Button disabled={isPreviousItemButtonDisabled} onClick={onPreviousClick}>
              <ArrowLeftOutlined />
              {PREV_QUERY_BUTTON_LABEL}
            </Button>
            <Button disabled={isNextItemButtonDisabled} onClick={onNextClick}>
              {NEXT_QUERY_BUTTON_LABEL}
              <ArrowRightOutlined />
            </Button>
          </div>
        }
      >
        {renderResult()}
        {renderFilesCollapse()}
        <div className={styles.section}>
          <ListCarousel options={getCarouselOptions()} />
        </div>
      </Drawer>
      <PromptModal />
      {renderMetaDataModal()}
      {renderFiltersDataModal()}
    </>
  );
};

export default DetailedQueryView;
