import { FeedbackType } from '@redux/types/types';

export const TITLE = 'Detailed Feedback';
export const FILTERS_LABEL = 'Filters Applied';
export const FILTERS_COLUMN_LABEL = 'View';
export const DOWNLOAD_FEEDBACK_LABEL = 'Download CSV';
export const COPY_BUTTON_LABEL = 'Copy to Clipboard';
export const DONE_BUTTON_LABEL = 'Done';
export const PREV_QUERY_BUTTON_LABEL = 'Prev. query';
export const NEXT_QUERY_BUTTON_LABEL = 'Next query';
export const COPY_TOOLTIP = 'Meta data filters copied to clipboard.';
export const FEEDBACK_FILTER_TAGS_LABEL = 'Tags';
export const FEEDBACK_FILTER_FEEDBACK_TYPE_LABEL = 'Rating';
export const FILTERS_SECTION_TITLE = 'Filters applied';
export const FEEDBACK_SENT_SUCCESS_MESSAGE = 'Feedback rating added';
export const FEEDBACK_UPDATED_SUCCESS_MESSAGE = 'Feedback rating changed';
export const FEEDBACK_BOOKMARK_SUCCESS_MESSAGE = 'Saved for later in detailed feedback';
export const FEEDBACK_BOOKMARK_REMOVED_SUCCESS_MESSAGE =
  'Removed from save for later in detailed feedback';
export const FEEDBACK_TAG_CREATED_SUCCESS_MESSAGE = 'Tag created';
export const FEEDBACK_TAG_DELETED_SUCCESS_MESSAGE = 'Tag deleted';
export const COLUMN_TITLES = {
  QUERY: 'Query',
  FILTERS: 'Filters',
  ANSWER: 'Predicted Answer',
  CONTEXT: 'Context',
  RANK: 'Rank',
  RELEVANCE: 'Relevance',
  USER: 'User',
  DURATION: 'Duration',
  QUERY_DATE: 'Query Date',
  QUERY_ID: 'Query ID',
  FILE_NAME: 'File(s)',
  FILE_ID: 'File ID',
  FEEDBACK_RATING: 'Feedback Rating',
  FEEDBACK_NOTES: 'Feedback Notes',
  FEEDBACK_TAGS: 'Feedback Tags',
  PROMPT: 'Prompt',
};

export const FEEDBACK_CAROUSEL_LIST_OPTIONS_KEYS = {
  FEEDBACK_TYPE: 'score',
  FEEDBACK_TAGS: 'tags',
  RANK: 'rank',
  QUERY_BY: 'queryBy',
  QUERY_ID: 'queryId',
  DURATION: 'duration',
  QUERY_CREATED: 'queryCreated',
  FILE_ID: 'file_id',
};

export const FEEDBACK_FILTERS_FIELD_KEYS = {
  FEEDBACK_TYPE: 'score',
  TAGS_ID: 'tags/tag_id',
};

export const FEEDBACK_TYPE_LABELS = {
  [FeedbackType.ACCURATE]: 'Accurate',
  [FeedbackType.FAIRLY_ACCURATE]: 'Fairly Accurate',
  [FeedbackType.INACCURATE]: 'Inaccurate',
};

export const FEEDBACK_SAVED_FOR_LATER_BUTTON_LABEL = 'Saved';
export const FEEDBACK_SAVE_FOR_LATER_BUTTON_TOOLTIP = 'Save for later in query history';

// Sorting

export const DEFAULT_FEEDBACK_SORTING_KEY = 'created_at_desc';

export const FEEDBACK_SORTING_DATATABLE_OPTIONS = [
  { key: 'created_at_desc', label: 'Query Date Newest to Oldest' },
  { key: 'created_at_asc', label: 'Query Date Oldest to Newest' },
  { key: 'search_history/search_query_asc', label: 'Query A to Z' },
  { key: 'search_history/search_query_desc', label: 'Query Z to A' },
  { key: 'search_history/rank_asc', label: 'Rank ascending' },
  { key: 'search_history/rank_desc', label: 'Rank descending' },
  { key: 'search_history/score_asc', label: 'Relevance ascending' },
  { key: 'search_history/score_desc', label: 'Relevance descending' },
];

export const SORTING_PARAMS_BY_KEY = {
  created_at_asc: {
    field: 'created_at',
    order: 'ASC',
  },
  created_at_desc: {
    field: 'created_at',
    order: 'DESC',
  },
  'search_history/rank_asc': {
    field: 'search_history/rank',
    order: 'ASC',
  },
  'search_history/rank_desc': {
    field: 'search_history/rank',
    order: 'DESC',
  },
  'search_history/search_query_asc': {
    field: 'search_history/search_query',
    order: 'ASC',
  },
  'search_history/search_query_desc': {
    field: 'search_history/search_query',
    order: 'DESC',
  },
  'search_history/score_asc': {
    field: 'search_history/score',
    order: 'ASC',
  },
  'search_history/score_desc': {
    field: 'search_history/score',
    order: 'DESC',
  },
};
