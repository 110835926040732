import { ExclamationCircleOutlined } from '@ant-design/icons';
import { LegacyButtonType } from 'antd/lib/button/button';
import { LabelingDetailsTabOptions } from '@redux/types/types';

export const PROJECTS_TITLE = 'Projects';
export const LABELS_LABEL = 'labels created';
export const QUERIES_LABEL = 'queries labeled';
export const NEW_PROJECT_BUTTON_LABEL = 'New project';

export const CREATE_NEW_PROJECT_MODAL_TITLE = 'Create a new project';
export const UPDATE_PROJECT_MODAL_TITLE = 'Update project';
export const PROJECT_NAME_INPUT_LABEL = 'Project Name';
export const PROJECT_NAME_INPUT_PLACEHOLDER = 'Enter a project name';
export const DESCRIPTION_INPUT_LABEL = 'Description';
export const DESCRIPTION_INPUT_PLACEHOLDER = 'Add a description to help you identify it later';
export const MANDATORY_LABEL = 'Mandatory';
export const START_LABELING_BUTTON = 'Start Labeling';

export const EMPTY_PROJECTS_LIST_TITLE = 'This is where your labeling projects will appear';
export const EMPTY_PROJECTS_LIST_SUBTITLE = 'Create a project to get started.';

export const PROJECT_CONFIGURATION_PIPELINE = 'Add a Pipeline';
export const PROJECT_CONFIGURATION_PIPELINE_DESCRIPTION =
  'Use the labeling pipeline template or choose an existing document retrieval pipeline to filter out potentially relevant documents.';
export const PROJECT_CONFIGURATION_DEPLOYED_PIPELINE_ALERT =
  'Editing the pipeline of an existing project may impact the quality of future labels. To edit the pipeline, undeploy, save your changes, and deploy it again.';
export const PROJECT_CONFIGURATION_UNDEPLOYED_PIPELINE_ALERT =
  "Deploy the pipeline to start labeling. Remember to edit it, as needed, beforehand as you can't modify deployed pipelines.Removing or editing the pipeline later can impact the quality of new labels.";
export const PROJECT_CONFIGURATION_INDEXING_PIPELINE_ALERT =
  'The pipeline is currently indexing documents. For complete results, wait until indexing finishes. Searching now will only show documents indexed so far.';
export const PROJECT_CONFIGURATION_LABELING_GUIDELINE = 'Add Labeling Guidelines';
export const PROJECT_CONFIGURATION_LABELING_GUIDELINE_DESCRIPTION =
  'Add guidelines the labelers should follow when working on the project.';
export const PROJECT_CONFIGURATION_LABELING_GUIDELINE_ADD_BUTTON = 'Add Guidelines';
export const PROJECT_CONFIGURATION_LABELING_GUIDELINE_EDIT_BUTTON = 'Edit Guidelines';
export const PROJECT_CONFIGURATION_QUERY_TARGET = 'Add Query Target';
export const PROJECT_CONFIGURATION_QUERY_TARGET_DESCRIPTION =
  'Set the number of queries you want to run. The more queries, the more labels you can add.';
export const PROJECT_CONFIGURATION_QUERY_TARGET_INPUT_LABEL = 'Number of queries to be performed';
export const PROJECT_CONFIGURATION_FILES = 'Files';
export const PROJECT_CONFIGURATION_FILES_DESCRIPTION =
  'This is the data used for your labeling project.';
export const SHOW_DOCUMENT_METADATA_LABEL = 'Show metadata';
export const RESULTS_LABELED_LABEL = 'Results labeled';
export const FLAG_TO_REVIEW_TOOLTIP = 'Flag to review';
export const PREV_QUERY_LABEL = 'Prev. Query';
export const NEXT_QUERY_LABEL = 'Next. Query';

export const SELECT_PIPELINE_PLACEHOLDER = 'Select a pipeline';
export const SELECT_USE_TEMPLATE_PIPELINE_OPTION = 'Use Template';
export const REMOVE_SELECTED_PIPELINE_CONFIRMATION_MESSAGE = 'Remove selected pipeline?';
export const FILES_STATISTIC_TITLE = 'All files in your workspace';

export const DETAILS_BUTTON_LABEL = 'Details';
export const ADD_BUTTON_LABEL = 'Add';
export const EDIT_BUTTON_LABEL = 'Edit';
export const UPLOAD_BUTTON_LABEL = 'Upload files';
export const REMOVE_FROM_PROJECT_BUTTON_LABEL = 'Remove from project';
export const DEPLOY_BUTTON_LABEL = 'Deploy';
export const UNDEPLOY_BUTTON_LABEL = 'Undeploy';
export const TRY_AGAIN_BUTTON_LABEL = 'Try Again';
export const SETTINGS_LINK_LABEL = 'settings';
export const PIPELINE_NOT_DEPLOYED_ALERT =
  'The pipeline must be deployed to start the labeling process. Go to %settings% to deploy this pipeline.';

export const UPDATE_STATUS_LABELS = {
  SAVING: 'Saving...',
  CHANGES_SAVED: 'Changes saved',
};

export enum ProjectMoreOptionMenuAction {
  Edit = 'EDIT',
  Delete = 'DELETE',
  Export = 'EXPORT',
  Duplicate = 'DUPLICATE',
}

export const PROJECT_MORE_OPTIONS_MENU_ITEMS = [
  {
    label: 'Edit Name & Description',
    key: ProjectMoreOptionMenuAction.Edit,
  },
  {
    label: 'Export Labels (.csv)',
    key: ProjectMoreOptionMenuAction.Export,
  },
  {
    label: 'Duplicate',
    key: ProjectMoreOptionMenuAction.Duplicate,
  },
  {
    label: 'Delete',
    key: ProjectMoreOptionMenuAction.Delete,
    danger: true,
  },
];

export enum SelectedPipelineMenuActions {
  Edit = 'EDIT',
  Details = 'DETAILS',
  Remove = 'REMOVE',
}
export const OVERVIEW_MENU_ITEMS = [
  {
    label: 'Add a Pipeline',
    key: 'pipeline_id',
    mandatory: true,
  },
  {
    label: 'Add Labeling Guidelines',
    key: 'annotation_guideline',
    mandatory: false,
  },
  {
    label: 'Add Query Target',
    key: 'query_target',
    mandatory: false,
  },
  {
    label: 'All files in your workspace',
    key: 'files',
    mandatory: true,
  },
];

export const LABELING_TABS = [
  {
    label: 'Overview',
    key: LabelingDetailsTabOptions.OVERVIEW,
  },
  {
    label: 'Settings',
    key: LabelingDetailsTabOptions.SETTINGS,
  },
];

export const MANDATORY_ALERT_MESSAGE = 'Mandatory steps not completed yet';
export const MANDATORY_ALERT_DESCRIPTION =
  'To start labeling, complete the mandatory tasks listed below.';

export const DELETE_PROJECT_MODAL = {
  title: 'Delete project?',
  icon: ExclamationCircleOutlined,
  description:
    'Deleting this labeling project and its contents is irreversible. Are you sure you want to delete %projectName%.',
  okText: 'Delete',
  okType: 'danger' as LegacyButtonType,
};

export const SIDEBAR_LABELING_GUIDELINE_TITLE = 'Labeling';
export const SIDEBAR_LABELING_GUIDELINE_DESCRIPTION =
  " Label each search result as Relevant or Not Relevant. If you're unsure, flag the result for review by another labeler.";
export const VIEW_LABELING_GUIDELINE_BUTTON_LABEL = ' View Guidelines';
export const LABELING_GUIDELINE_MODAL_DATE_LABEL = 'Guidelines |';
export const LABELING_PROGRESS_QUERY_TARGET_LABEL = 'Query Target';
export const LABELING_PROGRESS_QUERIES_LABEL = 'Queries';
export const LABELING_PROGRESS_QUERY_TARGET_DESCRIPTION = '%queriesPerformed% queries labeled';
export const LABELING_PROGRESS_QUERY_TARGET_POST_DESCRIPTION = ' out of %queryTarget%';
export const LABELING_LEADERBOARD_POSITION_LABEL = 'Position';
export const LABELING_LEADERBOARD_EMPTY_MESSAGE = 'Run queries and track your progress here.';
export const LABELING_QUERY_HISTORY_TITLE = 'Query History';

// Alerts
export const LABEL_ADDED_MESSAGE = 'Label added';
export const LABEL_UPDATED_MESSAGE = 'Label updated';
